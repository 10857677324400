import AuthForm from './Auth/AuthForm';
import AuthLogin from './Auth/AuthLogin';
import AuthRegister from './Auth/AuthRegister';

interface IProps {
    page: string
}
const Auth = ({ page }: IProps) => (
    <AuthForm
        showBack
    >
        <div className="form" id="login">
            {page === 'login' && (
                <AuthLogin />
            )}
            {page === 'register' && (
                <AuthRegister />
            )}
        </div>
    </AuthForm>

);

export default Auth;
