import { memo, useCallback } from 'react';
import s from './Td.module.scss';

interface IProps {
    error?: boolean;
    value?: string;
    className?: string;
    children?: React.ReactNode;
    onClick?: () => void;
}
const DocTableTd = memo(({
    className, children, error, onClick, value,
}: IProps) => {
    const getStyleByClass = useCallback(() => {
        if (className === 'sign') {
            return s.sign;
        } if (className === 'template') {
            return s.template;
        } if (className === 'title') {
            return s.title;
        } if (className === 'icons') {
            return s.icons;
        }
        return '';
    }, [className]);
    return (
        <td key={value} className={s.container}>
            <div className={`${s.td} ${getStyleByClass()} ${error ? s.error : ''}`} onClick={onClick}>
                {value || children}
            </div>
        </td>
    );
});
export default DocTableTd;
