import axios from 'axios';

const $document = axios.create({
    baseURL: process.env.REACT_APP_DOCUMENTOOBOROT_URL,
});
const $users = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

export {
    $document, $users,
};
