import { useEffect, useState } from 'react';
import { IMultySelectSearch } from '../../assets/interfaces/interfaces';
import { SelectArrowSvg } from '../../assets/svgs/SelectArrowSvg';
import s from './Select.module.scss';

interface IProps {
    id: string,
    placeholder: string,
    onChangeValues: Function,
    values: IMultySelectSearch[],
    spaceZoneClicked: boolean,
    multiple: boolean,
}
const SelectLabelField = ({
    values, multiple, placeholder, onChangeValues, id, spaceZoneClicked,
}: IProps) => {
    const [showSelect, setShowSelect] = useState<boolean>(false);
    const [input, setInput] = useState<string>('');

    const handleChange = (text: string) => {
        const filter = text.toUpperCase();
        const ul = document.getElementById(id);
        let valuesEl;
        let i;
        let txtValue;

        setInput(text);
        if (ul) {
            valuesEl = ul.getElementsByClassName('form_select__value');
            const newValues = [...values];
            // Loop through all list items, and hide those who don't match the search query
            for (i = 0; i < valuesEl.length; i++) {
                const newIndex = i % 2;
                const span = valuesEl[i];
                txtValue = span.textContent || span.innerHTML;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    newValues[newIndex].show = true;
                } else {
                    newValues[newIndex].show = false;
                }
            }
        }
    };
    const onSelect = (index: number) => {
        const newValues = [...values];
        if (newValues[index].selected) {
            newValues[index].selected = false;
            onChangeValues(newValues, index);
        } else {
            if (!multiple) {
                newValues.forEach((val) => val.selected = false);
            }
            newValues[index].selected = !newValues[index].selected;
            onChangeValues(newValues, index);
        }
    };
    const getSelectedValues = () => {
        const selected = [''];
        values.forEach((val) => {
            if (val.selected) {
                selected.push(val.title);
            }
        });
        if (selected.length > 1) {
            let names = selected.join(', ');
            names = `Выбрано: ${names.slice(2, names.length)}`;
            return names;
        }
        return '';
    };

    useEffect(() => {
        setShowSelect(false);
    }, [spaceZoneClicked]);

    return (
        <div className={s.select} key={id}>

            <div
                className={`${s.form} ${showSelect ? s.active_form : ''}`}
            >
                <input
                    onFocus={() => { setShowSelect(true); }}
                    value={input}
                    onChange={(e) => { handleChange(e.target.value); }}
                    className={s.input}
                    placeholder={placeholder}
                />
                <div className={s.arrow} onClick={() => { setShowSelect(!showSelect); }}>
                    <SelectArrowSvg />
                </div>
            </div>
            <div
                id={id}
                className={`${s.values} ${showSelect ? s.values_active : ''}`}
            >
                {values.map((val, index) => (
                    <div
                        key={val.id}
                    >
                        <div
                            onClick={() => onSelect(index)}
                            className={`${s.value} ${val.selected ? s.value_selected : ''} ${val.show ? '' : 'hide'}`}
                        >
                            <span className={s.title}>{val.title}</span>
                            <span className={s.subtitle}>{val.subtitle}</span>
                        </div>
                        <span className={s.search_field}>
                            {val.title}
                            {' '}
                            {val.subtitle}
                        </span>
                    </div>
                ))}
            </div>

            <div className={s.selected_values}>{getSelectedValues()}</div>
        </div>
    );
};
export default SelectLabelField;
