import '../../assets/styles/inputs/search_input.scss';
import MagnifierSvg from '../../assets/svgs/MagnifierSvg';

type IProps = {
    onChange: (text: string) => void;
}
const SearchInput = ({ onChange }: IProps) => (
    <div className="search-input">
        <input
            onChange={(e) => onChange(e.target.value)}
            type="text"
            placeholder="Введите для поиска..."
        />
        <div className="search-icon">
            <MagnifierSvg />
        </div>
    </div>

);

export default SearchInput;
