import axios from 'axios';

const $host = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});
const $admin = axios.create({
    baseURL: process.env.REACT_APP_ADMIN_URL,
});
const $erp = axios.create({
    baseURL: process.env.REACT_APP_ERP_URL,
});
const $task = axios.create({
    baseURL: process.env.REACT_APP_TASK_URL,
});
const $document = axios.create({
    baseURL: process.env.REACT_APP_DOCUMENTOOBOROT_URL,
});

// const authInterceptor = config => {
//     config.headers.authorization = `Bearer ${getCookie('access_token')}`
// }

// $authHost.interceptors.request.use(authInterceptor)

export {
    $admin, $document, $erp, $host, $task,
};
