import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkToken, signIn } from '../../http/userAPI';
import { STARTER_ROUTE } from '../../utils/consts';
import { eraseCookie, getCookie } from '../../utils/cookies';
import SureModal from '../Modals/SureModal';
import InputLabelField from './InputLabelField';

const AuthLogin = () => {
    const [login, setLogin] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [scope, setScope] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>({});
    const [exception, setException] = useState<boolean>(false);
    const [passMin, setPassMin] = useState<boolean>(true);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [client_id, setClientId] = useState<string>('');
    const [client_secret, setClientSecret] = useState<string>('');
    const [redirect_uri, setRedirectUri] = useState<string>('');

    const navigate = useNavigate();

    const validatePass = () => {
        setPassMin(password.length >= 8);
        return passMin;
    };
    const onSuccess = () => {
        setException(false);
        if (redirect_uri && redirect_uri !== '') {
            setShowLoginModal(true);
        } else {
            navigate(STARTER_ROUTE);
        }
    };
    const onAuth = async () => {
        signIn(login, password, scope, client_id, client_secret, redirect_uri).then(({ data }) => {
            if (!data.errors && !data.exception && data.statusCode !== 401 && data.success) {
                onSuccess();
            }
            if (data.errors) {
                setErrors(data.errors);
            }
            if (data.statusCode === 401) {
                setException(true);
            }
        }).catch((error) => {
            setError(true);
        });
        // signInToken(client_id, client_secret, login, password, scope).then(({ data }) => {
        //     if (!data.errors && !data.exception && data.statusCode !== 401 && data.success) {
        //         onSuccess()
        //     }
        //     if (data.errors) {
        //         setErrors(data.errors)
        //     }
        //     console.log('data', data)
        //     if (data.statusCode === 401) {
        //         setException(true)
        //     }
        // }).catch(error => {
        //     setError(true)
        // })
    };

    const getParams = (params: Array<any>) => {
        const values = params;
        return `?${values.map((val) => `${Object.keys(val)[0]}=${Object.values(val)[0]}`).join('&')}`;
    };
    const toRedirect = (params: string) => {
        window.location.href = redirect_uri + params;
    };
    const toCheckToken = () => {
        const authorization_token = getCookie('authorization_token');
        if (authorization_token) {
            checkToken(authorization_token, false).then((resp) => {
                const { data } = resp;
                if ((data && data.statusCode === 401) || !data) {
                    const params = getParams([{ success: 'false' }, { statusCode: '401' }, { message: 'Отказ авторизации..' }]);
                    eraseCookie('authorization_token');
                    eraseCookie('fio');
                    toRedirect(params);
                }
            });
        }
    };

    const onYes = () => {
        const params = getParams([{ success: 'true' }, { statusCode: 200 }, { message: 'Успешно.' }, { data: getCookie('authorization_token') }]);
        eraseCookie('authorization_token');
        eraseCookie('fio');
        toRedirect(params);
    };
    const onNo = () => {
        setShowLoginModal(false);
        toCheckToken();
    };
    const getQueryParams = () => {
        if (window.location.search) {
            const params = window.location.search.split('&').map((val) => val.split('='));
            const paramOne = [params[0][0].slice(1, params[0][0].length), params[0][1]];
            if (params[1] && params[2]) {
                const paramTwo = [params[1][0], params[1][1]];
                const paramThree = [params[2][0], params[2][1]];

                let client_id; let client_secret; let
                    redirect_uri = '';
                const getPrams = [paramOne, paramTwo, paramThree].forEach(([key, value]) => {
                    if (key === 'client_id') {
                        client_id = value;
                    } else if (key === 'client_secret') {
                        client_secret = value;
                    } else if (key === 'redirect_uri') {
                        redirect_uri = value;
                    }
                });
                return [client_id, client_secret, redirect_uri];
            }
        }
        return [null, null];
    };
    useEffect(() => {
        const access_token = getCookie('access_token');
        const [client, secret, redirect] = getQueryParams();
        if (client && secret && redirect) {
            setClientId(client);
            setClientSecret(secret);
            setRedirectUri(redirect);
        }
        if (redirect) {
            toCheckToken();
        }
    }, [client_id, client_secret, redirect_uri]);
    return (
        <>
            {showLoginModal && (
                <SureModal
                    onYes={onYes}
                    onNo={onNo}
                    text={`Вы точно хотите войти под аккаунтом ${getCookie('fio')}?`}
                />
            )}
            <InputLabelField
                value={login}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLogin(e.target.value)}
                onBlur={() => { }}
                label="Логин"
                htmlFor="login"
                type="text"
                errors={errors}
            />
            <InputLabelField
                value={password}
                onChange={(e: React.FormEvent<HTMLInputElement>) => setPassword(e.currentTarget.value)}
                label="Пароль"
                htmlFor="password"
                type="password"
                onBlur={() => validatePass()}
                errors={errors}
            />
            {error && !errors?.password && (
                <div className="error">Неверный логин или пароль</div>
            )}
            {exception && (
                <div className="error">Неверные данные</div>
            )}
            <div className="btn" onClick={onAuth}>Вход</div>
        </>

    );
};

export default AuthLogin;
