import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SelectArrowSvg } from '../../assets/svgs/SelectArrowSvg';
import {
    ADMIN_ROUTE, DOC_ROUTE, ORG_SCHEMA, STARTER_ROUTE, TASKS_ROUTE, USER_ROUTE,
} from '../../utils/consts';
import { getCookie } from '../../utils/cookies';
import { getRouteTabId } from '../../utils/func';
import {
    CALENDAR_MODULE, DOCUMENT_MODULE, MAIN_MODULE, QUESTION_MODULE, REFERENCE_MODULE, TASKS_MODULE,
} from '../../utils/modules';
import {
    CALENDAR_PAGE, DOCUMENT_PAGE, QUESTION_PAGE, REFERENCE_PAGE, TASKS_PAGE,
} from '../../utils/pages';

const Breadcrumbs = () => {
    require('../../assets/styles/header/breadcrumbs.scss');
    interface ICrumb {
        text: string,
        page: string
    }
    const [crumbs, setCrumbs] = useState<ICrumb[]>([{ text: '', page: '' }]);
    const navigate = useNavigate();
    const params = useParams();

    const toPage = (page: string) => {
        navigate(page);
    };
    const getModule = (name: string): string => {
        const routeSpit = USER_ROUTE.split('/');
        routeSpit.shift();
        const userId = getCookie('userId');
        let route = '/';
        if (userId) {
            routeSpit[1] = userId;
            routeSpit[2] = name;
            route = `/${routeSpit.join('/')}`;
        }
        return route;
    };
    const getModuleRoute = (module_route: string) => getRouteTabId(module_route);

    const onBack = () => {
        navigate(STARTER_ROUTE);
    };
    useEffect(() => {
        const path = window.location.pathname.split('/');
        path.shift();
        if (path[0] === 'users') {
            if (path[2] === MAIN_MODULE) {
                setCrumbs([{ text: 'главная', page: STARTER_ROUTE }, { text: 'личный кабинет', page: getModule(MAIN_MODULE) }]);
            } else if (path[2] === CALENDAR_MODULE) {
                setCrumbs([{ text: 'главная', page: STARTER_ROUTE }, { text: CALENDAR_PAGE, page: getModule(CALENDAR_MODULE) }]);
            } else if (path[2] === REFERENCE_MODULE) {
                setCrumbs([{ text: 'главная', page: STARTER_ROUTE }, { text: REFERENCE_PAGE, page: getModule(REFERENCE_MODULE) }]);
            } else if (path[2] === TASKS_MODULE) {
                setCrumbs([{ text: 'главная', page: STARTER_ROUTE }, { text: TASKS_PAGE, page: getModule(TASKS_MODULE) }]);
            } else if (path[2] === DOCUMENT_MODULE) {
                setCrumbs([{ text: 'главная', page: STARTER_ROUTE }, { text: DOCUMENT_PAGE, page: getModule(DOCUMENT_MODULE) }]);
            } else if (path[2] === QUESTION_MODULE) {
                setCrumbs([{ text: 'главная', page: STARTER_ROUTE }, { text: QUESTION_PAGE, page: getModule(QUESTION_MODULE) }]);
            }
        } else if (`/${path[0]}` === ADMIN_ROUTE) {
            setCrumbs([{ text: 'главная', page: STARTER_ROUTE }, { text: 'администрирование', page: ADMIN_ROUTE }]);
        } else if (path[0] === TASKS_ROUTE.split('/')[1]) {
            setCrumbs([{ text: 'главная', page: STARTER_ROUTE }, { text: 'задачи', page: `${getModuleRoute(TASKS_ROUTE)}` }]);
        } else if (path[0] === DOC_ROUTE.split('/')[1]) {
            setCrumbs([{ text: 'главная', page: STARTER_ROUTE }, { text: 'документооборот', page: `${getModuleRoute(DOC_ROUTE)}` }]);
        } else if (path[0] === ORG_SCHEMA.split('/')[1]) {
            setCrumbs([{ text: 'главная', page: STARTER_ROUTE }, { text: 'оргсхема', page: `${getModuleRoute(ORG_SCHEMA)}` }]);
        } else {
            setCrumbs([{ text: 'главная', page: STARTER_ROUTE }]);
        }
    }, [params]);
    return (
    // <div className="nav__breadcrumbs">
    //     {crumbs.map((crumb: ICrumb, index: number) => (
    //         <>
    //             <span className="crumb" onClick={() => toPage(crumb.page)}>{crumb.text}</span>
    //             {index === crumbs.length - 1 ? (
    //                 <span ></span>
    //             ) : (
    //                 <span className="arrow">/</span>
    //             )}
    //         </>

    //     ))}
    // </div>

        <ol className="cd-breadcrumb custom-separator">
            {/* <li className="home">
                <span onClick={() => onBack()}><HouseSvg/></span>
            </li> */}
            {crumbs.map((crumb: ICrumb, index: number) => (
                <li key={crumb.page} className={`${crumbs.length === (index + 1) ? 'current' : ''}`}>
                    <span onClick={() => toPage(crumb.page)}>{crumb.text}</span>
                    {crumbs.length !== (index + 1) && (
                        <span className="arrow"><SelectArrowSvg /></span>
                    )}
                </li>
            ))}

            {/* <li><span>Gallery</span></li>
    <li><span>Web</span></li>
    <li className="current"><span>Project</span></li> */}
        </ol>
    );
};

export default Breadcrumbs;
