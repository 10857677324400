import {
    memo, useCallback, useEffect,
    useState,
} from 'react';

import { useNavigate } from 'react-router-dom';
import {
    IMultySelectSearch, IOpenFile, ISureModal, ITaskFile, ITextActive,
} from '../../../../../assets/interfaces/interfaces';
import {
    IAddToArchiveResponse,
    IDocCreateDocument,
    IDocDetailsData,
    IDocInputSections,
    IDocPage,
    IDocStatusAgreement,
    IDocTable, IDocTableData, IDocTableHeader,
    ISaveDetails,
} from '../../../../../assets/interfaces/modules/doc/doc_interface';
import SureModal from '../../../../../components/Modals/SureModal';

import '../../../../../assets/styles/modules/components/doc/doc_layout_tab.scss';
import { DOC_ROUTE } from '../../../../../utils/consts';
import {
    acceptStatusDocumentAPI,
    addDocumentAPI, changeStatusDocumentAPI, deleteDocumentAPI, editDocumentAPI, generateDocumentAPI,
    getDocumentAPI,
} from '../../api/documentAPI';
import { getDocumentStateByText, getIndexById, isRespSuccess } from '../../config/funcs';
import { ACCEPT_STATUS_TEXT, APPROVE_STATUS_TEXT, DECLINE_STATUS_TEXT } from '../../config/variables';
import DocHeader from '../elements/DocHeader';
import DocAddDocumentModal from '../modals/DocAddDocumentModal';
import DocModals from '../modals/DocModals';
import TableDocModule from '../table/TableDocModule';

interface IProps {
    tableHeaders: IDocTableHeader[];
    tableData: IDocTable;
    tab: keyof IDocPage;
    title: string;
    isArchive?: boolean;
    showDatePicker?: boolean;
    isIncomingTab?: boolean;
    docSections?: IDocInputSections[];
    docTypes?: IDocInputSections[];
    onChangeData: (newData: IDocTable, tab: keyof IDocPage) => void;
    onChangeTablePage: (clickedPage: number) => void;
    onChangeStatus?: (index: number, btnText: string) => void;
    onCloseTab: () => void;
    onChangeDocSections?: Function;
    onChangeDocTypes?: Function;

}

const DocLayoutTab = memo(({
    tableHeaders,
    tableData,
    isArchive,
    title,
    tab,
    showDatePicker,
    isIncomingTab,
    docSections,
    docTypes,
    onChangeDocTypes,
    onChangeDocSections,
    onChangeData,
    onChangeStatus,
    onChangeTablePage,
    onCloseTab,
}: IProps) => {
    const navigate = useNavigate();

    const [openFiles, setOpenFiles] = useState<IOpenFile[]>([]);
    const [sureDenyAgreementModal, setSureDenyAgreementModal] = useState<ITextActive>({ active: false, text: '' });
    const [sureDeleteModal, setSureDeleteModal] = useState<ISureModal>({ show: false, text: '', id: -1 });
    const [sureArchiveModal, setSureArchiveModal] = useState<ISureModal>({ show: false, text: '', id: -1 });
    const [selectUsersModal, setSelectUsersModal] = useState<boolean>(false);

    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);

    const [openDocumentId, setOpenDocumentId] = useState<number>();

    const [inputtableSearch, setInputtableSearch] = useState<string>('');

    function checkUrlEqual(navigate_route:string) {
        return window.location.pathname === navigate_route;
    }
    const openDocument = (id:number) => {
        setOpenDocumentId(id);
        setShowDetailsModal(true);
        const route = DOC_ROUTE.split('/');
        route.shift();
        route[1] = tab;
        route[2] = String(id);
        const navigate_route = `/${route.join('/')}`;
        const urlEqual = checkUrlEqual(navigate_route);
        if (!urlEqual) {
            navigate(navigate_route);
        }
    };
    async function acceptStatus(id: number, agreement: keyof IDocStatusAgreement) {
        const res = await acceptStatusDocumentAPI(id, agreement);
        return isRespSuccess(res);
    }

    async function changeAcceptStatus(id:number, agreement: keyof IDocStatusAgreement) {
        const success = await acceptStatus(id, agreement);
        return success;
    }
    async function changeAproveStatus(id:number) {
        const statusData = new FormData();
        statusData.append('document_state_id', '2');
        statusData.append('user_id', '50');
        statusData.append('_method', 'patch');
        const res = await changeStatusDocumentAPI(statusData, id);
        console.log('changeAproveStatus isRespSuccess(res)', isRespSuccess(res));
        return isRespSuccess(res);
    }
    async function geterateFile(id:number, user_id:number) {
        const form = new FormData();
        form.append('user_id', `${user_id}`);
        form.append('type', 'word');
        await generateDocumentAPI(form, id);
    }
    async function changeStatus(id: number, btnText: string): Promise<boolean> {
        switch (btnText) {
        case ACCEPT_STATUS_TEXT:
            return changeAcceptStatus(id, 'success');
        case DECLINE_STATUS_TEXT:
            return changeAcceptStatus(id, 'fail');
        case APPROVE_STATUS_TEXT:
            return changeAproveStatus(id);
        default:
            break;
        }

        return false;
    }
    const onAddToArchive = useCallback((response: IAddToArchiveResponse) => {
        const { isArchive } = response;
        const { td } = response;
        const text = `Действительно хотите ${isArchive ? 'убрать' : 'добавить'} "${td.topic}" ${isArchive ? 'из архива' : 'в архив'}?`;
        setSureArchiveModal({ show: true, text, id: td.id });
    }, []);

    const onChangeStatusHandler = useCallback(async (id:number, btnText: string) => {
        if (onChangeStatus) {
            const ok = await changeStatus(id, btnText);
            if (ok) {
                onChangeStatus(id, btnText);
            }
        }
    }, [tableData]);

    const onDeleteDoc = useCallback((td: IDocTableData) => {
        const text = `Действительно хотите удалить "${td.topic}"?`;
        const { id } = td;
        setSureDeleteModal({ show: true, text, id });
    }, []);

    const onDenyAgreement = useCallback((td: IDocTableData) => {
        setSureDenyAgreementModal({ active: true, text: '' });
    }, []);

    const onOpenTask = useCallback(() => {
        console.log('onOpenTask');
    }, []);
    const onShowFileHandler = useCallback((td: IDocTableData) => {
        setOpenFiles([]);
        geterateFile(td.id, td.user_id);
        // console.log('onShowFileHandler files', files);
        // setTimeout(() => {
        //     setOpenFiles(onShowFileSlider(files[0].task_id, files, files[0], 0));
        // }, 1);
    }, []);
    const onOpenTdSelectUser = useCallback((td: IDocTableData) => {
        setSelectUsersModal(true);

        console.log('td', td);
    }, []);
    const onChangeComment = useCallback((index: number, comment_text: string) => {
        const newData = [...tableData.data];
        const newTableData = { ...tableData };
        newData[index].comment = comment_text;
        tableData.data = newData;
        onChangeData(newTableData, tab);
    }, [tableData]);

    const onConfirmNoDelete = () => {
        setSureDeleteModal({ show: false, text: '', id: -1 });
    };

    async function getDocumentById(id: number):Promise<{document: null} | { document: IDocDetailsData}> {
        const res = await getDocumentAPI(id);
        if (!isRespSuccess(res)) return { document: null };
        return { document: res.data.data };
    }

    function deleteRowDocument(id: number) {
        const newTableData = { ...tableData };
        const newData = [...tableData.data];
        const index = getIndexById(newData, id);
        newData.splice(index, 1);
        newTableData.data = newData;
        onChangeData(newTableData, tab);
    }
    const onConfirmYesDelete = async () => {
        const response = await deleteDocumentAPI(sureDeleteModal.id);
        if (response.status === 200) {
            deleteRowDocument(sureDeleteModal.id);
            onConfirmNoDelete();
        }
    };

    const onCloseDetailsModal = () => {
        openDocument(0);
        setShowDetailsModal(false);
    };

    function getDocumentFormData(docData: IDocTableData | IDocCreateDocument) {
        const form = new FormData();
        form.append('topic', docData.topic);

        if (docData.situation) {
            form.append('situation', docData.situation);
        }

        if (docData.solution) {
            form.append('solution', docData.solution);
        }

        form.append('is_important', String(docData.is_important ? 1 : 0));
        form.append('document_template_id', String(docData.document_template_id));
        form.append('document_state_id', String(docData.document_state_id));
        form.append('user_id', String(docData.user_id));
        // Use type narrowing to check for file property
        if ('send_file' in docData && docData.send_file) {
            form.append('file', docData.send_file);
        } else if ('file' in docData && docData.file) {
            form.append('file', docData.file);
        }
        if ('is_archive' in docData && docData.is_archive) {
            form.append('is_archive', String(docData.is_archive ? 1 : 0));
        }

        return form;
    }

    async function editDocument(form: FormData, id: number):Promise<boolean> {
        const responseEdit = await editDocumentAPI(form, id);
        return isRespSuccess(responseEdit);
    }
    function changeRowStatus(index: number, document_state_id: number) {
        const newData = [...tableData.data];
        newData[index].document_state_id = document_state_id;
        const newTableData = { ...tableData };
        newTableData.data = newData;
        onChangeData(newTableData, tab);
    }
    const onSaveDetails = async (response: ISaveDetails):Promise<IDocTableData> => {
        const newData = [...tableData.data];
        const index = getIndexById(newData, response.td.id);
        if (index === -1) return newData[index];
        const { btnText } = response;
        const { id } = response.td;
        const document_state_id = getDocumentStateByText(btnText);
        if (!document_state_id) return newData[index];
        newData[index].document_state_id = document_state_id;
        if (response.files) {
            const file = response.files[0];
            newData[index].send_file = file;
        }
        const form = getDocumentFormData(newData[index]);
        changeStatus(id, btnText);

        // Редактирование документа (topic и тд)
        // const ok = await editDocument(form, id);
        // console.log('ok', ok);
        // if (ok) {
        //     changeRowStatus(index, document_state_id);
        //     onCloseDetailsModal();
        //     return newData[index];
        // }
        return newData[index];
    };
    const onOpenAddDocument = (open: boolean) => {
        setShowAddModal(open);
    };

    const onDeleteFileDetails = (file: ITaskFile, index: number) => {

    };

    const onConfirmNoArchive = () => {
        setSureArchiveModal({ show: false, text: '', id: -1 });
    };
    const onConfirmYesArchive = async () => {
        const newData = [...tableData.data];
        const index = getIndexById(newData, sureArchiveModal.id);
        newData[index].is_archive = true;
        const form = getDocumentFormData(newData[index]);
        const ok = await editDocument(form, newData[index].id);
        if (ok) onConfirmNoArchive();
    };

    const onConfirmNoDenyAgreement = () => {
        setSureDenyAgreementModal({ active: false, text: '' });
    };
    const onConfirmYesDenyAgreement = () => {
        onConfirmNoDenyAgreement();
    };

    const onChangeOpenFiles = (value: IOpenFile[]) => {
        setOpenFiles(value);
    };

    function addRow(newRow:IDocTableData) {
        const newData = { ...tableData };
        console.log('addRow newData', newData);
        newData.data.unshift(newRow);
        console.log('addRow newData', newData);
        console.log('');
        onChangeData(newData, tab);
    }
    async function addNewDocToTable(document_id: number): Promise<boolean> {
        const { document } = await getDocumentById(document_id);
        if (!document) return false;
        const newRow:IDocTableData = document.document;
        newRow.user = document.user;
        newRow.creator = document.creator;
        addRow(newRow);

        return true;
    }

    const createDocument = async (addData: IDocCreateDocument): Promise<boolean> => {
        const form = getDocumentFormData(addData);
        console.log('');
        console.log('createDocument tableData', tableData);
        const response = await addDocumentAPI(form);
        if (isRespSuccess(response)) {
            const { document_id } = response.data.data;
            const ok = await addNewDocToTable(document_id);
            if (ok) {
                onOpenAddDocument(false);
                return true;
            }
        }
        return false;
    };

    const onChangeinputSearch = (text: string) => {
        setInputtableSearch(text);
    };

    const onCloseSelectUserModal = () => {
        setSelectUsersModal(false);
    };
    const onSaveSelectUserModal = (users: IMultySelectSearch[]) => {
        onCloseSelectUserModal();
    };
    const onChangeDenyAgreement = (text: string) => {
        setSureDenyAgreementModal({ active: true, text });
    };

    function openDocumentByUrl() {
        const path = window.location.pathname.split('/');
        const id = path[path.length - 1];
        openDocument(Number(id));
    }

    useEffect(() => {
        openDocumentByUrl();
    }, []);
    return (
        <>
            <DocAddDocumentModal
                show={showAddModal}
                onSaveAddModal={createDocument}
                onCloseAddModal={() => onOpenAddDocument(false)}
            />
            {(showDetailsModal && openDocumentId) ? (''
            // <DocDetailsModal
            //     onSaveDetails={onSaveDetails}
            //     onCloseDetailModal={onCloseDetailsModal}
            //     onDeleteFileDetails={onDeleteFileDetails}
            //     id={openDocumentId}
            // />
            ) : ''}
            {sureDeleteModal.show && (
                <SureModal
                    text={sureDeleteModal.text}
                    textConfirm="Удалить"
                    onYes={onConfirmYesDelete}
                    onNo={onConfirmNoDelete}
                />
            )}
            {sureArchiveModal.show && (
                <SureModal
                    text={sureArchiveModal.text}
                    textConfirm="Добавить"
                    onYes={onConfirmYesArchive}
                    onNo={onConfirmNoArchive}
                />
            )}
            <DocModals
                onConfirmNoDenyAgreement={onConfirmNoDenyAgreement}
                onConfirmYesDenyAgreement={onConfirmYesDenyAgreement}
                openFiles={openFiles}
                onChangeOpenFiles={onChangeOpenFiles}
                selectUserModal={selectUsersModal}
                onCloseSelectUserModal={onCloseSelectUserModal}
                sureDenyAgreementModal={sureDenyAgreementModal}
                onChangeDenyAgreement={onChangeDenyAgreement}
                onSaveSelectUserModal={onSaveSelectUserModal}
            />

            <DocHeader
                title={title}
                onShowAddModal={onOpenAddDocument}
                onInputSearchChange={onChangeinputSearch}
                onCloseTab={onCloseTab}
                docSections={docSections}
                onChangeDocSections={onChangeDocSections}
                docTypes={docTypes}
                onChangeDocTypes={onChangeDocTypes}
                showDatePicker={showDatePicker}
            />
            <div className="docmodule_body">
                <TableDocModule
                    headers={tableHeaders}
                    isIncomingTab={isIncomingTab === true}
                    pageNumbers={tableData.pages}
                    data={tableData.showData}
                    currentPage={tableData.currentPage}
                    isArchive={isArchive}
                    onChangePage={onChangeTablePage}
                    onChangeStatus={onChangeStatusHandler}
                    onAddToArchive={onAddToArchive}
                    onDeleteDoc={onDeleteDoc}
                    onDenyAgreement={onDenyAgreement}
                    onOpenDetails={openDocument}
                    onOpenTask={onOpenTask}
                    onShowFileHandler={onShowFileHandler}
                    onChangeComment={onChangeComment}
                    onOpenTdSelectUser={onOpenTdSelectUser}
                />
            </div>
        </>
    );
});

export default DocLayoutTab;
