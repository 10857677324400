import { useState } from 'react';
import {
    CALENDAR_PAGE, DOCUMENT_PAGE, MAIN_PAGE, QUESTION_PAGE, REFERENCE_PAGE, TASKS_PAGE,
} from '../../utils/pages';

interface IProps {
    onChangePage:Function,
    currentPage:string
}

const UserPages = ({ onChangePage, currentPage }:IProps) => {
    require('../../assets/styles/user/pages.scss');
    interface IPage {
        name: string,
        show: boolean
    }
    const [pages, setPages] = useState<IPage[]>([
        { name: MAIN_PAGE, show: true },
        { name: CALENDAR_PAGE, show: false },
        { name: REFERENCE_PAGE, show: false },
        { name: TASKS_PAGE, show: false },
        { name: DOCUMENT_PAGE, show: false },
        { name: QUESTION_PAGE, show: false },
    ]);

    const changePage = (page:IPage, index: number) => {
        const newPage = pages.map((item, i) => {
            if (index === i) {
                return { ...item, show: true };
            }
            return { ...item, show: false };
        });
        setPages(newPage);
        onChangePage(page.name);
    };
    return (
        <div className="pages">
            {pages.map((page: IPage, index: number) => (
                <div className={`page ${page.name === currentPage ? 'active' : ''}`}>
                    <span onClick={() => changePage(page, index)}>{page.name}</span>
                </div>
            ))}
        </div>
    );
};

export default UserPages;
