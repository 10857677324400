import { ReactNode } from 'react';
import { ISelectModalLink } from '../../../../../assets/interfaces/interfaces';
import s from './RowModal.module.scss';

type IProps = {
    children: ReactNode;
    title: string;
    onClick?: () => void;
    link?: ISelectModalLink;
}
const RowModule = ({
    children, title, link, onClick,
}: IProps) => {
    const onOpenLink = () => {
        if (link) {
            window.location.href = link.url;
        }
    };
    return (

        <div className={s.row} onClick={onClick}>
            {title ? (
                <div className={s.wrapper_title}>
                    <h5 className={s.title}>{title}</h5>
                    {link ? (
                        <span className={s.link} onClick={() => onOpenLink()}>
                            {link.text}
                        </span>
                    ) : null}

                </div>

            ) : null}
            {children}
        </div>
    );
};

export default RowModule;
