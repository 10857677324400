import { IModuleTab } from '../../../assets/interfaces/interfaces';

interface ITabsProps {
    tabs: IModuleTab[],
    onChangeTab: Function,
}
const ModuleTabs = ({ tabs, onChangeTab }: ITabsProps) => {
    require('../../../assets/styles/modules/components/module_tabs.scss');

    return (
        <div className="module_tabs">
            {tabs.map((tab, index) => (
                <div key={tab.text} onClick={() => onChangeTab(index)} className={`tab ${tab.active && 'active'}`}>
                    {tab.text}
                    {' '}
                    {tab.count ? `- ${tab.count}` : ''}
                </div>
            ))}
        </div>
    );
};
export default ModuleTabs;
