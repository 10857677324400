import { ReactNode } from 'react';
import { IModuleTab } from '../../../assets/interfaces/interfaces';
import '../../../assets/styles/modules/layout/page_with_tabs_layout.scss';
import Layout from '../../../components/Layout';

import SearchInput from '../../../components/Inputs/SearchInput';
import ModuleTabs from '../components/ModuleTabs';

type IProps = {
    tabs: IModuleTab[];
    onChangeModuleTab: (index: number) => void;
    onChangeSearchInput?: (text: string) => void;
    children: ReactNode;
}
const PageWithTabsLayout = ({
    tabs, onChangeModuleTab, onChangeSearchInput, children,
} : IProps) => (
    <Layout>
        <div className="page_with_tabs_layout">
            <div className="page_with_tabs_layout__header">
                <ModuleTabs
                    tabs={tabs}
                    onChangeTab={onChangeModuleTab}
                />
                {onChangeSearchInput ? (
                    <div className="search_input_wrapper">
                        <SearchInput onChange={onChangeSearchInput} />
                    </div>
                ) : null}
            </div>

            <div className="page_with_tabs_layout__content">
                {children}
            </div>
        </div>
    </Layout>
);

export default PageWithTabsLayout;
