import { IAddToArchiveResponse, IDocTableData } from '../../../../../../assets/interfaces/modules/doc/doc_interface';
import ButtonBorderRound from '../../../../../../components/html/buttons/ButtonBorderRound';
import { formatDateYMDHMS } from '../../../../../../utils/func';
import {
    checkSuccessStatus, docGetRecipients, getFioUser, getStatusButtonsText,
} from '../../../config/funcs';
import { ACCEPT_STATUS_TEXT, APPROVE_STATUS_TEXT, DECLINE_STATUS_TEXT } from '../../../config/variables';
import DocTableHoverIcons from '../components/DocTableHoverIcons';
import DocTableTdStatus from '../components/DocTableTdStatus';
import DocTableSubTd from '../td/DocTableSubTd';
import DocTableTd from '../td/DocTableTd';
import s from './TableTr.module.scss';

interface IProps {
    td: IDocTableData;
    index: number;
    isIncomingTab: boolean;
    isArchive: boolean;
    onOpenDetails: (id: number) => void;
    onOpenTask: Function;
    onShowFileHandler: (td: IDocTableData) => void;
    onDeleteDoc: (td: IDocTableData) => void;
    onDenyAgreement: (td: IDocTableData) => void;
    onAddToArchive: (response: IAddToArchiveResponse) => void;
    onChangeComment: (index: number, text: string) => void;
    onOpenSelectUser: Function;
    onChangeStatus?: (id: number, btnText: string) => void;

}
const DocIncomingTableTr = ({
    td, isArchive, onChangeStatus, isIncomingTab, onOpenSelectUser, index, onOpenTask, onChangeComment, onOpenDetails, onShowFileHandler, onDenyAgreement, onAddToArchive, onDeleteDoc,
}: IProps) => {
    function onTdClick(td: IDocTableData) {
        onOpenDetails(td.id);
    }
    function getFormattedDate(dateString:string) {
        if (dateString) {
            const date = new Date(dateString);
            return formatDateYMDHMS(date);
        }
        const date = new Date();
        return formatDateYMDHMS(date);
    }
    const onChangeStatusHandler = (text:string) => {
        if (onChangeStatus) {
            onChangeStatus(td.id, text);
        }
    };
    const getClassNames = (text: string) => {
        if (text === ACCEPT_STATUS_TEXT) {
            return `${s.button} ${s.success} ${checkSuccessStatus(td.document_state_id) ? s.active : ''}`;
        } if (text === DECLINE_STATUS_TEXT) {
            return `${s.button} ${s.danger} ${checkSuccessStatus(td.document_state_id) ? s.active : ''}`;
        } if (text === APPROVE_STATUS_TEXT) {
            return `${s.button} ${s.wait} ${checkSuccessStatus(td.document_state_id) ? s.active : ''}`;
        }
        return `${s.button} ${s.wait} ${checkSuccessStatus(td.document_state_id) ? s.active : ''}`;
    };
    const renderStatusButtons = () => (
        <div className={s.accept_buttons_status}>
            {getStatusButtonsText(td.document_state_id).map((text) => (
                <span
                    key={text}
                    className={getClassNames(text)}
                    onClick={() => onChangeStatusHandler(text)}
                >
                    {text}
                </span>
            ))}
        </div>
    );
    return (
        <>
            <tr
                className={`${s.tr} ${td.is_important ? s.important : ''}`}
            >
                <DocTableTd onClick={() => onTdClick(td)}>
                    {td.topic}
                </DocTableTd>
                <DocTableTd onClick={() => onTdClick(td)}>
                    {isIncomingTab ? (td.creator
                    ) : (docGetRecipients(td, 'id'))}

                </DocTableTd>
                <DocTableTd onClick={() => onTdClick(td)}>
                    {getFormattedDate(td.created_at)}
                </DocTableTd>
                <DocTableTd onClick={() => onTdClick(td)}>
                    {td.send_date}
                </DocTableTd>
                <DocTableTd className="status" onClick={() => onTdClick(td)}>
                    <DocTableTdStatus status={td.document_state_id} />
                </DocTableTd>
                <DocTableTd
                    className="performer"
                    onClick={() => {
                        switch (td.document_state_id) {
                        case 0:
                            onTdClick(td);
                            break;
                        case 1:
                            onOpenTask(td);
                            break;
                        case 2:
                            // onOpenSelectUser(td);
                            break;
                        default:
                            onTdClick(td);
                            break;
                        }
                    }}
                >
                    <div className="performer">
                        {(() => {
                            switch (td.document_state_id) {
                            case 0:
                                return <span className="text">Документ еще не согласован</span>;
                            case 1:
                                return (
                                    <div className="performet_btn_container">
                                        <ButtonBorderRound
                                            text="Ссылка на задачу"
                                            classes="button_info"
                                        />
                                    </div>
                                );
                            case 2:
                                return (
                                    <>
                                    </>
                                    // <div className="performet_btn_container">
                                    //     <ButtonBorderRound
                                    //         text="Добавить задачу"
                                    //         classes="button_success"
                                    //     />
                                    // </div>
                                );
                            default:
                                return null;
                            }
                        })()}
                    </div>
                </DocTableTd>
                <DocTableTd className="icons">

                    <DocTableHoverIcons
                        index={index}
                        isArchive={isArchive}
                        onAddToArchive={onAddToArchive}
                        onDeleteDoc={onDeleteDoc}
                        onDenyAgreement={onDenyAgreement}
                        onShowFileHandler={onShowFileHandler}
                        td={td}
                    />
                </DocTableTd>
            </tr>
            <tr
                className={`${s.subtr} `}
            >
                <DocTableSubTd title="Подписанты">
                    {getFioUser(td.user)}
                </DocTableSubTd>
                <DocTableSubTd title="Комментарий">
                    {isIncomingTab ? (
                        <input
                            type="text"
                            disabled
                            className={s.input_comment}
                            onChange={(e) => onChangeComment(index, e.target.value)}
                            value={td.comment}
                        />
                    ) : (td.comments[0] && td.comments[0].text)}
                </DocTableSubTd>
                <DocTableSubTd title="Дата">
                    {docGetRecipients(td, 'created_at') ? getFormattedDate(String(docGetRecipients(td, 'created_at'))) : 'Не указана'}
                </DocTableSubTd>
                <td />
                <DocTableSubTd title="Статус">
                    {isIncomingTab && onChangeStatus
                        ? renderStatusButtons()
                        : (
                            <DocTableTdStatus status={Number(docGetRecipients(td, 'status'))} />
                        )}
                </DocTableSubTd>
                <td />
                <td />
                <td />
            </tr>
        </>
    );
};
export default DocIncomingTableTr;
