import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IUser } from '../assets/interfaces/interfaces';
import Layout from '../components/Layout';
import UserMainPage from '../components/User/Pages/UserMainPage';
import UserCard from '../components/User/UserCard';
import UserPages from '../components/User/UserPages';
import { getUser } from '../http/userAPI';
import { USER_ROUTE } from '../utils/consts';
import { getCookie } from '../utils/cookies';
import {
    CALENDAR_MODULE, DOCUMENT_MODULE, MAIN_MODULE, QUESTION_MODULE, REFERENCE_MODULE, TASKS_MODULE,
} from '../utils/modules';
import {
    CALENDAR_PAGE, DOCUMENT_PAGE, MAIN_PAGE, QUESTION_PAGE, REFERENCE_PAGE, TASKS_PAGE,
} from '../utils/pages';

const User = () => {
    require('../assets/styles/user/user.scss');
    const params = useParams();
    const navigate = useNavigate();
    const [user, setUser] = useState<IUser>();
    const [currentPage, setCurrentPage] = useState<string>('Главная');

    useEffect(() => {
        if (params.module === MAIN_MODULE) {
            setCurrentPage(MAIN_PAGE);
        } else if (params.module === CALENDAR_MODULE) {
            setCurrentPage(CALENDAR_PAGE);
        } else if (params.module === REFERENCE_MODULE) {
            setCurrentPage(REFERENCE_PAGE);
        } else if (params.module === TASKS_MODULE) {
            setCurrentPage(TASKS_PAGE);
        } else if (params.module === DOCUMENT_MODULE) {
            setCurrentPage(DOCUMENT_PAGE);
        } else if (params.module === QUESTION_MODULE) {
            setCurrentPage(QUESTION_PAGE);
        }
        if (!user && params.id) {
            getUser(Number(params.id)).then(({ data }) => {
                setUser(data);
            });
        }
    }, []);
    const getModule = (page:string) => {
        let currentModule = '';
        if (page === MAIN_PAGE) {
            currentModule = MAIN_MODULE;
        } else if (page === CALENDAR_PAGE) {
            currentModule = CALENDAR_MODULE;
        } else if (page === REFERENCE_PAGE) {
            currentModule = REFERENCE_MODULE;
        } else if (page === TASKS_PAGE) {
            currentModule = TASKS_MODULE;
        } else if (page === DOCUMENT_PAGE) {
            currentModule = DOCUMENT_MODULE;
        } else if (page === QUESTION_PAGE) {
            currentModule = QUESTION_MODULE;
        }
        return currentModule;
    };
    const onChangePage = (page: string) => {
        const currentModule = getModule(page);
        const routeSpit = USER_ROUTE.split('/');
        routeSpit.shift();
        const userId = getCookie('userId');
        if (userId) {
            routeSpit[1] = userId;
            routeSpit[2] = currentModule;
            const route = `/${routeSpit.join('/')}`;
            navigate(route);
        }
        setCurrentPage(page);
    };
    return (
        <Layout>
            <div className="user_content">

                <div className="user_content__container">
                    <div className="side">
                        <UserCard user={user} />
                        <UserPages currentPage={currentPage} onChangePage={onChangePage} />
                    </div>
                    {currentPage === MAIN_PAGE && (
                        <UserMainPage />
                    )}
                    {currentPage === CALENDAR_PAGE && (
                        null
                    )}
                    {currentPage === REFERENCE_PAGE && (
                        null
                    )}
                    {currentPage === TASKS_PAGE && (
                        null
                    )}
                    {currentPage === DOCUMENT_PAGE && (
                        null
                    )}
                    {currentPage === QUESTION_PAGE && (
                        null
                    )}
                </div>

            </div>
        </Layout>
    );
};

export default User;
