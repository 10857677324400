import { useState } from 'react';

import { useDropzone } from 'react-dropzone';
import { IEditTask, ITaskErrors } from '../../../../../assets/interfaces/interfaces';
import '../../../../../assets/styles/user/components/edit/task_edit_form.scss';
import ExitSvg from '../../../../../assets/svgs/ExitSvg';
import FileTaskDeleteSvg from '../../../../../assets/svgs/FileTaskDeleteSvg';
import PencilSvg from '../../../../../assets/svgs/PencilSvg';
import TaskCheckboxSuccesSvg from '../../../../../assets/svgs/TaskCheckboxSuccesSvg';
import TrashSvg from '../../../../../assets/svgs/TrashSvg';
import { checkImage } from '../../../../../utils/func';
import { TASK_COLUMNS } from '../../../../../utils/tasks';

interface IPropsTaskEdit {
    task: IEditTask,
    onChangeFiles: Function,
    onSetEditValue: Function,
    onChangeDeadline: Function,
    onAddCheckList: Function,
    onChangeChecklistInput: Function,
    onAddCheckboxesChecklist: Function,
    onEnableEdidCheckboxName: Function,
    onDeleteCheckbox: Function,
    onChangeCheckboxName: Function,
    onEnableEdidChecklistName: Function,
    onDeleteChecklist: Function,
    onEditChecklistName: Function,
    onUpdateCheckbox: Function,
    errors: ITaskErrors,
}
const TaskEditForm = ({
    task, onChangeFiles, errors, onUpdateCheckbox, onEnableEdidChecklistName, onEditChecklistName, onDeleteChecklist, onChangeCheckboxName, onEnableEdidCheckboxName, onDeleteCheckbox, onAddCheckList, onChangeChecklistInput, onAddCheckboxesChecklist, onSetEditValue, onChangeDeadline,
}: IPropsTaskEdit) => {
    const [savedFiles, setSavedFiles] = useState<Array<File>>([]);
    const [checklistName, setChecklistName] = useState<string>('');
    const [nameLength, setNameLength] = useState<number>(200);
    const [drag, setDrag] = useState<boolean>(false);

    const onDragLeaveFile = () => {
        setDrag(false);
    };

    const onUploadFiles = (files: FileList) => {
        console.log('files', files);
        onDragLeaveFile();
        const newFiles = [...savedFiles];
        if (files) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                newFiles.push(file);
            }
            setSavedFiles(newFiles);
            onChangeFiles(task, files);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles: any) => {
            onUploadFiles(acceptedFiles);
        },
    });
    const getFileSize = (sizeInBytes: number) => {
        const sizeInMegabytes = sizeInBytes / (1024 * 1024);
        return `${sizeInMegabytes.toFixed(2)} МБ`; // Ограничиваем до 2 знаков после запятой
    };
    const getDate = (dateString: string) => {
        // Создаем объект даты из строки
        const date = new Date(dateString);

        // Получаем компоненты даты и времени
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
        const year = date.getFullYear();

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        // Форматируем дату в нужный формат
        return `${day}.${month}.${year} ${hours}:${minutes}`;
    };

    const onRemoveFile = (file_index: number) => {
        const newFiles = [...savedFiles];
        newFiles.splice(file_index, 1);
        setSavedFiles(newFiles);
    };
    const onDravOverFile = () => {
        setDrag(true);
    };

    const showImageFile = (file: any) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => {
            const fileEl = document.getElementById(file.name);
            if (fileEl) {
                fileEl.style.backgroundImage = `url('${reader.result}')`;
            }
        };
    };

    const onClickAddFiles = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.multiple = true;
        input.click();

        input.addEventListener('change', (e: any) => {
            onUploadFiles(e.target.files);
        });
    };
    return (
        <div
            className="edit_modal"
        >
            <div className="row">
                <div className={`form ${errors.name ? 'error' : ''}`}>
                    <input
                        type="text"
                        className="form_input"
                        id="kanbanTaskTitle"
                        value={task.task.name}
                        maxLength={nameLength}
                        onChange={(e) => onSetEditValue(task, e.target.value, 'name')}
                        placeholder=""
                    />
                    <span className="form_label">
                        Название (
                        {task.task.name.length}
                        /
                        {nameLength}
                        )
                    </span>
                    <input
                        className="form_input_color"
                        id="kanbanTaskTitle"
                        type="color"
                        placeholder=""
                        value={task.task.color_text ? task.task.color_text : '#000'}
                        onChange={(e) => onSetEditValue(task, e.target.value, 'color_text')}
                    />
                </div>
            </div>

            <div className="row">
                <div className="form">
                    <textarea
                        value={task.task.descriptions ? task.task.descriptions : ''}
                        onChange={(e) => onSetEditValue(task, e.target.value, 'descriptions')}
                        className="form_input textarea"
                        id="kanbanTaskDesc"
                        placeholder=""
                    />
                    <span className="form_label">Добавить описание</span>
                </div>
            </div>

            <div className="row">

                <div className="col">
                    <div className="form">
                        <input
                            className="form_input"
                            id="kanbanTaskStart"
                            type="datetime-local"
                            value={task.task.started_at ? task.task.started_at : ''}
                            onChange={(e) => onChangeDeadline(e.target.value, 'started_at', task)}
                            placeholder=""
                        />
                        <span className="form_label">Дата начала</span>
                    </div>
                </div>

                <div className="col">
                    <div className="form">
                        <input
                            className="form_input"
                            id="kanbanTaskEnd"
                            type="datetime-local"
                            value={task.task.finished_at ? task.task.finished_at : ''}
                            onChange={(e) => onChangeDeadline(e.target.value, 'finished_at', task)}
                            placeholder=""
                        />
                        <span className="form_label">Дата окончания</span>
                    </div>
                </div>

            </div>

            <div className="row">
                <div className="form">
                    <select
                        value={task.task.task_state_id}
                        onChange={(e) => onSetEditValue(task, e.target.value, 'task_state_id')}
                        className="form_input"
                        id="kanbanTaskStatus"
                    >
                        {TASK_COLUMNS.map((column) => (
                            <option value={column.task_state_id}>{column.status}</option>
                        ))}
                    </select>
                    <span className="form_label">Статус</span>
                </div>
            </div>

            <div className="row">
                <div
                    className="file_container"
                >
                    <div
                        {...getRootProps()}
                        className={`drag_zone ${drag ? 'over' : ''}`}
                        onDragOver={() => onDravOverFile()}
                        onDragLeave={() => onDragLeaveFile()}
                        onDragEnd={() => onDragLeaveFile()}
                        onClick={() => onClickAddFiles()}
                    >
                        <div
                            onDrop={(e) => { console.log('drop'); }}
                            className="drag_zone__space"
                        >
                            <img
                                className="icon"
                                onDrop={(e) => { console.log('drop'); }}
                                src={require('../../../../../assets/imgs/image-icon.png')}
                                width="24"
                                alt=""
                            />
                            <br />
                            Добавить файл
                        </div>

                    </div>
                    {savedFiles.length ? (
                        <div className="files">
                            {savedFiles.map((file, index) => (
                                <div className="file">
                                    <div className="left">
                                        <div className="icon">
                                            {checkImage(file.name) ? (
                                                <>
                                                    {showImageFile(file)}
                                                    <span id={file.name} className="image" />
                                                </>
                                            ) : (
                                                <img className="rounded-2 dz-image" src={require('../../../../../assets/imgs/file.png')} alt="..." />
                                            )}
                                        </div>
                                        <div className="info">
                                            <div className="title">{file.name}</div>
                                            <div className="created">{getFileSize(file.size)}</div>

                                        </div>
                                    </div>

                                    <span className="delete" onClick={() => onRemoveFile(index)}>
                                        <FileTaskDeleteSvg />
                                    </span>
                                </div>
                            ))}

                        </div>
                    ) : ''}
                </div>
            </div>

            {task.task.id ? (
                <div className="row add">
                    <div className="form">
                        <input
                            type="text"
                            className="form_input"
                            id="kanbanTaskChecklist"
                            value={checklistName}
                            onChange={(e) => setChecklistName(e.target.value)}
                            placeholder=""
                        />
                        <span className="form_label">Название чеклиста</span>
                    </div>
                    <button type="button" className="btn_add" onClick={() => { onAddCheckList(checklistName, task); setChecklistName(''); }}>Добавить</button>
                </div>
            ) : ''}

            <div className="checklist_container">
                {task.task.checklists.length ? (
                    <div className="checklists">
                        {task.task.checklists.map((checklist, checklist_index) => (
                            <div className="checklist">
                                <div className="top">
                                    <div className="left">
                                        {checklist.edit === true ? (
                                            <div className="checklist_row">

                                                <div className="form">
                                                    <input
                                                        type="text"
                                                        className="form_input"
                                                        id={`kanbanTaskTitle${checklist_index}`}
                                                        value={checklist.name}
                                                        onChange={(e) => onEditChecklistName(task, checklist_index, e.target.value)}
                                                        placeholder=""
                                                    />
                                                    <span className="form_label">Название чеклиста</span>
                                                </div>
                                            </div>

                                        ) : (
                                            <div className="checklist_title">{checklist.name}</div>
                                        )}

                                    </div>
                                    <div className="right">
                                        {checklist.edit === true ? (
                                            <div
                                                className="checklist_success"
                                                onClick={() => onEnableEdidChecklistName(task, checklist_index)}
                                            >
                                                <TaskCheckboxSuccesSvg />
                                            </div>
                                        ) : (
                                            <div
                                                className="checklist_edit"
                                                onClick={() => onEnableEdidChecklistName(task, checklist_index)}
                                            >
                                                <PencilSvg />
                                            </div>
                                        )}

                                        <div
                                            className="checklist_remove"
                                            onClick={() => onDeleteChecklist(task, checklist_index)}
                                        >
                                            <TrashSvg />
                                        </div>
                                        {checklist.edit === true ? (
                                            null
                                        ) : (
                                            <div className="checklist_created">{getDate(checklist.created_at)}</div>

                                        )}
                                    </div>
                                </div>

                                <div className="checklist_row">
                                    <div className="form">
                                        <input
                                            type="text"
                                            className="form_input"
                                            id={`kanbanTaskСhecklist${checklist_index}`}
                                            value={checklist.input}
                                            onChange={(e) => onChangeChecklistInput(e.target.value, checklist_index, task)}
                                            placeholder=""
                                        />
                                        <span className="form_label">Добавить чекбокс</span>
                                        <span
                                            onClick={() => onAddCheckboxesChecklist(checklist.id, checklist.input, checklist_index, task)}
                                            className="plus"
                                        >
                                            +
                                        </span>
                                    </div>
                                </div>

                                {checklist.checkboxes.length ? (
                                    <div className="checkboxes">
                                        {checklist.checkboxes.map((checkbox, checkbox_index) => (
                                            <div className={`checkbox ${checkbox.complete ? 'active' : ''}`}>

                                                {checkbox.editName ? (
                                                    <div className="checkbox_row">

                                                        <div className="form">
                                                            <input
                                                                type="text"
                                                                className="form_input"
                                                                id={`kanbanTaskTitle${checkbox_index}`}
                                                                value={checkbox.name}
                                                                onChange={(e) => onChangeCheckboxName(e.target.value, task, checklist_index, checkbox_index)}
                                                                placeholder=""
                                                            />
                                                            <span className="form_label">Название чекбокса</span>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="left"
                                                        onClick={() => onUpdateCheckbox(task, checklist_index, checkbox_index)}
                                                    >
                                                        <input type="checkbox" id="taskCheckbox6" className="taskCheckboxInput" />
                                                        <div className="boxCheckbox">
                                                            <span className="success_icon">
                                                                <TaskCheckboxSuccesSvg />
                                                            </span>
                                                        </div>

                                                        <div className="middle">
                                                            <div className="checkbox_title">{checkbox.name}</div>
                                                        </div>

                                                    </div>
                                                )}

                                                <div className="right">
                                                    <div className="checkbox_edit" onClick={() => onEnableEdidCheckboxName(task, checklist_index, checkbox_index)}>
                                                        {checkbox.editName ? (
                                                            <TaskCheckboxSuccesSvg />
                                                        ) : (
                                                            <PencilSvg />
                                                        )}

                                                    </div>
                                                    <div className="checkbox_remove" onClick={() => onDeleteCheckbox(task, checklist_index, checkbox_index)}><TrashSvg /></div>
                                                    <div className="checkbox_add" onClick={() => onAddCheckList(checkbox.name, task)}><ExitSvg /></div>
                                                </div>

                                            </div>
                                        )).reverse()}
                                    </div>
                                ) : (
                                    <span className="empty" />
                                )}

                            </div>
                        )).reverse()}
                    </div>
                ) : (
                    <>
                    </>
                )}
            </div>

        </div>
    );
};

export default TaskEditForm;
