import { useEffect, useState } from 'react';
import { IActions, IMultySelectSearch } from '../../../../../assets/interfaces/interfaces';
import {
    IDocCoordinationData, IDocCreateDocument, IDocDoneData, IDocSendData,
} from '../../../../../assets/interfaces/modules/doc/doc_interface';
import { IDocTableTemplateData } from '../../../../../assets/interfaces/modules/doc/tab/doc_template_interface';
import EditModal from '../../../../../components/Modals/EditModal';
import { formatDateYMDHMS } from '../../../../../utils/func';
import { getTemplatesAPI } from '../../api/documentAPI';
import { isRespSuccess } from '../../config/funcs';
import DocAddModalContent from './DocAddModalContent';

interface IProps {
    show: boolean;
    onCloseAddModal: () => void;
    onSaveAddModal: (response: IDocCreateDocument) => Promise<boolean>;
}
const DocAddDocumentModal = ({
    onSaveAddModal, onCloseAddModal, show,
}: IProps) => {
    const [actions, setActions] = useState<IActions[]>([
        {
            text: 'Важно', active: false, svg: 'ExclamationMarkSvg', active_bg: '#f59e0b',
        },
    ]);
    const [spaceZoneClicked, setSpaceZoneClicked] = useState<boolean>(false);
    const [dropDownFiles, setDropDownFiles] = useState<File[]>([]);
    const [topic, setTopic] = useState('');
    const [documentTemplateId, setDocumentTemplateId] = useState<number>(-1);
    const [sendDocData, setSendDocData] = useState<IDocSendData>({
        number: '', city: '', firstDate: '', secondDate: '',
    });
    const [coordinationData, setCoordinationData] = useState<IDocCoordinationData>({
        data: '',
    });
    const [selectedDocs, setSelectedDocs] = useState<IMultySelectSearch[]>([]);
    const [doneDocData, setDoneDocData] = useState<IDocDoneData>({ situation: '', data: '', solution: '' });
    function onChangeSpaceZoneClicked(value: boolean) {
        setSpaceZoneClicked(value);
    }
    const onActiveDo = (index: number) => {
        const newArr = [...actions];
        newArr[index].active = !newArr[index].active;
        setActions(newArr);
    };
    const onChangeTypeDoc = (id: number) => {
        setDocumentTemplateId(id);
    };
    const onChangeSelectUsers = (users: IMultySelectSearch[]) => {

    };

    const onChangeFilesAddModal = (files: File[]) => {
        setDropDownFiles(files);
    };
    const onChangeDoneDataAddModal = (text: string, key: keyof IDocDoneData) => {
        const data = { ...doneDocData };
        data[key] = text;
        setDoneDocData(data);
    };
    const onChangeSendDataAddModal = (text: string, key: keyof IDocSendData) => {
        const data = { ...sendDocData };
        data[key] = text;
        setSendDocData(data);
    };
    const onChangeCoordinationData = (text: string, key: keyof IDocCoordinationData) => {
        const data = { ...coordinationData };
        data[key] = text;
        setCoordinationData(data);
    };
    const getSaveData = ():IDocCreateDocument => {
        const now = formatDateYMDHMS(new Date());
        const data: IDocCreateDocument = {
            topic,
            creator: 'Фамилия Имя Отчество',
            send_file: dropDownFiles[0],
            is_important: actions[0].active ? 1 : 0,
            document_state_id: 2,
            document_template_id: documentTemplateId,
            send_date: now,
            situation: doneDocData.situation,
            solution: doneDocData.solution,
            create_date: now,
            created_at: now,
            user_id: 57,
        };
        return data;
    };
    const onSave = async () => {
        const ok = await onSaveAddModal(getSaveData());
        if (ok) {
            setDropDownFiles([]);
            setTopic('');
            setDocumentTemplateId(-1);
            const newActions = [...actions];
            newActions.forEach((val) => val.active = false);
            setActions(newActions);
            setSelectedDocs([]);
        }
    };

    const onSelectDoc = (vals: IMultySelectSearch[], index: number) => {
        console.log('vals', vals);
        setSelectedDocs(vals);
        const { id } = vals[index];
        if (id) {
            onChangeTypeDoc(id);
        }
    };
    async function getDocTypes() {
        const resp = await getTemplatesAPI();
        if (isRespSuccess(resp)) {
            const docTypes:IMultySelectSearch[] = [];
            resp.data.data.forEach((val:IDocTableTemplateData) => {
                docTypes.push({
                    id: val.id, selected: false, show: true, subtitle: val.name, title: val.full_name,
                });
            });
            setSelectedDocs(docTypes);
        }
    }

    useEffect(() => {
        getDocTypes();
    }, []);
    return (
        <EditModal
            show={show}
            onClickSpaceZone={() => onChangeSpaceZoneClicked(!spaceZoneClicked)}
            onCloseModal={() => onCloseAddModal()}
            onClickSave={() => onSave()}
        >
            <DocAddModalContent
                actions={actions}
                onClickSpaceZone={() => onChangeSpaceZoneClicked(!spaceZoneClicked)}
                doneDocData={doneDocData}
                onActiveDo={onActiveDo}
                onChangeDoneData={onChangeDoneDataAddModal}
                onChangeFiles={onChangeFilesAddModal}
                onChangeSendData={onChangeSendDataAddModal}
                sendDocData={sendDocData}
                sendCoordinationData={coordinationData}
                onChangeCoordinationData={onChangeCoordinationData}
                theme={topic}
                setTheme={(text: string) => setTopic(text)}
                spaceZoneClicked={spaceZoneClicked}
                dropDownFiles={dropDownFiles}
                onChangeTypeDoc={onChangeTypeDoc}
                onSelectDoc={onSelectDoc}
                selectedDocs={selectedDocs}
            />
        </EditModal>
    );
};
export default DocAddDocumentModal;
