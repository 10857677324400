import {
    ButtonHTMLAttributes,
    memo,
    ReactNode,
    useState,
} from 'react';
import { classNames, Mods } from '../../../utils/classNames/classNames';
import styles from './Button.module.scss';

export enum ThemeButton {
  CLEAR = 'clear',
  DEFAULT = 'default',
  SVG_CLEAN = 'svg_clean',
  OUTLINE = 'outline',
  SVG_BTN = 'svg_btn',
  SVG_CIRCLE = 'svg_circle',
  SELECTOR = 'selector',
  SELECTOR_INVERTED = 'selector_inverted',
  TAB = 'tab',
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  theme?: ThemeButton;
  disabled?: boolean;
  children?: ReactNode;
  helper?: boolean;
  helperText?: string;
}

export const Button = memo((props : ButtonProps) => {
    const {
        className, children, disabled, helper, helperText, theme = ThemeButton.CLEAR, ...otherProps
    } = props;

    const [isShown, setIsShown] = useState(false);

    const mods: Mods = {
        [styles[theme]]: true,
        [styles.disabled]: disabled,
    };

    return (
        <button
            disabled={disabled}
            type="button"
            className={classNames(styles.Button, mods, [className])}
            {...otherProps}
        >
            {children}
        </button>

    );
});
