interface IProps {
    options: {value: string, text: string, disabled?: boolean}[];
    label: string;
    htmlFor: string;
    classNameLabel?: string;
    value: string;
    showInput?: boolean;
    onChange: React.ReactEventHandler<HTMLSelectElement>;
}
const SelectLabelDropdown = ({
    label, showInput, options, value, onChange, htmlFor, classNameLabel,
}: IProps) => {
    require('../../assets/styles/html/SelectDropdown.scss');
    return (
        <label className={classNameLabel} htmlFor={htmlFor}>
            {label}
            <select
                className="dropdown"
                onChange={onChange}
                value={value}
                name={htmlFor}
                id={htmlFor}
            >
                {options.map((val) => (
                    <option disabled={val.disabled} value={val.value}>{val.text}</option>
                ))}
            </select>
            {showInput
                ? <input type="text" name={htmlFor} id={htmlFor} placeholder="" />
                : null}
        </label>
    );
};

export default SelectLabelDropdown;
