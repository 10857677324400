import { memo, useCallback, useState } from 'react';
import {
    IDocInputSections, IDocPage, IDocTable,
} from '../../../../../assets/interfaces/modules/doc/doc_interface';
import { INCOMING_TAB } from '../../../../../utils/tabs';
import { getDocumentStateByText, getIndexById } from '../../config/funcs';
import DocLayoutTab from '../layouts/DocLayoutTab';

interface IProps {
    data: IDocTable;
    onChangeData: (data: IDocTable, page: keyof IDocPage) => void;
    onCloseTab: () => void;
}
const tableHeaders = [
    { text: 'Документ', class: 'doc' },
    { text: 'От кого', class: 'to' },
    { text: 'Дата создания', class: 'created' },
    { text: 'Дата отправки', class: 'send' },
    { text: 'Статус', class: 'status' },
    { text: 'Исполнитель задачи', class: 'performer' },
    { text: '', class: '' },
];
const DocIncomingTab = memo(({ onCloseTab, onChangeData, data }: IProps) => {
    const [docSections, setDocSections] = useState<IDocInputSections[]>([
        { text: 'Все документы', active: true, count: 0 },
        { text: 'Отправленные на согласование', active: false, count: 0 },
        { text: 'В процессе согласования', active: false, count: 0 },
        { text: 'Согласованные', active: false, count: 0 },
        { text: 'Отменные', active: false, count: 0 },
    ]);
    const [docTypes, setDocTypes] = useState<IDocInputSections[]>([
        { text: 'Все документы', active: true },
        { text: 'Согласование договора', active: false },
        { text: 'Передача функционала', active: false },
    ]);
    const onChangeDocSections = useCallback((sections: IDocInputSections[]) => {
        setDocSections(sections);
    }, []);
    const onChangeDocTypes = useCallback((sections: IDocInputSections[]) => {
        setDocTypes(sections);
    }, []);

    const onChangeTablePage = useCallback((page: number) => {
        const newData = { ...data };
        newData.currentPage = page;
        onChangeData(newData, INCOMING_TAB);
    }, [data]);
    const onChangeStatus = useCallback((id: number, btnText: string) => {
        const newData = { ...data };
        const document_state_id = getDocumentStateByText(btnText);
        const index = getIndexById(data.data, id);
        if (document_state_id) {
            data.data[index].document_state_id = document_state_id;
            onChangeData(newData, INCOMING_TAB);
        }
    }, [data]);

    return (
        <DocLayoutTab
            title="Входящие документы"
            tableData={data}
            tab={INCOMING_TAB}
            isIncomingTab
            tableHeaders={tableHeaders}
            docSections={docSections}
            docTypes={docTypes}
            onChangeTablePage={onChangeTablePage}
            onCloseTab={onCloseTab}
            onChangeStatus={onChangeStatus}
            onChangeData={onChangeData}
            onChangeDocSections={onChangeDocSections}
            onChangeDocTypes={onChangeDocTypes}
        />
    );
});

export default DocIncomingTab;
