import { addDays } from 'date-fns';
import { useState } from 'react';
import { IDateRange } from '../../../../../assets/interfaces/interfaces';
import { IDocInputSections } from '../../../../../assets/interfaces/modules/doc/doc_interface';
import InputClose from '../input/InputClose';
import s from './Header.module.scss';

interface IProps {
    title: string;
    onInputSearchChange: Function;
    onCloseTab: () => void;
    onShowAddModal?: Function;
    onChangeDocSections?: Function;
    docSections?: IDocInputSections[];
    docTypes?: IDocInputSections[];
    onChangeDocTypes?: Function;
    showDatePicker?:boolean;
}
const DocHeader = ({
    title, showDatePicker, docTypes, onChangeDocTypes, onChangeDocSections, docSections, onShowAddModal, onInputSearchChange, onCloseTab,
}: IProps) => {
    const [selectedRange, setSelectedRange] = useState<IDateRange[]>([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection',
        },
    ]);
    const [otherSelectClicked, setOtherSelectClicked] = useState<boolean>(false);
    const [toggleActive, setToggleActive] = useState<boolean>(false);

    const getNewSections = (index: number, sections: IDocInputSections[]) => {
        const newSections = [...sections];
        newSections.map((val) => val.active = false);
        newSections[index].active = true;
        return newSections;
    };
    const changeInputSection = (index: number) => {
        if (onChangeDocSections && docSections) {
            onChangeDocSections(getNewSections(index, docSections));
        }
    };
    const changeInputDocTypes = (index: number) => {
        if (onChangeDocTypes && docTypes) {
            onChangeDocTypes(getNewSections(index, docTypes));
        }
    };
    const onOpenAddDocument = (open: boolean) => {
        if (onShowAddModal) {
            onShowAddModal(open);
        }
    };
    const onChangeSelectedRange = (value: IDateRange[]) => {
        setSelectedRange(value);
    };

    const onChangeInputSearch = (text: string) => {
        onInputSearchChange(text);
    };
    const onSelectClicked = () => {
        setOtherSelectClicked(!otherSelectClicked);
    };
    const onShowInputs = () => {
        setToggleActive(!toggleActive);
    };
    return (
        <div className={`${s.header} ${(docTypes || docSections || showDatePicker) ? s.wrap : ''}`}>
            <div className={s.left}>
                <span className={s.title}>{title}</span>
                {onShowAddModal ? (
                    <button
                        type="button"
                        className={s.button_create}
                        onClick={() => onOpenAddDocument(true)}
                    >
                        Создать документ
                    </button>
                ) : null}

            </div>
            {/* <div className="{s.right}">
                {(docTypes || docSections || showDatePicker) ? (
                    <div className={s.toggle_btn}>
                        <span onClick={() => onShowInputs()}>
                            <TogglerButton />
                        </span>
                    </div>
                ) : null}

                <div className={`{s.inputs} ${toggleActive ? s.show : ''}`}>
                    {docTypes
                        ? (
                            <div className={s.input}>
                                <InputSelect
                                    title="Тип документа"
                                    inputSections={docTypes}
                                    onChangeInputSection={changeInputDocTypes}
                                    onClickSelect={() => onSelectClicked()}
                                    otherSelectClicked={otherSelectClicked}
                                />
                            </div>
                        )
                        : null}
                    {docSections
                        ? (
                            <div className={s.input}>
                                <InputSelect
                                    title="Раздел документооборота"
                                    inputSections={docSections}
                                    onChangeInputSection={changeInputSection}
                                    onClickSelect={() => onSelectClicked()}
                                    otherSelectClicked={otherSelectClicked}
                                />
                            </div>
                        )
                        : null}
                    {showDatePicker !== false
                        ? (
                            <div className={s.input}>
                                <InputDatepicker
                                    selectedRange={selectedRange}
                                    onChangeSelectedRange={onChangeSelectedRange}
                                />
                            </div>
                        )
                        : null}

                </div>
                <div className={s.input}>
                    <InputSearch
                        onSearch={onChangeInputSearch}
                    />
                </div>

            </div> */}
            <div className={s.close}>
                <span
                    onClick={() => onCloseTab()}
                >
                    <InputClose />
                </span>
            </div>
            {/* <div className="mailing_header">
                <SmallGrayButton
                    text="Рассылка"
                />
            </div> */}
        </div>
    );
};
export default DocHeader;
