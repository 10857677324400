import { ITableTemplate } from '../../../../../../assets/interfaces/modules/doc/doc_interface';
import '../../../../../../assets/styles/modules/components/doc/table/field_table_tr.scss';
import { docCheckTemplateKeys } from '../../../config/funcs';
import DocTableTextarea from '../components/DocTableTextarea';
import DocTabelTrFiledTemplate from './templates/DocTabelTrFiledTemplate';
import DocTabelTrSignTemplate from './templates/DocTabelTrSignTemplate';
import DocTabelTrTemplateTemplate from './templates/DocTabelTrTemplateTemplate';

type IProps = {
    td?: any;
    tableTemplate: keyof ITableTemplate;
    index: number;
    create?: boolean;
    createRow?: any;
    onAddRow?: () => void;
    onDeleteRow?: (index: number) => void;
    onChangeField: (index:number, text: string, key: string) => void;
    onSelectClick?: (index: number) => void
    onChangeFiles?: (index: number, files: FileList | null) => void
}
const DocHandbookTabelTrLayout = ({
    td,
    index,
    createRow,
    create,
    tableTemplate,
    onAddRow,
    onDeleteRow,
    onChangeField,
    onSelectClick,
    onChangeFiles,
}: IProps) => {
// Helper functions

    const renderTitle = (key: string) => {
        let content;

        if (key === 'id' || key === 'desc' || key === 'type' || docCheckTemplateKeys(key)) {
            if (create && createRow) {
                const value = createRow[key];

                content = (
                    <DocTableTextarea
                        onChange={(text) => onChangeField(index, text, key)}
                        value={value}
                    />

                );
            } else if (td) {
                const value = td[key];

                if (value !== null) {
                    content = (
                        <span>{td[key]}</span>
                    );
                }
            }
        }

        return content;
    };

    return (
        <tr className="field_table_tr">
            {tableTemplate === 'sign' && (
                <DocTabelTrSignTemplate
                    index={index}
                    td={td}
                    tableTemplate={tableTemplate}
                    create={create}
                    createRow={createRow}
                    onAddRow={onAddRow}
                    onDeleteRow={onDeleteRow}
                    onChangeFiles={onChangeFiles}
                    onSelectClick={onSelectClick}
                />
            )}
            {tableTemplate === 'template' && (
                <DocTabelTrTemplateTemplate
                    td={td}
                    index={index}
                    tableTemplate={tableTemplate}
                    create={create}
                    createRow={createRow}
                    renderTitle={renderTitle}
                    onAddRow={onAddRow}
                    onChangeFiles={onChangeFiles}
                    onDeleteRow={onDeleteRow}
                    onSelectClick={onSelectClick}
                />
            )}
            {tableTemplate === 'fields' && (
                <DocTabelTrFiledTemplate
                    index={index}
                    createRow={createRow}
                    tableTemplate={tableTemplate}
                    create={create}
                    renderTitle={renderTitle}
                    onAddRow={onAddRow}
                    onDeleteRow={onDeleteRow}
                />
            )}
        </tr>

    );
};
export default DocHandbookTabelTrLayout;
