interface IProps {
    id: string,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    checked: boolean,
}
const RadioComponent = ({ id, onChange, checked }: IProps) => {
    require('../../assets/styles/html/radio.scss');
    return (
        <div className="radio_wrapper">
            <input
                type="radio"
                checked={checked}
                onChange={onChange}
                className="radio__input"
                name={id}
                id={id}
            />
            <span className="radio__inner" />
        </div>
    );
};

export default RadioComponent;
