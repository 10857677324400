import { useState } from 'react';
import {
    IDocInputSections, IDocPage, IDocTable,
} from '../../../../../assets/interfaces/modules/doc/doc_interface';
import { ARCHIVE_TAB } from '../../../../../utils/tabs';
import DocLayoutTab from '../layouts/DocLayoutTab';

interface IProps {
    data: IDocTable;
    onChangeData: (data: IDocTable, page: keyof IDocPage) => void;
    onCloseTab: () => void;
}
const tableHeaders = [
    { text: 'Документ', class: 'doc' },
    { text: 'Кому', class: 'to' },
    { text: 'Дата создания', class: 'created' },
    { text: 'Дата отправки', class: 'send' },
    { text: 'Статус', class: 'status' },
    { text: 'Исполнитель задачи', class: 'performer' },
    { text: '', class: '' },
];
const DocArchiveTab = ({ onCloseTab, onChangeData, data }:IProps) => {
    const [docSections, setDocSections] = useState<IDocInputSections[]>([
        { text: 'Все документы', active: true, count: 0 },
        { text: 'Отправленные на согласование', active: false, count: 0 },
        { text: 'В процессе согласования', active: false, count: 0 },
        { text: 'Согласованные', active: false, count: 0 },
        { text: 'Отменные', active: false, count: 0 },
    ]);
    const [docTypes, setDocTypes] = useState<IDocInputSections[]>([
        { text: 'Все документы', active: true },
        { text: 'Согласование договора', active: false },
        { text: 'Передача функционала', active: false },
    ]);
    const onChangeDocSections = (sections: IDocInputSections[]) => {
        setDocSections(sections);
    };
    const onChangeDocTypes = (sections: IDocInputSections[]) => {
        setDocTypes(sections);
    };
    const onChangeTablePage = (page: number) => {
        const newData = { ...data };
        newData.currentPage = page;
        onChangeData(newData, ARCHIVE_TAB);
    };

    return (
        <DocLayoutTab
            title="Архив документов"
            isArchive
            tableData={data}
            onChangeTablePage={onChangeTablePage}
            onChangeData={onChangeData}
            tab={ARCHIVE_TAB}
            tableHeaders={tableHeaders}
            onCloseTab={onCloseTab}
            docSections={docSections}
            docTypes={docTypes}
            onChangeDocSections={onChangeDocSections}
            onChangeDocTypes={onChangeDocTypes}
        />
    );
};

export default DocArchiveTab;
