import '../../../assets/styles/admin/admin_table_layout.scss';

interface IProps {
    id?: string;
    className: string;
    children: any;
}
const AdminTableLayout = ({ id, className, children }: IProps) => (
    <table
        id={id}
        className={`admin_table_layout ${className}`}
        border={1}
        cellSpacing={0}
    >
        <thead>
            {children[0]}
        </thead>
        {children[1]}
    </table>
);

export default AdminTableLayout;
