import Admin from './pages/Admin';
import Hello from './pages/Hello';
import HelloMiddleware from './pages/HelloMiddleware';
import Login from './pages/Login';
import DocumentooborotPage from './pages/modules/DocumentooborotModule/DocumentooborotPage';
import OrgSchemaModule from './pages/modules/OrgSchemaModule/OrgSchemaModule';
import TasksModule from './pages/modules/TasksModule/TasksModule';
import Register from './pages/Register';
import Starter from './pages/Starter';
import User from './pages/User';
import {
    ADMIN_ROUTE,
    DOC_ROUTE,
    HELLO_ROUTE,
    LOGIN_ROUTE,
    ORG_SCHEMA,
    REGISTER_ROUTE,
    STARTER_ROUTE,
    TASKS_ROUTE,
    USER_ROUTE,
} from './utils/consts';

export const authRoutes = [
    {
        path: STARTER_ROUTE,
        component: <Starter />,
    },
    {
        path: ADMIN_ROUTE,
        component: <Admin />,
    },
    {
        path: USER_ROUTE,
        component: <User />,
    },

];
export const publicRoutes = [
    // {
    //     path: SESSION_ROUTE,
    //     component: <ContinueSession />
    // },
    {
        path: ADMIN_ROUTE,
        component: <Admin />,
    },
    {
        path: TASKS_ROUTE,
        component: <TasksModule />,
    },
    {
        path: DOC_ROUTE,
        component: <DocumentooborotPage />,
    },
    {
        path: STARTER_ROUTE,
        component: <HelloMiddleware />,
    },
    {
        path: HELLO_ROUTE,
        component: <Hello />,
    },
    {
        path: LOGIN_ROUTE,
        component: <Login />,
    },
    {
        path: REGISTER_ROUTE,
        component: <Register />,
    },
    {
        path: ORG_SCHEMA,
        component: <OrgSchemaModule />,
    },
];
