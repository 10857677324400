interface IProps {
    id: string,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    value: string|undefined,
    className?: string,
}
const InputFiled = ({
    className, id, onChange, value,
}: IProps) => {
    require('../../assets/styles/html/InputFiled.scss');
    return (
        <input
            type="text"
            className={`InputFiled ${className}`}
            id={id}
            name={id}
            onChange={onChange}
            value={value}
            placeholder=""
        />
    );
};

export default InputFiled;
