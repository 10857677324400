import s from './SubTd.module.scss';

interface IProps {
    title: string;
    children: React.ReactNode;
}
const DocTableSubTd = ({
    children, title,
}: IProps) => (
    <td className={s.subtd} key={title}>
        <div className={s.subtd_div}>
            <div className={s.title}>{title}</div>
            <div className={s.value}>
                {children}
            </div>
        </div>
    </td>
);
export default DocTableSubTd;
