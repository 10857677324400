import { memo } from 'react';
import {
    IAddToArchiveResponse,
    IDocTableData, IDocTableHeader,
} from '../../../../../assets/interfaces/modules/doc/doc_interface';
import '../../../../../assets/styles/modules/components/doc/table/module_table_doc.scss';
import TabelPagination from './TablePagination';
import DocIncomingTableTr from './tr/DocIncomingTableTr';

interface IProps {
    headers: IDocTableHeader[]
    currentPage: number;
    isIncomingTab: boolean;
    pageNumbers: Array<number>;
    data: IDocTableData[];
    onOpenTask: Function;
    isArchive?: boolean;
    onOpenTdSelectUser: Function;
    onChangePage: (clickedPage: number) => void;
    onOpenDetails: (id: number) => void;
    onChangeStatus?: (id: number, btnText: string) => void;
    onShowFileHandler: (td: IDocTableData) => void;
    onDeleteDoc: (td: IDocTableData) => void;
    onDenyAgreement: (td: IDocTableData) => void;
    onAddToArchive: (response: IAddToArchiveResponse) => void;
    onChangeComment: (index: number, text: string) => void;
}
const TableDocModule = memo(({
    headers,
    pageNumbers,
    onChangePage,
    isIncomingTab,
    isArchive,
    onOpenTask,
    onOpenTdSelectUser,
    currentPage,
    data,
    onAddToArchive,
    onDeleteDoc,
    onDenyAgreement,
    onOpenDetails,
    onShowFileHandler,
    onChangeComment,
    onChangeStatus,
}: IProps) => {
    const onClickPagination = (page: number) => {
        if (page < (pageNumbers.length + 1) && page > 0) {
            onChangePage(page);
        }
    };

    const renderTableContent = () => {
        if (data.length > 0) {
            return data.map((td, index) => (
                <DocIncomingTableTr
                    key={td.id}
                    td={td}
                    isIncomingTab={isIncomingTab}
                    index={index}
                    onOpenTask={onOpenTask}
                    isArchive={!!isArchive}
                    onOpenSelectUser={onOpenTdSelectUser}
                    onAddToArchive={onAddToArchive}
                    onDeleteDoc={onDeleteDoc}
                    onDenyAgreement={onDenyAgreement}
                    onOpenDetails={onOpenDetails}
                    onShowFileHandler={onShowFileHandler}
                    onChangeComment={onChangeComment}
                    onChangeStatus={onChangeStatus}
                />

            ));
        }

        return (
            <tr className="table_empty" />
        );
    };
    return (
        <div className="module_table_doc">
            <div className="table_responsive">
                <table className="table">
                    <thead className="thead">
                        <tr>
                            {headers.map((th) => (
                                <th key={th.text} className={`th ${th.class}`}>{th.text}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="tbody">
                        {renderTableContent()}
                    </tbody>
                </table>
            </div>
            <div
                className="table_pagination"
            >
                <TabelPagination
                    currentPage={currentPage}
                    pageNumbers={pageNumbers}
                    onClick={onClickPagination}
                />
            </div>

        </div>
    );
});
export default TableDocModule;
