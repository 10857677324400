import s from './Pagination.module.scss';

interface IProps {
    onClick: Function;
    pageNumbers: Array<number>;
    currentPage: number;
}

const TablePagination = ({ onClick, pageNumbers, currentPage }: IProps) => (
    <div className={s.pagination_container}>
        <nav>
            <ul className={s.pagination}>
                <li className={s.li}>
                    <button type="button" onClick={() => onClick(currentPage - 1)} className={s.pageButton}>
                        &lt;
                    </button>
                </li>
                {pageNumbers.map((number) => (
                    <li key={number} className={s.li} style={{ display: (number > (currentPage + 3) || (number < (currentPage - 3))) ? 'none' : '' }}>
                        <button type="button" onClick={() => onClick(number)} className={`${s.pageButton} ${currentPage === number ? s.current : ''}`}>
                            {number}
                        </button>
                    </li>
                ))}
                <li className={s.li}>
                    <button type="button" onClick={() => onClick(currentPage + 1)} className={s.pageButton}>
                        &gt;
                    </button>
                </li>
            </ul>
        </nav>
    </div>
);

export default TablePagination;
