import { useState } from 'react';
import { IDocFieldTable, IDocTableFieldData } from '../../../../../assets/interfaces/modules/doc/tab/doc_field_interface';
import DocHandbookLayout from '../layouts/DocHandbookLayout';

interface IProps {
    onCloseTab: () => void;
}
const DocFieldTab = ({ onCloseTab }: IProps) => {
    const [tableData, setTableData] = useState<IDocFieldTable>({
        data: [
            {
                id: 'short_title', desc: 'full_title', type: 'text',
            },
        ],
        currentPage: 1,
        todosPerPage: 10,
    });
    const [createRow, setCreateRow] = useState<IDocTableFieldData>({
        id: '', desc: '', type: '',
    });
    const onChangeTablePage = (page: number) => {
        const newData = { ...tableData };
        newData.currentPage = page;
        setTableData(newData);
    };
    const clearCreateRow = () => {
        setCreateRow({
            id: '', desc: '', type: '',
        });
    };
    const onAddRow = (newData: IDocFieldTable) => {
        setTableData(newData);
        clearCreateRow();
    };
    const onDeleteRow = (newData: IDocFieldTable) => {
        setTableData(newData);
    };

    const onChangeCreateRow = (key: keyof IDocTableFieldData, value: string) => {
        const newData = { ...createRow };
        newData[key] = value;
        setCreateRow(newData);
    };
    const onChangeDataRow = (index: number, key: keyof IDocTableFieldData, value: string) => {
        const newData = { ...tableData };
        newData.data[index][key] = value;
        setTableData(newData);
    };
    const getDeleteName = (index: number):string => tableData.data[index].id;
    return (
        <DocHandbookLayout
            title="Справочник полей"
            tableTemplate="fields"
            tableData={tableData}
            onChangeTablePage={onChangeTablePage}
            createRow={createRow}
            onChangeCreateRow={onChangeCreateRow}
            onChangeDataRow={onChangeDataRow}
            onAddRow={onAddRow}
            onDeleteRow={onDeleteRow}
            getDeleteName={getDeleteName}
            tableHeaders={[
                { text: 'Идентификатор столбца' },
                { text: 'Описание поля' },
                { text: 'Тип поля' },
                { text: '' },
            ]}
            onCloseTab={onCloseTab}
        />
    );
};

export default DocFieldTab;
