import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IModuleTab } from '../../../assets/interfaces/interfaces';
import PageWithTabsLayout from '../layout/PageWithTabsLayout';
import TasksTemplate from './template/TasksTemplate';

const TasksModule = () => {
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState<string>('me');
    const [searchInput, setSearchInput] = useState<string>('e');

    const [tabs, setTabs] = useState<IModuleTab[]>([
        {
            name: 'me', text: 'Задачи мне', active: true, count: 0,
        },
        {
            name: 'tome', text: 'Поставленные мной', active: false, count: 0,
        },
        {
            name: 'audit', text: 'Аудиторские', active: false, count: 0,
        },
        {
            name: 'archive', text: 'В архиве', active: false, count: 0,
        },
    ]);
    const onChangeTab = (index: number) => {
        const newTabs = [...tabs];
        newTabs.forEach((val) => val.active = false);
        const tab = newTabs[index];
        tab.active = true;
        if (tab.name === 'archive') {
            navigate(`${window.location.pathname}?archived=1`);
        } else {
            navigate(`${window.location.pathname}`);
        }
        setCurrentTab(tab.name);
    };
    const onChangeTabs = (newTabs: IModuleTab[]) => {
        setTabs(newTabs);
    };
    return (
        <PageWithTabsLayout tabs={tabs} onChangeModuleTab={onChangeTab} onChangeSearchInput={setSearchInput}>
            <TasksTemplate currentTab={currentTab} tabs={tabs} onChangeTabs={onChangeTabs} searchInput={searchInput} />
        </PageWithTabsLayout>
    );
};

export default TasksModule;
