import PropTypes from 'prop-types';
import React from 'react';
import BackCrumbsDate from './BackCrumbsDate';
import Header from './Header';

interface INameProps {
    children: React.ReactNode;
}

const Layout: React.FC<INameProps> = ({ children }) => (
    <>
        <Header />
        <BackCrumbsDate />
        {children}
    </>
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
