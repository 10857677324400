import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    IEditTask,
    IModuleTab,
    ITask, ITaskData, ITaskErrors,
    ITaskFile,
} from '../../../../assets/interfaces/interfaces';
import '../../../../assets/styles/user/pages/user_tasks_page.scss';
import SureModal from '../../../../components/Modals/SureModal';
import {
    addCheckboxesChecklistAPI, addChecklistAPI, addCommentTaskAPI, addTaskAPI, deleteCheckboxAPI, deleteChecklistAPI, deleteCommentTaskAPI, deleteFileTaskAPI, deleteTaskAPI, editTaskAPI, getArchivedTasksAPI, getCommentsTaskAPI, getTaskAPI, getTasksAPI, updateCheckboxChecklistAPI, updateChecklistAPI, updateCommentTaskAPI,
} from '../../../../http/task/taskAPI';
import { TASKS_ROUTE } from '../../../../utils/consts';
import { getCookie } from '../../../../utils/cookies';
import { ITaskColumn, TASK_COLUMNS } from '../../../../utils/tasks';
import TaskBoard from '../components/Drag/TaskBoard';
import TaskEdit from '../components/TaskEdit';

interface ITasksChangeParams {
    key: string,
    value: any
}
interface ITasksDataChangeParams {
    key: keyof ITask,
    value: any
}
type IProps = {
    searchInput: string;
    currentTab: string;
    tabs: IModuleTab[];
    onChangeTabs: (tabs: IModuleTab[]) => void;
}
const TasksTemplate = ({
    currentTab, tabs, searchInput, onChangeTabs,
}: IProps) => {
    const navigate = useNavigate();

    const [data, setData] = useState<ITaskData>({
        'В работе': [], 'Ждут проверки': [], Завершенные: [], Новые: [], Отложенные: [],
    });
    const [errors, setErrors] = useState<ITaskErrors>({ name: false });

    const [openEdit, setOpenEdit] = useState<IEditTask>();
    const [textOnConfirm, setTextOnConfirm] = useState<string>('');
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState<boolean>(false);
    const [openArchiveConfirm, setOpenArchiveConfirm] = useState<boolean>();
    const [paramsForConfirm, setParamsForConfirm] = useState<any>();
    const setOpenEditHandler = (task: ITask, edit: boolean, status: keyof ITaskData) => {
        const newOpenEdit: IEditTask = {
            edit,
            status,
            auditors: task.auditors,
            checklists: task.checklists,
            comments: task.comments,
            files: task.files,
            history: task.history,
            performers: task.performers,
            task,
        };
        setOpenEdit(newOpenEdit);
    };
    const getStatusByTaskStateId = (task_state_id: number): keyof ITaskData => {
        let status: keyof ITaskData = '';
        TASK_COLUMNS.forEach((column) => {
            if (column.task_state_id === task_state_id) {
                status = column.status;
            }
        });
        return status;
    };
    const findTaskIndex = (task_id: number, status: keyof ITaskData) => data[status].findIndex((val) => val.id === task_id);

    const changeDataTasks = async (index: number, status: keyof ITaskData, paramsForChange?: ITasksDataChangeParams[], replacedTask?: any) => {
        const updatedData = { ...data };
        if (paramsForChange) {
            paramsForChange.forEach((val) => {
                updatedData[status][index][val.key] = val.value;
            });
        } else if (replacedTask) {
            updatedData[status][index] = replacedTask;
        }
        setData(updatedData);
    };
    const getTask = async (task_id: number) => {
        const response = await getTaskAPI(task_id);
        if (response.status === 200) {
            const { data } = response.data;
            data.status = getStatusByTaskStateId(data.task.task_state_id);
            return data;
        }
        return null;
    };
    const taskToFormData = (task: IEditTask, values: ITasksChangeParams[]): FormData => {
        const formData = new FormData();

        // Use forEach to iterate over values
        values.forEach((val) => {
            if (val.key !== 'addedFiles' && val.value) {
                formData.append(val.key, String(val.value));
            }
        });

        // Use forEach to iterate over addedFiles instead of a for...of loop
        if (task.task.addedFiles) {
            Array.from(task.task.addedFiles.entries()).forEach(([index, file]) => {
                formData.append(`task_file_${index}`, file);
            });
        }

        return formData;
    };
    const editTaskHandler = async (editValues?: ITasksChangeParams[], completedFormData?: FormData) => {
        let formData = new FormData();
        if (editValues) {
            editValues.forEach((val) => {
                formData.append(val.key, String(val.value));
            });
        } else if (completedFormData) {
            formData = completedFormData;
        }
        return editTaskAPI(formData);
    };
    const toTaskPage = (task_id: number, data?: IEditTask) => {
        // Открытие окна редактирования
        const routeSpit = TASKS_ROUTE.split('/');
        routeSpit.shift();
        let route = '/';
        routeSpit[1] = String(task_id);
        route = `/${routeSpit.join('/')}`;
        if (data) {
            setOpenEdit({ ...data, edit: false });
        }
        navigate(route);
    };
    const toTask = async (task_id: number) => {
        if (task_id !== 0) {
            const data = await getTask(task_id);
            if (data) {
                data.status = getStatusByTaskStateId(data.task.task_state_id);
                toTaskPage(task_id, data);
            }
            return;
        }
        toTaskPage(task_id);
    };
    const getTasks = async () => {
        const response = await getTasksAPI();
        const responseArchive = await getArchivedTasksAPI();
        if (response.status === 200 && responseArchive.status === 200) {
            const resp = response.data.data;
            const respArchive = responseArchive.data.data;
            const newTasks: ITaskData = {
                Новые: [], 'В работе': [], 'Ждут проверки': [], Завершенные: [], Отложенные: [],
            };
            TASK_COLUMNS.forEach((column) => {
                resp[column.status].forEach((task: ITask) => {
                    newTasks[column.status].push(task);
                });
                respArchive[column.status].forEach((task: ITask) => {
                    newTasks[column.status].push(task);
                });
            });

            setData(newTasks);
            const task_id = Number(window.location.pathname.split('/')[2]);
            toTask(task_id);
        }
    };
    const onEditSaveHandler = async (task: IEditTask) => {
        let formData = new FormData();
        const values: ITasksChangeParams[] = [
            { key: 'id', value: task.task.id },
            { key: 'name', value: task.task.name },
            { key: 'task_state_id', value: task.task.task_state_id },
            { key: 'started_at', value: task.task.started_at },
            { key: 'finished_at', value: task.task.finished_at },
            { key: 'descriptions', value: task.task.descriptions },
            { key: 'color_text', value: task.task.color_text },
            { key: 'addedFiles', value: task.task.addedFiles },
        ];
        formData = taskToFormData(task, values);

        const response = await editTaskHandler(undefined, formData);
        if (response.status === 200) {
            const index = data[task.status].findIndex((val) => task.task.id === val.id);
            const newTask = await getTask(task.task.id);
            if (newTask) {
                setOpenEditHandler(newTask.task, false, newTask.status);
                getTasks();
            }
            // onCloseEdit(task)
        }
    };

    const onFilterTasks = (find: string, status?: string) => {
        let li;
        let elItem;
        let topEl;
        let i;
        let txtValue;
        const txtUl = status ? `task_content ${status}` : 'task_content';
        const ul = document.getElementsByClassName(txtUl);
        [].forEach.call(ul, (el: any) => {
            li = el.getElementsByClassName('task_card');
            let count = li.length + 1;
            let classes = el.className.split(' ');
            classes.shift();
            classes = classes.join(' ');
            const status = document.getElementById(`status_${classes}`);
            if (status) {
                status.innerHTML = String(count - 1);
            }
            [].forEach.call(li, (item: any) => {
                elItem = item.getElementsByClassName('top');

                [].forEach.call(elItem, (top: any) => {
                    topEl = top.getElementsByClassName('title');
                    [].forEach.call(topEl, (span: any) => {
                        txtValue = span.textContent || span.innerText;

                        if (status) {
                            if (txtValue.toUpperCase().indexOf(find.toUpperCase()) > -1) {
                                item.style.display = '';
                            } else {
                                item.style.display = 'none';
                                count -= 1;
                                status.innerHTML = String(count - 1);
                            }
                        }
                    });
                });
            });
        });
    };
    const onAddTask = (task_state_id: number, status: keyof ITaskData) => {
        // Создание задачи
        setOpenEdit(undefined);
        const epmtyTask: ITask = {
            order: 0, editDesc: false, addedFiles: new FormData(), editTitle: false, archived_at: null, auditors: [], checklists: [], color_text: null, comments: [], created_at: '', deleted_at: null, descriptions: null, started_at: null, ending_at: null, files: [], finished_at: null, history: [], id: 0, locked: null, name: '', performers: [], task_state_id, updated_at: '', user_id: null,
        };
        const newData = { ...data };
        newData[status].unshift(epmtyTask);
        setData(newData);
        setOpenEditHandler(data[status][0], true, status);
    };
    const changeEditTask = (task: IEditTask) => {
        setOpenEditHandler(task.task, task.edit, task.status);
        changeDataTasks(findTaskIndex(task.task.id, task.status), task.status, undefined, task.task);
    };

    const onEditTask = async (index: number, status: keyof ITaskData) => {
        const taskToMove = data[status][index];
        toTask(taskToMove.id);
    };
    const moveTask = async (task: ITask, oldStatus: keyof ITaskData, column: ITaskColumn) => {
        const newListOrderValueMax = data[column.status]
            .map((item) => item.order)
            .reduce((maxValue, a) => Math.max(maxValue, a), 0);

        const tempGaveList = data[oldStatus]
            .filter((item) => item.id !== task.id)
            .sort((a, b) => a.order - b.order)
            .map((item, i: number) => ({ ...item, order: i + 1 }));

        const tempRecievedList = [
            ...data[column.status],
            {
                ...task,
                order: task ? task.order - 1 : newListOrderValueMax + 1,
            },
        ];
        setData((d) => ({ ...d, [oldStatus]: tempGaveList, [column.status]: tempRecievedList }));
        await editTaskHandler([
            { key: 'id', value: task.id },
            { key: 'task_state_id', value: column.task_state_id },
        ]);
    };
    const onTaskToComplete = async (task: ITask, status: keyof ITaskData, editedTask?: IEditTask) => {
        const lastIndexColumn = TASK_COLUMNS.length - 1;
        if (editedTask) {
            editedTask.task.task_state_id = TASK_COLUMNS[lastIndexColumn].task_state_id;
            changeEditTask(editedTask);
        }
        const task_index = findTaskIndex(task.id, status);
        changeDataTasks(task_index, status, [
            { key: 'task_state_id', value: TASK_COLUMNS[lastIndexColumn].task_state_id },
        ]);
        moveTask(task, status, TASK_COLUMNS[lastIndexColumn]);
    };
    const getTabsCount = () => {
        const newTabs = [...tabs];
        newTabs.forEach((tab) => {
            if (tab !== null) {
                tab.count = 0;
                TASK_COLUMNS.forEach((column) => {
                    data[column.status].forEach((task: ITask) => {
                        if (tab.count) {
                            // if (tab === 'me' && task.performers.includes(getCookie('userId'))) {
                            //     count += 1
                            // } else if (tab === 'tome' && task.user_id === getCookie('userId')) {
                            //     count += 1
                            // } else if (tab === 'audit' && task.auditors.includes(getCookie('userId'))) {
                            //     count += 1
                            // }
                            if (task.archived_at !== true && tab.name !== 'archive') {
                                tab.count += 1;
                            } else if (task.archived_at === true && tab.name === 'archive') {
                                tab.count += 1;
                            }
                        }
                    });
                });
            }
        });
        onChangeTabs(newTabs);
    };
    const addTask = async (task: IEditTask) => {
        const userId = getCookie('userId');
        if (userId) {
            const { name } = task.task;
            const response = await addTaskAPI(name, userId);
            if (response.status === 200) {
                const { task_id } = response.data.data;
                console.log('newTask.task', task.task);
                task.task.id = task_id;
                setOpenEditHandler(task.task, false, task.status);
                onEditSaveHandler(task);
                getTabsCount();
            }
        }
    };

    const onDeleteFile = async (task: IEditTask, file: ITaskFile, fileIndex: number) => {
        const response = await deleteFileTaskAPI(task.task.id, file.id);
        if (response.status === 200) {
            const newFiles = task.task.files;
            newFiles.splice(fileIndex, 1);
            task.task.files = newFiles;
            const task_index = findTaskIndex(task.task.id, task.status);
            const { status } = task;
            changeDataTasks(task_index, status, [
                { key: 'files', value: task.task.files },
            ]);
            changeEditTask(task);
        }
    };
    const getComments = async (task_id: number) => {
        const response = await getCommentsTaskAPI(task_id);
        if (response.status === 200) {
            return response.data.data.comments;
        }
        return [];
    };
    const onAddComment = async (task: IEditTask, newComment: FormData, commentText: string) => {
        if (commentText.replace(/\s/g, '').length === 0) return;
        const response = await addCommentTaskAPI(task.task.id, newComment);
        if (response.status === 200) {
            const comments = await getComments(task.task.id);
            task.task.comments = comments;
            changeEditTask(task);
        }
    };
    const onChangeComment = (task: IEditTask, commentIndex: number, text: string) => {
        task.task.comments[commentIndex].text = text;
        changeEditTask(task);
    };
    const onChangeDeadline = (date: string, time: string, task: IEditTask) => {
        const ketTime = time === 'started_at' ? 'started_at' : 'finished_at';
        task.task[ketTime] = date;
        changeEditTask(task);
    };

    const onEditComment = async (task: IEditTask, commentIndex: number, editData?: FormData) => {
        task.task.comments[commentIndex].edit = !task.task.comments[commentIndex].edit;
        changeEditTask(task);
        if (editData) {
            const response = await updateCommentTaskAPI(editData, task.task.id, task.task.comments[commentIndex].id);
        }
    };

    const onDeleteComment = async (task: IEditTask, commentIndex: number) => {
        const newComments = task.task.comments;
        const response = await deleteCommentTaskAPI(task.task.id, newComments[commentIndex].id);
        if (response.status === 200) {
            newComments.splice(commentIndex, 1);
            task.task.comments = newComments;
            changeEditTask(task);
        }
    };
    const onLock = async (task: IEditTask) => {
        const newLock = task.task.locked === null ? true : !task.task.locked;

        const response = await editTaskHandler([
            { key: 'id', value: task.task.id },
            { key: 'locked', value: newLock },
        ]);
        if (response.status === 200) {
            task.task.locked = newLock;
            changeEditTask(task);
        }
    };
    const onEditDesc = (task: IEditTask) => {
        task.task.editDesc = !task.task.editDesc;
        changeEditTask(task);
    };
    const checkErrorsHandler = (value: string, key: string | keyof ITask) => {
        if (key === 'name' && value.length === 0) {
            setErrors({ name: true });
            return;
        }
        setErrors({ name: false });
    };
    const onSetEditValue = (task: IEditTask, value: string, key: keyof ITask) => {
        checkErrorsHandler(value, key);
        if (Number(value)) {
            task.task[key] = Number(value);
        } else {
            task.task[key] = value;
        }
        changeEditTask(task);
    };
    const onChangeFiles = (task: IEditTask, files: FileList) => {
        task.task.addedFiles = new FormData();
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            task.task.addedFiles.append(String(i), file);
        }
        changeEditTask(task);
    };

    const onEnableEditTitle = (task: IEditTask) => {
        if (String(task.task.name).length > 0) {
            task.task.editTitle = !task.task.editTitle;
            task.task.edit = !task.task.edit;
            changeEditTask(task);
        }
    };

    const onSaveEdit = (task: IEditTask) => {
        if (String(task.task.name).length === 0) {
            setErrors((prev) => ({ ...prev, name: true }));
            return;
        }
        if (task.task.created_at === '') {
            addTask(task);
            return;
        }

        setErrors({ name: false });
        onEditSaveHandler(task);
    };
    const onCloseEdit = (task: IEditTask) => {
        const { status } = TASK_COLUMNS[0];
        if (String(data[status][0].name) === '') {
            const updatedTasks: ITaskData = { ...data };
            updatedTasks[status] = updatedTasks[status].filter((val: ITask, i: number) => val.id !== task.task.id);
            setData(updatedTasks);
        }
        setOpenEdit(undefined);
        toTask(0);
    };

    interface IOnYesArchiveConfirm {
        index: number | null,
        status?: keyof ITaskData,
        task?: IEditTask,
    }
    const onAddTaskToArchive = async (params: IOnYesArchiveConfirm) => {
        setOpenArchiveConfirm(true);
        let task_name = '';
        let archive_text = '';
        let removeTaskText = 'Вы действительно хотите убрать задачу ';
        let addTaskText = 'Вы действительно хотите добавить задачу ';
        if (typeof params.index === 'number' && params.status && !params.task) {
            task_name = data[params.status][params.index].name;
            removeTaskText += `"${task_name}" из архива`;
            addTaskText += `"${task_name}" в архив`;
            archive_text = data[params.status][params.index].archived_at ? removeTaskText : addTaskText;
        } else if (params.index === null && params.task) {
            task_name = params.task.task.name;
            removeTaskText += `"${task_name}" из архива`;
            addTaskText += `"${task_name}" в архив`;
            archive_text = params.task.task.archived_at ? removeTaskText : addTaskText;
        }
        setTextOnConfirm(archive_text);
        setParamsForConfirm({ index: params.index, status: params.status, task: params.task });
    };
    const onNoArchiveConfirm = async () => {
        setOpenArchiveConfirm(false);
        setTextOnConfirm('');
    };
    const onYesArchiveConfirm = async (params: IOnYesArchiveConfirm) => {
        let archived = false;
        let task_id = 0;
        let task_index = 0;
        let status: keyof ITaskData = 'Новое';
        if (typeof params.index === 'number' && params.status && !params.task) {
            task_index = params.index;
            archived = !data[params.status][task_index].archived_at;
            data[params.status][task_index].archived_at = archived;
            task_id = data[params.status][task_index].id;
            status = params.status;
        } else if (params.index === null && params.task) {
            params.task.task.archived_at = !params.task.task.archived_at;
            task_index = findTaskIndex(params.task.task.id, params.task.status);
            archived = params.task.task.archived_at;
            task_id = params.task.task.id;
            status = params.task.status;
        }
        const response = await editTaskHandler([
            { key: 'id', value: task_id },
            { key: 'archived_at', value: archived },
        ]);
        if (response.status === 200) {
            if (params.task) {
                changeEditTask(params.task);
            }
            changeDataTasks(task_index, status, [
                { key: 'archived_at', value: archived },
            ]);
            onNoArchiveConfirm();
        }
    };

    const onAddCheckboxesChecklist = async (checklist_id: number, text: string, checklist_index: number, task: IEditTask) => {
        const response = await addCheckboxesChecklistAPI(task.task.id, checklist_id, text);
        if (response.status === 200) {
            task.task.checklists[checklist_index].input = '';
            const response = await getTaskAPI(task.task.id);
            if (response.status === 200) {
                const { data } = response.data;
                task.task.checklists[checklist_index].checkboxes = data.task.checklists[checklist_index].checkboxes;
                const checkboxIndex = task.task.checklists[checklist_index].checkboxes.findIndex((val) => val.name === text);
                task.task.checklists[checklist_index].checkboxes[checkboxIndex].complete = false;

                changeEditTask(task);
                const task_index = findTaskIndex(task.task.id, task.status);

                changeDataTasks(task_index, task.status, [
                    { key: 'checklists', value: task.task.checklists },
                ]);
            }
        }
    };
    const updateCheckbox = async (task: ITask, checklist_index: number, checkbox_index: number) => {
        const checklist_id = task.checklists[checklist_index].id;
        const checkbox_id = task.checklists[checklist_index].checkboxes[checkbox_index].id;
        const numComplete = task.checklists[checklist_index].checkboxes[checkbox_index].complete ? 1 : 0;
        const checkboxName = task.checklists[checklist_index].checkboxes[checkbox_index].name;
        const task_id = task.id;
        return updateCheckboxChecklistAPI(task_id, checklist_id, checkbox_id, numComplete, checkboxName);
    };
    const updateCheckboxHandler = async (task: ITask, status: keyof ITaskData, checklist_index: number, checkbox_index: number, editTask?: IEditTask) => {
        const task_index = findTaskIndex(task.id, status);

        task.checklists[checklist_index].checkboxes[checkbox_index].complete = !task.checklists[checklist_index].checkboxes[checkbox_index].complete;
        changeDataTasks(task_index, status, undefined, task);

        if (editTask) {
            const editedTask = editTask;
            editedTask.task = task;
            changeEditTask(editedTask);
        }
        const response = await updateCheckbox(task, checklist_index, checkbox_index);
        if (response.status === 200) {
            console.log('response');
        }
    };

    const onUpdateCheckboxComplete = async (task: ITask, status: keyof ITaskData, checklist_index: number, checkbox_index: number) => {
        updateCheckboxHandler(task, status, checklist_index, checkbox_index);
    };
    const onUpdateCheckbox = async (task: IEditTask, checklist_index: number, checkbox_index: number) => {
        updateCheckboxHandler(task.task, task.status, checklist_index, checkbox_index, task);
    };

    const onAddCheckList = async (checklist_name: string, task: IEditTask) => {
        const response = await addChecklistAPI(checklist_name, task.task.id);
        if (response.status === 200) {
            const task_data = await getTask(task.task.id);
            if (!task_data) {
                return;
            }
            task_data.edit = true;
            task = task_data;
            console.log('task_data', task_data);
            changeEditTask(task);
            const task_index = findTaskIndex(task_data.task.id, task_data.status);
            changeDataTasks(task_index, task.status, undefined, data.task);
        }
    };
    const onDeleteChecklist = async (task: IEditTask, checklist_index: number) => {
        const response = await deleteChecklistAPI(task.task.id, task.task.checklists[checklist_index].id);
        if (response.status === 200) {
            const newChecklists = task.task.checklists;
            newChecklists.splice(checklist_index, 1);
            task.task.checklists = newChecklists;
            changeEditTask(task);
        }
    };
    const onEnableEdidChecklistName = async (task: IEditTask, checklist_index: number) => {
        task.task.checklists[checklist_index].edit = !task.task.checklists[checklist_index].edit;
        changeEditTask(task);
        if (!task.task.checklists[checklist_index].edit) {
            const response = await updateChecklistAPI(task.task.id, task.task.checklists[checklist_index].id, task.task.checklists[checklist_index].name);
        }
    };

    const onChangeChecklistInput = (text: string, checklist_index: number, task: IEditTask) => {
        task.task.checklists[checklist_index].input = text;
        changeEditTask(task);
    };
    const onChangeCheckboxName = (text: string, task: IEditTask, checklist_index: number, checkbox_index: number) => {
        task.task.checklists[checklist_index].checkboxes[checkbox_index].name = text;
        changeEditTask(task);
    };
    const onEnableEdidCheckboxName = async (task: IEditTask, checklist_index: number, checkbox_index: number) => {
        if (task.task.checklists[checklist_index].checkboxes[checkbox_index].editName === true) {
            const response = await updateCheckbox(task.task, checklist_index, checkbox_index);
        }
        task.task.checklists[checklist_index].checkboxes[checkbox_index].editName = !task.task.checklists[checklist_index].checkboxes[checkbox_index].editName;
        changeEditTask(task);
    };
    const onEditChecklistName = (task: IEditTask, checklist_index: number, checklist_name: string) => {
        task.task.checklists[checklist_index].name = checklist_name;
        changeEditTask(task);
    };
    const onDeleteCheckbox = async (task: IEditTask, checklist_index: number, checkbox_index: number) => {
        const checkbox_id = task.task.checklists[checklist_index].checkboxes[checkbox_index].id;
        const response = await deleteCheckboxAPI(task.task.id, task.task.checklists[checklist_index].id, checkbox_id);
        if (response.status === 200) {
            task.task.checklists[checklist_index].checkboxes.splice(checkbox_index, 1);
            changeEditTask(task);
        }
    };
    const onDeleteTask = async (params: IOnYesArchiveConfirm) => {
        setOpenDeleteConfirm(true);
        if (!params.index && params.task) {
            setTextOnConfirm(`Вы действительно хотите удалить задачу: ${params.task.task.name}`);
        } else if (typeof params.index === 'number' && params.status && !params.task) {
            setTextOnConfirm(`Вы действительно хотите удалить задачу: ${data[params.status][params.index].name}`);
        }
        setParamsForConfirm(params);
    };
    const deleteTaskByIndex = (task_index: number, status: keyof ITaskData) => {
        const updatedTasks = { ...data };
        updatedTasks[status] = updatedTasks[status].filter((task: any, index: number) => index !== task_index);
        setData(updatedTasks);
        getTabsCount();
    };
    const onNoDeleteTask = async () => {
        setOpenDeleteConfirm(false);
        setTextOnConfirm('');
    };
    const onYesDeleteTask = async (params: IOnYesArchiveConfirm) => {
        let response;
        let task_index = 0;
        let status: keyof ITaskData = '';
        if (!params.index && params.task) {
            response = await deleteTaskAPI(params.task.task.id);
        } else if (typeof params.index === 'number' && params.status && !params.task) {
            response = await deleteTaskAPI(data[params.status][params.index].id);
        }
        if (response.status === 200) {
            if (!params.index && params.task) {
                setOpenEditHandler(params.task.task, false, params.task.status);
                status = params.task.status;

                task_index = findTaskIndex(params.task.task.id, params.task.status);
            } else if (typeof params.index === 'number' && params.status && !params.task) {
                setOpenEdit(undefined);
                task_index = params.index;
                status = params.status;
            }

            deleteTaskByIndex(task_index, status);
            onNoDeleteTask();
        }
    };

    const onOpenEdit = (task: IEditTask) => {
        if (String(task.task.name).length > 0) {
            task.edit = !task.edit;
            changeEditTask(task);
        }
    };

    useEffect(() => {
        getTasks();
    }, []);
    useEffect(() => {
        getTabsCount();
    }, [data]);
    useEffect(() => {
        onFilterTasks(searchInput);
    }, [searchInput]);
    interface ICardInfo {
        id: number;
        status: keyof ITaskData;
    }
    const getTaskStateIdByStatus = (status: keyof ITaskData) => {
        let task_state_id = 0;
        TASK_COLUMNS.forEach((column) => {
            if (column.status === status) {
                task_state_id = column.task_state_id;
            }
        });
        return task_state_id;
    };

    const onDrop = async (cardInfo: ICardInfo, newStatus: keyof ITaskData, targetCardId: string) => {
        const { id, status: oldStatus } = cardInfo;

        const dropCard = data[oldStatus].find((el) => el.id === id);
        const targetCard = targetCardId !== ''
            ? data[newStatus].find((el) => el.id === targetCardId)
            : null;

        const newListOrderValueMax = data[newStatus]
            .map((item) => item.order)
            .reduce((maxValue, a) => Math.max(maxValue, a), 0);

        const task_state_id = getTaskStateIdByStatus(newStatus);
        const task_index = findTaskIndex(id, oldStatus);

        changeDataTasks(task_index, oldStatus, [
            { key: 'task_state_id', value: task_state_id },
        ]);

        // CASE 1: If same list, work only this if block then return;
        if (oldStatus === newStatus) {
            const temp = data[oldStatus]
                .map((item) => {
                    if (dropCard) {
                        if (item.id === dropCard.id) {
                            return {
                                ...dropCard,
                                order: targetCard
                                    ? targetCard.order - 1
                                    : newListOrderValueMax + 1,
                            };
                        }
                        return item;
                    }
                    return null;
                })
                .sort((a: any, b: any) => a.order - b.order)
                .map((item: any, i: number) => ({ ...item, order: i + 1 }));
            setData((d: any) => ({ ...d, [oldStatus]: temp }));

            return;
        }
        // CASE 1 ENDS HERE

        // CASE 2: Drag across multiple lists
        let newCol: ITaskColumn = { status: '', task_state_id: 0 };
        TASK_COLUMNS.forEach((column) => {
            if (column.status === newStatus) {
                newCol = column;
            }
        });
        moveTask(dropCard, oldStatus, newCol);

        // CASE 2 ENDS HERE

        await editTaskHandler([
            { key: 'id', value: dropCard.id },
            { key: 'task_state_id', value: task_state_id },
        ]);
    };
    return (
        <>
            {openDeleteConfirm && (
                <SureModal
                    text={textOnConfirm}
                    onYes={onYesDeleteTask}
                    onNo={onNoDeleteTask}
                    paramsForFunc={paramsForConfirm}
                />
            )}
            {openArchiveConfirm && (
                <SureModal
                    text={textOnConfirm}
                    onYes={onYesArchiveConfirm}
                    onNo={onNoArchiveConfirm}
                    paramsForFunc={paramsForConfirm}
                />
            )}
            <div className="user_tasks_page">

                {openEdit && (
                    <TaskEdit
                        task={openEdit}
                        closeEdit={onCloseEdit}
                        onSave={onSaveEdit}
                        onEditComment={onEditComment}
                        onDeleteComment={onDeleteComment}
                        onAddComment={onAddComment}
                        onChangeComment={onChangeComment}
                        onLock={onLock}
                        onEditDesc={onEditDesc}
                        onChangeDeadline={onChangeDeadline}
                        onSetEditValue={onSetEditValue}
                        onEnableEditTitle={onEnableEditTitle}
                        onChangeFiles={onChangeFiles}
                        onDeleteFile={onDeleteFile}
                        onAddCheckboxesChecklist={onAddCheckboxesChecklist}
                        onChangeChecklistInput={onChangeChecklistInput}
                        onUpdateCheckbox={onUpdateCheckbox}
                        onAddCheckList={onAddCheckList}
                        onChangeCheckboxName={onChangeCheckboxName}
                        onEnableEdidCheckboxName={onEnableEdidCheckboxName}
                        onDeleteCheckbox={onDeleteCheckbox}
                        onAddTaskToArchive={onAddTaskToArchive}
                        onDeleteTask={onDeleteTask}
                        onDeleteChecklist={onDeleteChecklist}
                        onEnableEdidChecklistName={onEnableEdidChecklistName}
                        onEditChecklistName={onEditChecklistName}
                        onTaskToComplete={onTaskToComplete}
                        onOpenEdit={onOpenEdit}
                        errors={errors}
                    />
                )}
                <div className="user_tasks_page__tasks">
                    {TASK_COLUMNS.map((taskColumn) => data && (
                        <TaskBoard
                            data={data[taskColumn.status]}
                            status={taskColumn.status}
                            onDrop={onDrop}
                            onAddTask={onAddTask}
                            onEditTask={onEditTask}
                            onFilterTasks={onFilterTasks}
                            onAddTaskToArchive={onAddTaskToArchive}
                            onDeleteTask={onDeleteTask}
                            onTaskToComplete={onTaskToComplete}
                            onUpdateCheckboxComplete={onUpdateCheckboxComplete}
                            currentTab={currentTab}
                            task_state_id={taskColumn.task_state_id}
                        />
                    ))}

                </div>
            </div>
        </>

    );
};

export default TasksTemplate;
