import '../../assets/styles/auth/input_label_field.scss';

interface ICustomErrors {
    valid: boolean, error: string

}
interface IProps {
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    onBlur: React.FocusEventHandler<HTMLInputElement>,
    type: string
    htmlFor: string
    errors: any
    label: string
    value: string,
    isValid?: boolean,
    className?: string,
    customErrors?: ICustomErrors[],
}
const InputLabelField = ({
    onChange, onBlur, type, htmlFor, errors, label, value, isValid, className, customErrors,
}: IProps) => (
    <>
        <label className="auth_label" htmlFor={htmlFor}>{label}</label>
        <input
            type={type}
            className="auth_input"
            id={htmlFor}
            name={htmlFor}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            placeholder={label}
        />
        {errors && (errors[htmlFor] && (value.length === 0)) && (
            <div className="auth_error">
                Поле
                {label}
                {' '}
                обязательно для заполнения.
            </div>
        )}
        {errors && (errors[htmlFor] && (value.length > 0)) && (
            <div className="auth_error">{errors[htmlFor]}</div>
        )}
        {isValid && (
            <div className="error">
                Поле
                {label}
                {' '}
                не верно указано
            </div>
        )}
        {customErrors?.map((error: ICustomErrors) => (
            error.valid && (
                <div className="error">{error.error}</div>

            )

        ))}
    </>

);

export default InputLabelField;
