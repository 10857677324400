import '../../../../assets/styles/admin/components/admin_edit_field.scss';

interface IProps {
    label: string
    htmlFor: string,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    value: string,
    type?: string,
    className?: string,
    errors?: any,
}
const AdminEditField = ({
    label, htmlFor, onChange, value, type, className, errors,
}: IProps) => (
    <div className={`admin_edit_field ${className}`}>
        <label htmlFor={htmlFor}>{label}</label>
        <input
            type={type || 'text'}
            id={htmlFor}
            name={htmlFor}
            onChange={onChange}
            value={value}
            placeholder=""
        />
        {errors && (errors[htmlFor] && (value.length === 0)) && (
            <div className="admin_edit_field__error">
                Поле
                {label}
                {' '}
                обязательно для заполнения.
            </div>
        )}
    </div>

);

export default AdminEditField;
