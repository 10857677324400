import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SelectArrowSvg } from '../assets/svgs/SelectArrowSvg';
import Layout from '../components/Layout';
import StarterCard from '../components/Starter/StarterCard';
import StarterPresentation from '../components/Starter/StarterPresentation';
import StarterSlider from '../components/Starter/StarterSlider';
import { HELLO_ROUTE } from '../utils/consts';
import { getCookie } from '../utils/cookies';

const Starter = () => {
    require('../assets/styles/starter/starter_page.scss');
    const navigate = useNavigate();
    const [usersBirthday, setUsers] = useState([
        { avatar: 'https://cms.transmashpribor.ru/uploads/img/avatars/543a1e078321ce3a521da359d78055bf.jpg', birthday: '03' },
        { avatar: 'https://cms.transmashpribor.ru/uploads/img/avatars/543a1e078321ce3a521da359d78055bf.jpg', birthday: '03' },
        { avatar: 'https://cms.transmashpribor.ru/uploads/img/avatars/543a1e078321ce3a521da359d78055bf.jpg', birthday: '03' },
        { avatar: 'https://cms.transmashpribor.ru/uploads/img/avatars/543a1e078321ce3a521da359d78055bf.jpg', birthday: '03' },
        { avatar: 'https://cms.transmashpribor.ru/uploads/img/avatars/543a1e078321ce3a521da359d78055bf.jpg', birthday: '03' },
        { avatar: 'https://cms.transmashpribor.ru/uploads/img/avatars/543a1e078321ce3a521da359d78055bf.jpg', birthday: '03' },
        { avatar: 'https://cms.transmashpribor.ru/uploads/img/avatars/543a1e078321ce3a521da359d78055bf.jpg', birthday: '03' },
        { avatar: 'https://cms.transmashpribor.ru/uploads/img/avatars/543a1e078321ce3a521da359d78055bf.jpg', birthday: '03' },
        { avatar: 'https://cms.transmashpribor.ru/uploads/img/avatars/543a1e078321ce3a521da359d78055bf.jpg', birthday: '03' },
        { avatar: 'https://cms.transmashpribor.ru/uploads/img/avatars/543a1e078321ce3a521da359d78055bf.jpg', birthday: '03' },
        { avatar: 'https://cms.transmashpribor.ru/uploads/img/avatars/543a1e078321ce3a521da359d78055bf.jpg', birthday: '03' },
        { avatar: 'https://cms.transmashpribor.ru/uploads/img/avatars/543a1e078321ce3a521da359d78055bf.jpg', birthday: '03' },
        { avatar: 'https://cms.transmashpribor.ru/uploads/img/avatars/543a1e078321ce3a521da359d78055bf.jpg', birthday: '03' },
        { avatar: 'https://cms.transmashpribor.ru/uploads/img/avatars/543a1e078321ce3a521da359d78055bf.jpg', birthday: '03' },
    ]);

    const [usersVacation, setUsersVacation] = useState({
        rest: [
            'Тагильцева Ольга Николаевна',
            'Наронов Алексей Сергеевич',
            'Захарова Алена Михайловна',
            'Катаева Нина Валерьевна',
            'Пантелеев Николай Николаевич',
            'Коробов Сергей Владимирович',
            'Таушканов Даниил Александрович',
            'Роде Олег Геннадьевич',
            'Русских Татьяна Васильевна',
            'Гатиятуллина Екатерина Владимировна',
        ],
        sick: [
            'Белоусов Сергей Александрович',
            'Третьяков Александр Владимирович',
            'Семенова Алена Сергеевна',
        ],
    });
    const [usersBetters, setUsersBetters] = useState([
        { avatar: 'https://cms.transmashpribor.ru/uploads/img/avatars/543a1e078321ce3a521da359d78055bf.jpg', birthday: '03' },
    ]);
    useEffect(() => {
        const token = getCookie('access_token');
        const userId = getCookie('userId');
        if (token && userId) {
            console.log('fdf');
        } else {
            navigate(HELLO_ROUTE);
        }
    }, []);
    return (
        <Layout>
            <div className="starter_container">
                <div className="starter_content">
                    <div className="starter_left">
                        <StarterCard>
                            <div className="card_header">
                                <div className="left">
                                    <div className="title">Дни рождения</div>
                                </div>
                                <div className="right">
                                    <div className="months">
                                        <span className="arrow_left">
                                            <SelectArrowSvg />
                                        </span>
                                        <span className="month">Июль</span>
                                        <span className="arrow_right">
                                            <SelectArrowSvg />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="card_body">
                                <ul className="users_birthday">
                                    {usersBirthday.map((user: any) => (
                                        <li className="user" style={{ backgroundImage: `url(${user.avatar})` }}>
                                            <span className="date">{user.birthday}</span>
                                        </li>
                                    ))}
                                </ul>

                            </div>
                        </StarterCard>

                        <StarterCard>
                            <div className="card_header">
                                <div className="left">
                                    <div className="title"> Отпуск и больничный </div>
                                </div>
                            </div>

                            <div className="card_body">
                                <div className="vacations">
                                    <span className="title">В отпуске:</span>
                                    <div className="vacation">
                                        {usersVacation.rest.map((user) => (
                                            <span className="item">{user}</span>
                                        ))}
                                    </div>
                                    <span className="title">Больничный:</span>
                                    <div className="vacation">
                                        {usersVacation.sick.map((user) => (
                                            <span className="item">{user}</span>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </StarterCard>

                        <StarterCard>
                            <div className="card_header">
                                <div className="left">
                                    <div className="title">Доска почета</div>
                                </div>
                                <div className="right">
                                    <div className="months">
                                        <span className="arrow_left">
                                            <SelectArrowSvg />
                                        </span>
                                        <span className="month">Июль</span>
                                        <span className="arrow_right">
                                            <SelectArrowSvg />
                                        </span>
                                    </div>
                                    <div className="months">
                                        <span className="arrow_left">
                                            <SelectArrowSvg />
                                        </span>
                                        <span className="month">2024</span>
                                        <span className="arrow_right">
                                            <SelectArrowSvg />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="card_body">
                                <div className="betters">
                                    <p className="title">Лучшие в этом месяце:</p>
                                    <ul className="rewards">
                                        {usersBetters.map((user: any) => (
                                            <li className="user" style={{ backgroundImage: `url(${user.avatar})` }} />
                                        ))}
                                    </ul>
                                    <p className="title">Стремящиеся к лучшим:</p>
                                    <ul className="rewards">
                                        <p className="user_empty">Пользователи отсутствуют</p>
                                    </ul>
                                </div>
                            </div>
                        </StarterCard>

                        <StarterCard>
                            <div className="card_header">
                                <div className="left">
                                    <div className="title">Документооборот</div>
                                </div>
                            </div>

                            <div className="card_body">
                                <div className="documents">
                                    <div className="agreement_item">
                                        <span className="title">Отправленные на согласование:</span>
                                        <span className="desc">0</span>
                                    </div>
                                    <div className="agreement_item">
                                        <span className="title">В процессе согласования:</span>
                                        <span className="desc">0</span>
                                    </div>
                                    <div className="agreement_item">
                                        <span className="title">Согласованные:</span>
                                        <span className="desc">0</span>
                                    </div>
                                    <div className="agreement_item">
                                        <span className="title">Отмененные:</span>
                                        <span className="desc">0</span>
                                    </div>
                                </div>
                            </div>
                        </StarterCard>

                        <StarterCard>
                            <div className="card_header">
                                <div className="left">
                                    <div className="title">Вопрос директору</div>
                                </div>
                            </div>

                            <div className="card_body">
                                <div className="documents">
                                    <div className="agreement_item">
                                        <span className="title">Обращения отсутствуют </span>
                                    </div>
                                </div>
                            </div>
                        </StarterCard>
                    </div>
                    <div className="starter_right">
                        <StarterSlider />
                        <br className="margin" />
                        <StarterPresentation />
                    </div>
                </div>

            </div>
        </Layout>

    );
};

export default Starter;
