import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HELLO_ROUTE, STARTER_ROUTE } from '../utils/consts';
import { getCookie } from '../utils/cookies';

const HelloMiddleware = () => {
    require('../assets/styles/hello.scss');
    const navigate = useNavigate();
    useEffect(() => {
        const path = window.location.pathname;
        const token = getCookie('access_token');
        if (path === '/' && token) {
            navigate(STARTER_ROUTE);
        } else {
            navigate(HELLO_ROUTE);
        }
    }, []);
    return (
        null

    );
};

export default HelloMiddleware;
