import { useState } from 'react';
import CloseCircleSvg from '../../../assets/svgs/CloseCircleSvg';
import PencilSvg from '../../../assets/svgs/PencilSvg';
import PlusSvg from '../../../assets/svgs/PlusSvg';
import SuccessSvg from '../../../assets/svgs/SuccessSvg';
import InputFiled from '../../html/InputFiled';
import SureModal from '../../Modals/SureModal';
import AdminTableLayout from './AdminTableLayout';

const AdminGroupTable = () => {
    require('../../../assets/styles/admin/admin_group_table.scss');
    const [groups, setGroups] = useState<Array<any>>([
        { name: 'Уволенные', status: true, edit: false },
    ]);
    const editGroup = (index: number, key: string, value?: string) => {
        const newState = groups.map((state: any, i: number) => {
            if (index === i) {
                return { ...groups[index], [key]: value || !groups[index][key] };
            }

            return state;
        });
        setGroups(newState);
    };
    const addGroup = () => {
        if (groups[groups.length - 1].name !== '') {
            setGroups((prev: any) => [...prev, { name: '', status: true, edit: false }]);
        }
    };
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalText, setModalText] = useState<string>('');
    const onYes = () => {
        setShowModal(false);
    };
    const onNo = () => {
        setShowModal(false);
    };
    const onDelete = (name: string) => {
        setModalText(`Вы действительно хотите удалить группу ${name}?`);
        setShowModal(true);
    };

    return (
        <>
            {showModal && (
                <SureModal text={modalText} onYes={onYes} onNo={onNo} />
            )}
            <AdminTableLayout className="group">
                <tr>
                    <th>#</th>
                    <th>Название группы</th>
                    <th>Статус</th>
                    <th>Функции</th>
                </tr>
                <tbody>
                    <tr>
                        <td />
                        <td />
                        <td />
                        <td>
                            <div className="plus">
                                <span onClick={() => addGroup()}><PlusSvg /></span>
                            </div>

                        </td>
                    </tr>
                    {groups.map((group: any, index: number) => (
                        <tr>
                            <td>{index}</td>
                            <td>
                                <span style={{ display: group.edit ? 'none' : 'block' }}>{group.name}</span>
                                <span style={{ display: group.edit ? 'block' : 'none' }}>
                                    <InputFiled
                                        id="group"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { editGroup(index, 'name', e.target.value); }}
                                        value={group.name}
                                        className="group_input"
                                    />
                                </span>
                            </td>
                            <td>
                                <div className="active">{group.status ? 'Актив' : 'Не актив'}</div>
                            </td>
                            <td>
                                <div className="icons">
                                    <div className="btn edit" onClick={() => editGroup(index, 'edit')}>
                                        <span style={{ display: group.edit ? 'none' : 'block' }}><PencilSvg /></span>
                                        <span style={{ display: group.edit ? 'block' : 'none' }}><SuccessSvg /></span>
                                    </div>
                                    <div className="btn delete" onClick={() => onDelete(group.name)}>
                                        <CloseCircleSvg />
                                    </div>
                                </div>

                            </td>
                        </tr>
                    ))}

                </tbody>
            </AdminTableLayout>
        </>
    );
};

export default AdminGroupTable;
