import { IActions } from '../../assets/interfaces/interfaces';
import CloseCrossSvg from '../../assets/svgs/CloseCrossSvg';
import ExclamationMarkSvg from '../../assets/svgs/ExclamationMarkSvg';
import PaperAirplaneSvg from '../../assets/svgs/PaperAirplaneSvg';
import SuccessSvg from '../../assets/svgs/SuccessSvg';
import s from './Actions.module.scss';

interface IProps {
    actions: IActions[],
    onActive: Function,
}

const getSvgComponent = (svgName: string) => {
    switch (svgName) {
    case 'ExclamationMarkSvg':
        return <ExclamationMarkSvg />;
    case 'SuccessSvg':
        return <SuccessSvg />;
    case 'CloseCrossSvg':
        return <CloseCrossSvg />;
    case 'PaperAirplaneSvg':
        return <PaperAirplaneSvg />;
    default:
        return null;
    }
};
const getBackgroundColor = (val: {active: boolean, active_bg: string}) => {
    if (val.active && val.active_bg) {
        return val.active_bg;
    } if (val.active) {
        return '#f59e0b';
    }
    return '';
};
const ActionsButtons = ({ actions, onActive }: IProps) => (
    <div className={s.wrapper}>

        <div className={s.actions}>
            {actions.map((val, index) => (
                <div
                    key={val.text}
                    style={{
                        backgroundColor: getBackgroundColor(val),
                    }}
                    className={`${s.action} ${val.active ? s.active : ''}`}
                    onClick={() => onActive(index)}
                >
                    <div className={s.icon}>
                        {getSvgComponent(val.svg)}
                    </div>
                    <div className={s.text}>{val.text}</div>
                </div>
            ))}
        </div>
    </div>
);

export default ActionsButtons;
