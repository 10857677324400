import '../../../../../../assets/styles/modules/components/doc/table/table_td_status.scss';
import { docGetTextStatus, getStatusClass } from '../../../config/funcs';

interface ITabsProps {
    status: number,
}

const DocTableTdStatus = ({
    status,
}: ITabsProps) => (
    <span
        className={`doc_table_td_status ${getStatusClass(status)}`}
    >
        {docGetTextStatus(status)}
    </span>
);
export default DocTableTdStatus;
