import { useEffect, useState } from 'react';

import { IMultySelectSearch, ISelectModalLink } from '../../../../../assets/interfaces/interfaces';
import { IDocTableHeader, ITableTemplate } from '../../../../../assets/interfaces/modules/doc/doc_interface';
import { IDocTableSignData } from '../../../../../assets/interfaces/modules/doc/tab/doc_sign_interface';
import '../../../../../assets/styles/modules/components/doc/doc_layout_tab.scss';
import EditModal from '../../../../../components/Modals/EditModal';
import SureModal from '../../../../../components/Modals/SureModal';
import SelectLabelField from '../../../../../components/html/SelectLabelField';
import { docCheckCreateIsEmpty } from '../../config/funcs';
import DocHeader from '../elements/DocHeader';
import RowModule from '../modals/RowModals';
import TableDocHandbookLayout from '../table/TableDocHandbookLayout';

type ITableData = {
    data: any[];
    currentPage: number;
    todosPerPage: number;
}
type IProps = {
    tableHeaders: IDocTableHeader[];
    tableData: ITableData;
    createRow: IDocTableSignData | any;
    title: string;
    selectModalProps?: {title: string, values: IMultySelectSearch[], link: ISelectModalLink, multiple: boolean};
    tableTemplate: keyof ITableTemplate;
    onChangeTablePage: Function;
    onCloseTab: () => void;
    onAddRow: (data: any) => void;
    onSelectModalOpen?: (index: number) => void;
    onDeleteRow: (newData: any, id: number) => void;
    onChangeCreateRow: (key: any, value: any) => void;
    onChangeDataRow: (index: number, key: any, value: any) => void;
    onChangeFilesRow?: (index: number, files: FileList | null) => void;
    onSelectModalValue?: (val: IMultySelectSearch[], index: number) => void;
    clearSelectModalValues?: () => void;
    getDeleteName?: (index: number) => string;

}
const DocHandbookLayout = ({
    tableHeaders, tableData, getDeleteName, tableTemplate, selectModalProps, onSelectModalOpen, clearSelectModalValues, onDeleteRow, onSelectModalValue, onChangeTablePage, title, onCloseTab, onAddRow, onChangeCreateRow, onChangeDataRow, createRow, onChangeFilesRow,
}: IProps) => {
    const [data, setData] = useState<any[]>([]);
    const [pageNumbers, setPageNumbers] = useState<Array<number>>([]);
    const [inputTabelSearch, setInputTabelSearch] = useState<string>('');
    const [showSelectModal, setShowSelectModal] = useState<{show: boolean, td_index:number}>({ show: false, td_index: -1 });

    const [sureDeleteModal, setSureDeleteModal] = useState<{active: boolean, text: string, index: number}>({ active: false, text: '', index: -1 });
    const [spaceZoneClicked, setSpaceZoneClicked] = useState<boolean>(false);

    function getRowIndex(index: number) {
        if (tableData.currentPage === 2) {
            return tableData.todosPerPage + index;
        }
        if (tableData.currentPage > 2) {
            return tableData.todosPerPage * (tableData.currentPage - 1) + index;
        }
        return index;
    }
    const onChangeFilesRowHandler = (index: number, files: FileList | null) => {
        const tableIndex = getRowIndex(index);
        if (onChangeFilesRow)onChangeFilesRow(tableIndex, files);
    };
    function getTableSlicedData(tableData: ITableData) {
        const indexOfLastTodo = tableData.currentPage * tableData.todosPerPage;
        const indexOfFirstTodo = indexOfLastTodo - tableData.todosPerPage;
        return tableData.data.slice(indexOfFirstTodo, indexOfLastTodo);
    }
    function changeTableData(newData: ITableData) {
        setData(getTableSlicedData(newData));
    }

    function onChangeCurrentPage(signData: ITableData) {
        if (signData) {
            changeTableData(signData);

            const pages = [];
            for (let i = 1; i <= Math.ceil(signData.data.length / signData.todosPerPage); i++) {
                pages.push(i);
            }
            setPageNumbers(pages);
        }
    }
    function addRowTable(isEmpty: boolean) {
        if (!isEmpty) {
            const newData = { ...tableData };
            newData.data.unshift(createRow);
            onAddRow(newData);
        }
    }
    const onAddRowHandler = async () => {
        const isEmpty = docCheckCreateIsEmpty(tableTemplate, createRow);
        addRowTable(isEmpty);
    };

    const onChangeField = (index:number, text: string, key: string) => {
        if (index === -1) {
            onChangeCreateRow(key, text);
        } else {
            onChangeDataRow(getRowIndex(index), key, text);
        }
    };

    const onNoConfirmDelete = () => {
        setSureDeleteModal({ active: false, text: '', index: -1 });
    };
    const onConfirmYesDelete = () => {
        const newData = { ...tableData };
        const { index } = sureDeleteModal;
        const { id } = newData.data[index];
        newData.data.splice(index, 1);
        if (newData.currentPage === 2) {
            if (newData.data.length <= newData.todosPerPage) {
                newData.currentPage = 1;
            }
        } else if (newData.currentPage > 2) {
            if (newData.data.length <= newData.todosPerPage * (newData.currentPage - 1)) {
                newData.currentPage -= 1;
            }
        }

        onDeleteRow(newData, id);
        onNoConfirmDelete();
    };
    const onDeleteRowHandler = (index: number) => {
        if (getDeleteName) {
            const tableIndex = getRowIndex(index);
            const deleteName = getDeleteName(tableIndex);
            const text = `Действительно хотите удалить "${deleteName}"?`;
            setSureDeleteModal({ active: true, text, index: tableIndex });
        }
    };
    const onChangeinputSearch = (text:string) => {
        setInputTabelSearch(text);
    };
    const onCloseSelectModalHandler = () => {
        if (clearSelectModalValues) clearSelectModalValues();
        setShowSelectModal({ show: false, td_index: -1 });
    };
    const onSaveSelectModalHandler = (selectedUsers: IMultySelectSearch[]) => {
        if (clearSelectModalValues) clearSelectModalValues();
    };
    const onSelectModalOpenHandler = (index: number) => {
        const tableIndex = getRowIndex(index);
        if (onSelectModalOpen) onSelectModalOpen(tableIndex);
        setShowSelectModal({ show: true, td_index: tableIndex });
    };

    useEffect(() => {
        onChangeCurrentPage(tableData);
    }, [tableData]);
    return (

        <>
            {sureDeleteModal.active ? (
                <SureModal
                    text={sureDeleteModal.text}
                    textConfirm="Удалить"
                    onYes={onConfirmYesDelete}
                    onNo={onNoConfirmDelete}
                />
            ) : null}
            {(onSelectModalValue && selectModalProps) ? (
                <EditModal
                    show={showSelectModal.show}
                    onCloseModal={() => onCloseSelectModalHandler()}
                    onClickSave={() => onSaveSelectModalHandler(selectModalProps.values)}
                    confirmBtnHide
                >
                    <RowModule title={selectModalProps.title} link={selectModalProps.link}>
                        <SelectLabelField
                            id="select_users"
                            placeholder="Поиск..."
                            values={selectModalProps.values}
                            onChangeValues={(val: IMultySelectSearch[]) => onSelectModalValue(val, showSelectModal.td_index)}
                            spaceZoneClicked={spaceZoneClicked}
                            multiple={selectModalProps.multiple}
                        />
                    </RowModule>
                </EditModal>
            ) : null}
            <DocHeader
                title={title}
                onInputSearchChange={onChangeinputSearch}
                onCloseTab={onCloseTab}
                showDatePicker={false}
            />
            <div className="docmodule_body">
                <TableDocHandbookLayout
                    headers={tableHeaders}
                    tableTemplate={tableTemplate}
                    onChangePage={onChangeTablePage}
                    currentPage={tableData.currentPage}
                    pageNumbers={pageNumbers}
                    data={data}
                    inputSearch={inputTabelSearch}
                    createRow={createRow}
                    onSelectClick={onSelectModalOpenHandler}
                    onChangeField={onChangeField}
                    onAddRow={onAddRowHandler}
                    onDeleteRow={onDeleteRowHandler}
                    onChangeFiles={onChangeFilesRowHandler}
                />
            </div>
        </>
    );
};

export default DocHandbookLayout;
