import s from './SuccessModal.module.scss';

const SuccessModal = () => (
    <section>
        <span className={s.overlay} />
        <div className={s.modal_box}>
            <h2>Успешно</h2>
            <h3>Запрос на регистрацию отправлен</h3>

            {/* <div className={s.buttons}>
                        <button className={`${s.button}`}>Хорошо</button>
                    </div> */}
        </div>
    </section>

);

export default SuccessModal;
