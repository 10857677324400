import { useState } from 'react';
import { ITableTemplate } from '../../../../../../../assets/interfaces/modules/doc/doc_interface';
import { IDocTableFieldData } from '../../../../../../../assets/interfaces/modules/doc/tab/doc_field_interface';
import PlusLigthSvg from '../../../../../../../assets/svgs/PlusLigthSvg';
import TrashSvg from '../../../../../../../assets/svgs/TrashSvg';
import { docCheckCreateIsEmpty } from '../../../../config/funcs';
import DocTableIcon from '../../components/DocTableIcon';
import DocTableTd from '../../td/DocTableTd';

type IProps = {
    index: number;
    create?: boolean;
    createRow?: any;
    tableTemplate: keyof ITableTemplate;
    onAddRow?: () => void;
    onDeleteRow?: (index: number) => void;
    renderTitle: (key: string) => React.ReactNode
}
const DocTabelTrFiledTemplate = ({
    index,
    create,
    createRow,
    tableTemplate,
    onAddRow,
    onDeleteRow,
    renderTitle,
}: IProps) => {
    const [error, setError] = useState<IDocTableFieldData>({ id: '1', desc: '1', type: '1' });
    const onAddRowHandler = () => {
        if (!docCheckCreateIsEmpty(tableTemplate, createRow)) {
            if (onAddRow) {
                onAddRow();
            }
            setError({ id: '1', desc: '1', type: '1' });
        } else {
            setError(createRow);
        }
    };
    return (
        <>
            <DocTableTd className="title" error={error.id.length === 0}>
                {renderTitle('id')}
            </DocTableTd>
            <DocTableTd className="title" error={error.desc.length === 0}>
                {renderTitle('desc')}
            </DocTableTd>
            <DocTableTd className="title" error={error.type.length === 0}>
                {renderTitle('type')}
            </DocTableTd>
            <DocTableTd className="icons">
                {create ? (
                    <DocTableIcon index={index} onVoidClick={onAddRowHandler}>
                        <PlusLigthSvg />
                    </DocTableIcon>
                ) : (
                    <DocTableIcon index={index} onClickIndex={onDeleteRow}>
                        <TrashSvg />
                    </DocTableIcon>
                )}
            </DocTableTd>
        </>
    );
};
export default DocTabelTrFiledTemplate;
