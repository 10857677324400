import { IUser } from '../../../../assets/interfaces/interfaces';
import { IDocTableData, ITableTemplate } from '../../../../assets/interfaces/modules/doc/doc_interface';
import { IDocTableTemplateData, IDocTemplateDataError } from '../../../../assets/interfaces/modules/doc/tab/doc_template_interface';
import { formatDateToISO } from '../../../../utils/func';
import { ACCEPT_STATUS_TEXT, APPROVE_STATUS_TEXT, DECLINE_STATUS_TEXT } from './variables';

export const getStatusClass = (status: number) => {
    switch (status) {
    case 1:
        return 'save';
    case 2:
        return 'wait';
    case 3:
        return 'process';
    case 4:
        return 'active';
    case 5:
        return 'cancel';
    default:
        return '';
    }
};
export function isRespSuccess(res:any) {
    return res.status === 200;
}
export const getStatusButtonsText = (document_state_id: number) => {
    let actionBtns: string[] = [];
    if (document_state_id === 1 || document_state_id === 5) {
        actionBtns = [APPROVE_STATUS_TEXT];
    } else if (document_state_id === 2 || document_state_id === 3) {
        actionBtns = [ACCEPT_STATUS_TEXT, DECLINE_STATUS_TEXT];
    }
    return actionBtns;
};
export const checkSuccessStatus = (status: number) => {
    switch (status) {
    case 4:
        return true;
    case 5:
        return false;
    default:
        return null;
    }
};
export const getDocumentStateByText = (btnText: string) => {
    let status = null;
    if (btnText === ACCEPT_STATUS_TEXT) {
        status = 4;
    } else if (btnText === DECLINE_STATUS_TEXT) {
        status = 5;
    } else if (btnText === APPROVE_STATUS_TEXT) {
        status = 2;
    }
    return status;
};
export const getSuccessStatusNumber = (success: boolean | null, defaultValue: number) => {
    switch (success) {
    case true:
        return 4;
    case false:
        return 5;
    default:
        return defaultValue;
    }
};
export function docGetTextStatus(status:number) {
    switch (status) {
    case 1:
        return 'Сохранен';
    case 2:
        return 'На согласовании';
    case 3:
        return 'В процессе согласования';
    case 4:
        return 'Согласован';
    case 5:
        return 'Отменён';
    default:
        return '';
    }
}
export function getIndexById(arr: IDocTableData[], id:number) {
    const findItem = arr.find((item) => item.id === id);
    if (!findItem) return -1;
    return arr.indexOf(findItem);
}
export function getEmptyUser(): IUser {
    const now = formatDateToISO(new Date());
    console.log('now', now);
    return {
        id: 0,
        name: '',
        email: '',
        email_verified_at: null,
        created_at: now,
        updated_at: now,
        status: '',
        login: '',
        avatar: null,
        lastname: '',
        middlename: '',
        position_id: 0,
        birthday: null,
        phones: null,
        job_phones: null,
        gender_id: 0,
        last_visit: null,
        last_ip: null,
        telegram: null,
        vks_link: null,
        vacation: false,
        disease: false,
    };
}
function getFirstChar(txt:string) {
    return txt.charAt(0).toUpperCase();
}
export function getFioUser(user: IUser) {
    return user ? `${user.lastname.replace(/^\w/, (c) => c.toUpperCase())} ${getFirstChar(user.name)}${user.middlename ? `.${getFirstChar(user.middlename)}` : ''}` : 'Не указан';
}
export function docGetIndex(index:number | undefined) {
    if (index === 0) return 0;
    if (index === undefined) return -1;
    return index > 0 ? index : -1;
}
export function docGetRecipients(value: IDocTableData, key: keyof IUser) {
    if (value.user) {
        return value.user[key];
    }

    return '';
}
export function docCheckTemplateKeys(key: string): key is 'full_name' | 'name' {
    return key === 'full_name' || key === 'name';
}
export function docTemplateCheckErrors(createRow: IDocTableTemplateData): IDocTemplateDataError {
    const newError:IDocTemplateDataError = {
        array_input: false, full_name: false, name: false, path: false,
    };
    if (createRow.array_input.length === 0) {
        newError.array_input = true;
    } else if (createRow.full_name.length === 0) {
        newError.full_name = true;
    } else if (createRow.name.length === 0) {
        newError.name = true;
    } else if (createRow.path === null) {
        newError.path = true;
    }
    return newError;
}
export function docCheckCreateIsEmpty(tab: keyof ITableTemplate, createRow: any) {
    let isEmpty = true;
    if (tab === 'sign') {
        if (createRow.picture.length && createRow.sign.length && createRow.selected_file !== null && createRow.selected_user_user_id.length && createRow.selected_user_text.length) {
            isEmpty = false;
        }
    } else if (tab === 'template') {
        const error = docTemplateCheckErrors(createRow);
        if (!error.name && !error.full_name && !error.path && !error.array_input) {
            isEmpty = false;
        }
    } else if (tab === 'fields') {
        if (createRow.id.length && createRow.desc.length && createRow.type.length) {
            isEmpty = false;
        }
    }
    return isEmpty;
}
