import { useState } from 'react';
import EditSquareSvg from '../../../assets/svgs/EditSquareSvg';
import TrashSvg from '../../../assets/svgs/TrashSvg';
import UserAddSvg from '../../../assets/svgs/UserAddSvg';
import CheckboxSquare from '../../html/CheckboxSquare';
import SelectLabelDropdown from '../../html/SelectLabelDropdown';
import AdminEditField from './components/AdminEditField';

interface IProps {
    onSave: Function
}
interface IErrors {
    name: boolean,
    namemodule: boolean,
    descmodule: boolean,
}
interface IGroupUser {
    name: string,
    edit: boolean,
    admin: boolean,
}
const AdminEditModule = ({ onSave }: IProps) => {
    require('../../../assets/styles/admin/adminEditModule.scss');
    const [groups, setGroups] = useState<IGroupUser[]>([
        { name: 'Администратор', edit: false, admin: false },
        { name: 'Директор', edit: false, admin: false },
        { name: 'HR', edit: false, admin: false },
        { name: 'Менеджер', edit: false, admin: false },
        { name: 'Пользователи', edit: false, admin: false },
        { name: 'Планирование', edit: false, admin: false },
        { name: 'Бухгалтерия', edit: false, admin: false },
        { name: 'Производство', edit: false, admin: false },
        { name: 'Разработчик', edit: false, admin: false },
    ]);
    const [users, setUsers] = useState<IGroupUser[]>([
        { name: 'Семенов Антон Юрьевич', edit: false, admin: false },
        { name: 'Семенова Алёна Сергеевна', edit: false, admin: false },
        { name: 'Предвин Дмитрий Алексеевич ', edit: false, admin: false },
    ]);
    const [status, setStatus] = useState<string>('0');
    const [name, setName] = useState<string>('');
    const [type, setType] = useState<string>('');
    const [link, setLink] = useState<string>('');
    const [namemodule, setNamemodule] = useState<string>('');
    const [descmodule, setDescmodule] = useState<string>('');
    const [posblock, setPosblock] = useState<string>('');
    const [linkfile, setLinkfile] = useState<string>('');
    const [linkcontent, setLinkcontent] = useState<string>('');
    const [sort, setSort] = useState<string>('');
    const [image, setImage] = useState<string>('');
    const [guide, setGuide] = useState<string>('');
    const [imageText, setImageText] = useState<string>('');
    const [guideText, setGuideText] = useState<string>('');
    const [instruction, setInstruction] = useState<boolean>(false);
    const [returnType, setReturnType] = useState<boolean>(false);
    const [selectedGroup, setSelectedGroup] = useState<string>('');
    const [selectedUser, setSelectedUser] = useState<string>('');

    const [errors, setErrors] = useState<IErrors>({
        name: false,
        namemodule: false,
        descmodule: false,
    });
    const changeState = (index: number, typeObj: string, obj: IGroupUser[], setObj: Function) => {
        const newState = obj.map((state: any, i) => {
            if (index === i) {
                return { ...state, [typeObj]: !state[typeObj] };
            }

            return state;
        });
        setObj(newState);
    };
    const clearErrors = () => {
        setErrors({
            name: false,
            namemodule: false,
            descmodule: false,
        });
    };
    const clearText = (setText: Function) => {
        setText('');
    };
    const onSend = () => {
        clearErrors();
        if (name.length < 1) {
            setErrors((prevstate: IErrors) => ({ ...prevstate, name: true }));
        }
        if (namemodule.length < 1) {
            setErrors((prevstate: IErrors) => ({ ...prevstate, namemodule: true }));
        }
        if (descmodule.length < 1) {
            setErrors((prevstate: IErrors) => ({ ...prevstate, descmodule: true }));
            return;
        }
        onSave();
    };

    return (
        <div className="admin_edit_module ">
            <div className="admin_edit_module__row">
                <div className="field">
                    <SelectLabelDropdown
                        htmlFor="status"
                        label="Статус"
                        classNameLabel="title"
                        value={status}
                        onChange={(e) => setStatus(e.currentTarget.value)}
                        options={[
                            { value: '0', text: 'Не актив' },
                            { value: '1', text: 'Актив' },
                        ]}
                    />
                </div>

                <AdminEditField
                    htmlFor="name"
                    label="Уникальное имя"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                    value={name}
                    errors={errors}
                />
            </div>
            <div className="admin_edit_module__row">
                <div className="field">
                    <SelectLabelDropdown
                        htmlFor="typeofelem"
                        label="Тип элемента"
                        classNameLabel="title"
                        value={type}
                        onChange={(e) => setType(e.currentTarget.value)}
                        options={[
                            { value: '1', text: 'Модуль' },
                            { value: '2', text: 'Блок' },
                            { value: '3', text: 'Блок для конкретного модуля' },
                        ]}
                    />
                </div>

                <AdminEditField
                    htmlFor="link"
                    label="Ссылка на модуль"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLink(e.target.value)}
                    value={link}
                />
            </div>
            <div className="admin_edit_module__row">
                <AdminEditField
                    htmlFor="namemodule"
                    label="Название модуля\ID родительского модуля"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNamemodule(e.target.value)}
                    value={namemodule}
                    errors={errors}
                />
                <AdminEditField
                    htmlFor="descmodule"
                    label="Описание модуля"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescmodule(e.target.value)}
                    value={descmodule}
                    errors={errors}
                />
            </div>
            <div className="admin_edit_module__row">
                <AdminEditField
                    htmlFor="posblock"
                    label="Позиция блока"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPosblock(e.target.value)}
                    value={posblock}
                />
                <AdminEditField
                    htmlFor="linkfile"
                    label="Ссылка на файл с функциями"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLinkfile(e.target.value)}
                    value={linkfile}
                />
            </div>
            <div className="admin_edit_module__row">
                <AdminEditField
                    htmlFor="linkcontent"
                    label="Ссылка на блок с контентом"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLinkcontent(e.target.value)}
                    value={linkcontent}
                />
                <AdminEditField
                    htmlFor="sort"
                    label="Сортировка"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSort(e.target.value)}
                    value={sort}
                />
            </div>
            <div className="admin_edit_module__row">
                <AdminEditField
                    htmlFor="image"
                    label="Изображение"
                    className="image"
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setImage(e.target.value)}
                    value={image}
                />
                <AdminEditField
                    htmlFor="guide"
                    label="Инструкция к модулю"
                    className="image"
                    type="file"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGuide(e.target.value)}
                    value={guide}
                />
            </div>
            <div className="admin_edit_module__row">
                <div className="field">
                    <div className="img_url">
                        <input
                            type="text"
                            name="imageurl"
                            id="imageurl"
                            onChange={(e) => setImageText(e.target.value)}
                            value={imageText}
                            placeholder=""
                        />
                        <div className="icon">
                            <span onClick={() => clearText(setImageText)}><TrashSvg /></span>
                        </div>
                    </div>
                </div>
                <div className="field">
                    <div className="img_url">
                        <input
                            type="text"
                            name="imageurl2"
                            onChange={(e) => setGuideText(e.target.value)}
                            value={guideText}
                            id="imageurl2"
                            placeholder=""
                        />
                        <div className="icon">
                            <span onClick={() => clearText(setGuideText)}><TrashSvg /></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="admin_edit_module__row">
                <div className="field row">
                    <div className="col">

                        <CheckboxSquare
                            htmlFor="guidemodule"
                            label="Инструкция к модулю"
                            value={instruction}
                            onClick={() => setInstruction(!instruction)}
                        />
                    </div>
                    <div className="col">
                        <CheckboxSquare
                            label="Тип возвращаемого контента"
                            htmlFor="returntype"
                            value={returnType}
                            onClick={() => setReturnType(!returnType)}
                        />
                    </div>
                </div>
            </div>
            <div className="admin_edit_module__row">
                <div className="field row fullwidth">
                    <div className="col select">
                        <SelectLabelDropdown
                            htmlFor="accessgroup"
                            label="Доступ для групп"
                            classNameLabel="title"
                            value={selectedGroup}
                            onChange={(e) => { setSelectedGroup(e.currentTarget.value); }}
                            options={[
                                { value: '0', text: `Группы (выбранно:{${groups.length})`, disabled: true },
                                ...groups.map((group, index) => ({
                                    value: String(index + 1), // Индексы начинаются с 0, а значения в опции с 1
                                    text: group.name,
                                })),
                            ]}
                            showInput
                        />
                    </div>
                    <div className="col">
                        <div className="groups">
                            {groups.map((group: IGroupUser, index: number) => (
                                <div className="group">
                                    <span className="name">{group.name}</span>
                                    <span onClick={() => changeState(index, 'edit', groups, setGroups)} className={`edit ${group.edit && 'active'}`}><EditSquareSvg /></span>
                                    <span onClick={() => changeState(index, 'admin', groups, setGroups)} className={`add ${group.admin && 'active'}`}><UserAddSvg /></span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="admin_edit_module__row">
                <div className="field row fullwidth">
                    <div className="col select">
                        <SelectLabelDropdown
                            htmlFor="accessforusers"
                            label="Доступ для пользователей"
                            classNameLabel="title"
                            value={selectedUser}
                            onChange={(e) => setSelectedUser(e.currentTarget.value)}
                            options={[
                                { value: '0', text: `Пользователи (выбранно:{${users.length})`, disabled: true },
                                ...users.map((user, index) => ({
                                    value: String(index + 1), // Индексы начинаются с 0, а значения в опции с 1
                                    text: user.name,
                                })),
                            ]}
                            showInput
                        />
                    </div>
                    <div className="col">
                        <div className="groups">
                            {users.map((user: IGroupUser, index: number) => (
                                <div className="group">
                                    <span className="name">{user.name}</span>
                                    <span onClick={() => changeState(index, 'edit', users, setUsers)} className={`edit ${user.edit && 'active'}`}><EditSquareSvg /></span>
                                    <span onClick={() => changeState(index, 'admin', users, setUsers)} className={`add ${user.admin && 'active'}`}><UserAddSvg /></span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="admin_edit_module__send_btn">
                <button type="button" onClick={onSend}>Отправить</button>
            </div>
        </div>

    );
};

export default AdminEditModule;
