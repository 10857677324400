import { MouseEventHandler } from 'react';
import '../../assets/styles/html/CheckboxSquare.scss';

interface IProps {
    htmlFor: string,
    onClick: MouseEventHandler<HTMLInputElement>,
    value: boolean,
    label: string,
}
const CheckboxSquare = ({
    htmlFor, onClick, value, label,
}: IProps) => (
    <label htmlFor={htmlFor} className="checkbox">
        {label}
        <span className="checkbox__inner">
            <input
                type="checkbox"
                checked={value}
                className="checkbox__input"
                onClick={onClick}
                name={htmlFor}
                id={htmlFor}
            />
        </span>
    </label>
);

export default CheckboxSquare;
