import { IDocTableHeader, ITableTemplate } from '../../../../../assets/interfaces/modules/doc/doc_interface';
import '../../../../../assets/styles/modules/components/doc/table/module_table_doc.scss';
import TabelPagination from './TablePagination';
import DocHandbookTabelTrLayout from './tr/DocHandbookTabelTrLayout';

interface IProps {
    headers: IDocTableHeader[]
    onChangePage: Function;
    currentPage: number;
    tableTemplate: keyof ITableTemplate;
    createRow: any;
    inputSearch: string;
    pageNumbers: Array<number>;
    data: any[];
    onSelectClick?: (index: number) => void
    onChangeField: (index:number, text: string, key: string) => void;
    onAddRow: () => void;
    onDeleteRow: (index:number) => void;
    onChangeFiles?: (index: number, files: FileList | null) => void
}
const TableDocHandbookLayout = ({
    headers, onAddRow, onDeleteRow, onChangeField, onSelectClick, onChangeFiles, tableTemplate, pageNumbers, inputSearch, onChangePage, currentPage, data, createRow,
}: IProps) => {
    const onClickPagination = (page: number) => {
        if (page < (pageNumbers.length + 1) && page > 0) {
            onChangePage(page);
        }
    };
    const renderTableContent = () => {
        if (data.length > 0) {
            const searchField = Object.keys(data[0])[0];
            const filteredSignElements = data.filter((row) => !inputSearch.length || row[searchField]
                .toString()
                .toLowerCase()
                .replace(/\s/g, '')
                .includes(inputSearch.toString().toLowerCase().replace(/\s/g, '')));

            if (filteredSignElements.length > 0) {
                return filteredSignElements.map((td, index) => (
                    <DocHandbookTabelTrLayout
                        tableTemplate={tableTemplate}
                        onSelectClick={onSelectClick}
                        onDeleteRow={onDeleteRow}
                        td={td}
                        index={index}
                        onChangeField={onChangeField}
                        onChangeFiles={onChangeFiles}
                    />
                ));
            }
        }

        return (
            null
        );
    };
    return (
        <div className={`module_table_doc ${tableTemplate}`}>
            <div className="table_responsive">
                <table className="table">
                    <thead className="thead">
                        <tr>
                            {headers.map((th) => (
                                <th key={th.text} className={`th ${th.class}`}>{th.text}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="tbody">
                        <DocHandbookTabelTrLayout
                            tableTemplate={tableTemplate}
                            onAddRow={onAddRow}
                            index={-1}
                            create
                            createRow={createRow}
                            onChangeField={onChangeField}
                            onSelectClick={onSelectClick}
                            onChangeFiles={onChangeFiles}
                        />
                        {renderTableContent()}
                    </tbody>
                </table>
            </div>
            <div
                className="table_pagination"
            >
                <TabelPagination
                    currentPage={currentPage}
                    pageNumbers={pageNumbers}
                    onClick={onClickPagination}
                />
            </div>

        </div>
    );
};
export default TableDocHandbookLayout;
