import s from './Textarea.module.scss';

type IProps = {
    value: string;
    onChange: (text: string) => void;
}

const DocTableTextarea = ({
    value, onChange,
}: IProps) => (
    <textarea
        className={s.textarea}
        value={value}
        onChange={(e) => { onChange(e.currentTarget.value); }}
    />

);
export default DocTableTextarea;
