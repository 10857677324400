import { useState } from 'react';
import { IMultySelectSearch } from '../../../../../assets/interfaces/interfaces';
import { IDocPage } from '../../../../../assets/interfaces/modules/doc/doc_interface';
import { IDocCreateSignData, IDocSignTable, IDocTableSignData } from '../../../../../assets/interfaces/modules/doc/tab/doc_sign_interface';
import { SIGN_TAB } from '../../../../../utils/tabs';
import { addSignAPI, deleteSignAPI } from '../../api/documentAPI';
import DocHandbookLayout from '../layouts/DocHandbookLayout';

interface IProps {
    data: IDocSignTable;
    onChangeData: (data: IDocSignTable, page: keyof IDocPage) => void;
    onCloseTab: () => void;
}
const DocSignTab = ({ onCloseTab, data, onChangeData }: IProps) => {
    const [createRow, setCreateRow] = useState<IDocCreateSignData>({
        picture: '',
        sign: '',
        selected_file: null,
        selected_user_text: '',
        selected_user_user_id: '',
    });
    const [selectModalValues, setSelectModalValues] = useState<IMultySelectSearch[]>([
        {
            id: 1, title: 'Ермилов Дмитрий Олегович', subtitle: 'ermilov@gmail.com', show: true, selected: false,
        },
        {
            id: 2, title: 'Балаев Валентин Романович', subtitle: 'st1ll@odr.su', show: true, selected: false,
        },
        {
            id: 3, title: 'Николаев Игорь Васильевич', subtitle: 'nikolay@e1.ru', show: true, selected: false,
        },
    ]);
    function changeTableData(newData: IDocSignTable) {
        onChangeData(newData, SIGN_TAB);
    }
    const onChangeTablePage = (page: number) => {
        const newData = { ...data };
        newData.currentPage = page;
        changeTableData(newData);
    };
    function getEmptyCreateRow(): IDocCreateSignData {
        return {
            picture: '',
            sign: '',
            selected_file: null,
            selected_user_text: '',
            selected_user_user_id: '',
        };
    }
    const clearCreateRow = () => {
        setCreateRow(getEmptyCreateRow());
    };
    const onAddRow = async (newData: IDocSignTable) => {
        const form = new FormData();
        form.append('user_id', createRow.selected_user_user_id);
        if (createRow.selected_file) {
            form.append('picture', createRow.selected_file);
        }
        const response = await addSignAPI(form);
        if (response.status === 200) {
            changeTableData(newData);
            clearCreateRow();
        }
    };
    const onDeleteRow = async (newData: IDocSignTable, id: number) => {
        console.log('id', id);
        const response = await deleteSignAPI(id);
        if (response.status === 200) {
            changeTableData(newData);
        }
    };
    const onChangeFiles = (index: number, files: FileList | null) => {
        if (files) {
            const file = files[0];
            const fileHref = URL.createObjectURL(file);
            const sign = `подпись ${file.name}`;
            if (index === -1) {
                const newData = getEmptyCreateRow();
                newData.selected_user_text = createRow.selected_user_text;
                newData.picture = fileHref;
                newData.sign = sign;
                newData.selected_file = file;
                setCreateRow(newData);
            } else if (index > -1) {
                const newData = { ...data };
                newData.data[index].picture = fileHref;
                changeTableData(newData);
            }
        }
    };
    function isUserId(key:keyof IDocTableSignData):key is 'user_id' {
        return key === 'user_id';
    }

    const onChangeCreateRow = (key: keyof IDocCreateSignData, value: string) => {
        const newData = { ...createRow };
        if (key === 'selected_user_text' || key === 'selected_user_user_id') {
            newData[key] = value;
            setCreateRow(newData);
        }
    };
    const onChangeDataRow = (index: number, key: keyof IDocTableSignData, value: string) => {
        const newData = { ...data };
        if (isUserId(key)) {
            newData.data[index][key] = value;
            changeTableData(newData);
        }
    };
    const onSelectModal = (values: IMultySelectSearch[], index: number) => {
        let selectedUser = '';
        values.forEach((value) => { if (value.selected === true) { selectedUser = value.title; } });
        if (index !== -1) {
            const newData = { ...data };
            newData.data[index].user_id = selectedUser;
            changeTableData(newData);
        } else {
            const newData = getEmptyCreateRow();
            newData.picture = createRow.picture;
            newData.sign = createRow.sign;
            newData.selected_file = createRow.selected_file;
            newData.selected_user_text = selectedUser;
            newData.selected_user_user_id = '1';
            setCreateRow(newData);
        }
    };
    function setSelectedModalValue(search: string) {
        if (search.length) {
            let findIndex = -1;
            selectModalValues.forEach((val, i) => {
                if (val.title === search) {
                    findIndex = i;
                }
            });
            if (findIndex !== -1) {
                const newModalValues = [...selectModalValues];
                newModalValues[findIndex].selected = true;
                setSelectModalValues(newModalValues);
            }
        }
    }
    const onSelectModalOpen = (index: number) => {
        if (index === -1) {
            setSelectedModalValue(createRow.selected_user_text);
        } else {
            setSelectedModalValue(data.data[index].user_id);
        }
    };
    const clearSelectModalValues = () => {
        const newData = [...selectModalValues];
        newData.forEach((val) => val.selected = false);
        setSelectModalValues(newData);
    };
    return (
        <DocHandbookLayout
            title="Справочник факсимиле"
            tableTemplate="sign"
            tableData={data}
            onChangeTablePage={onChangeTablePage}
            createRow={createRow}
            getDeleteName={(index) => data.data[index].picture}
            onChangeCreateRow={onChangeCreateRow}
            onChangeDataRow={onChangeDataRow}
            onAddRow={onAddRow}
            onDeleteRow={onDeleteRow}
            onChangeFilesRow={onChangeFiles}
            onSelectModalValue={onSelectModal}
            onSelectModalOpen={onSelectModalOpen}
            clearSelectModalValues={clearSelectModalValues}
            selectModalProps={{
                title: 'Список пользователей', multiple: false, values: selectModalValues, link: { text: 'Список пользователей', url: 'https://develop.transmashpribor.ru/adminka.html?module=users' },
            }}
            tableHeaders={[
                { text: 'ФИО' },
                { text: 'Подпись' },
                { text: '' },
            ]}
            onCloseTab={onCloseTab}
        />
    );
};

export default DocSignTab;
