import { SelectArrowSvg } from '../../../../../../assets/svgs/SelectArrowSvg';
import { docGetIndex } from '../../../config/funcs';
import s from './SelectField.module.scss';

type IProps = {
    text: string;
    index: number;
    onClick: (index: number) => void;
}
const DocTabelSelectField = ({
    onClick, text, index,
}: IProps) => (
    <div className={`${s.select_wrapper}`} onClick={() => onClick(docGetIndex(index))}>
        <span>{text}</span>

        <span className={s.arrow}><SelectArrowSvg size={10} /></span>
    </div>

);
export default DocTabelSelectField;
