import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import '../assets/styles/header/back_crumbs_date.scss';
import Breadcrumbs from './Header/Breadcrumbs';
import CurrentDate from './Header/CurrentDate';

const BackCrumbsDate = observer(() => {
    const [showBack, setShowBack] = useState<boolean>(false);

    useEffect(() => {
        const path = window.location.pathname.split('/');
        path.shift();
        if (path[0] === '') {
            setShowBack(false);
        } else {
            setShowBack(true);
        }
    }, []);

    return (
        showBack ? (
            <div className="back_crumbs_date">
                <div className="left">
                    <Breadcrumbs />
                </div>
                <CurrentDate />
            </div>
        ) : null
    );
});

export default BackCrumbsDate;
