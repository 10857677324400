import s from './BorderRound.module.scss';

interface IProps {
    text: string;
    classes?: string;
}
const ButtonBorderRound = ({ text, classes }: IProps) => {
    const getClass = (className?:string) => {
        switch (className) {
        case 'button_success':
            return s.button_success;
        case 'button_danger':
            return s.button_danger;
        case 'button_info':
            return s.button_info;
        default:
            break;
        }
        return '';
    };
    return (
        <button
            type="button"
            className={`${s.button} ${getClass(classes)}`}
        >
            {text}
        </button>
    );
};
export default ButtonBorderRound;
