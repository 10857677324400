import { Link } from 'react-router-dom';
import { STARTER_ROUTE } from '../../utils/consts';

import '../../assets/styles/login.scss';
import LogoSvg from '../../assets/svgs/LogoSvg';

interface IProps {
    showBack: boolean,
    children: any
}
const AuthForm = ({ showBack, children }: IProps) => (
    <div className="container">
        {showBack && (
            <div className="exit"><Link to={STARTER_ROUTE}>Назад</Link></div>
        )}
        <header className="header">
            <div className="header__container">
                <div className="img_logo_login">
                    <LogoSvg />
                </div>
                {children}
            </div>
        </header>
    </div>

);

export default AuthForm;
