import { MouseEventHandler, useState } from 'react';
import '../../../assets/styles/admin/admin_module_table.scss';
import EditSquareSvg from '../../../assets/svgs/EditSquareSvg';
import PencilSvg from '../../../assets/svgs/PencilSvg';
import UserAddSvg from '../../../assets/svgs/UserAddSvg';
import AdminTableLayout from './AdminTableLayout';

interface IGroupUser {
    name: string,
    edit: boolean,
    add: boolean
}
interface IAccess {
    groups: IGroupUser[],
    users: IGroupUser[],
}
interface IModule {
    name:string,
    access:IAccess,
    status:boolean
}
interface IProps {
    onEdit: MouseEventHandler<HTMLDivElement>;
}

const AdminModuleTable = ({ onEdit }: IProps) => {
    const [modules, setModules] = useState<IModule[]>([
        {
            name: 'Задачи',
            access: {
                groups: [
                    { name: 'Администратор', edit: true, add: true },
                    { name: 'Директор', edit: false, add: false },
                    { name: 'HR', edit: false, add: false },
                    { name: 'Менеджер', edit: false, add: false },
                    { name: 'Пользователи', edit: false, add: false },
                    { name: 'Планирование', edit: false, add: false },
                    { name: 'Бухгалтерия', edit: false, add: false },
                    { name: 'Производство', edit: false, add: false },
                    { name: 'Разработчик', edit: false, add: false },
                ],
                users: [
                    { name: 'Семенов Антон Юрьевич', edit: true, add: true },
                    { name: 'Семенова Алёна Сергеевна', edit: true, add: true },
                    { name: 'Предеин Дмитрий Алексеевич', edit: true, add: true },
                ],
            },
            status: true,
        },
    ]);
    return (
        <AdminTableLayout className="module">
            <tr>
                <th>#</th>
                <th>Название модуля</th>
                <th>Разрешения на действия</th>
                <th>Статус</th>
                <th>Функции</th>
            </tr>
            <tbody>
                {modules.map((module: IModule, index: number) => (
                    <tr>
                        <td>{++index}</td>
                        <td>
                            {module.name}
                        </td>
                        <td>
                            <div className="permissions">
                                <div className="groups">
                                    <div className="title">Группы:</div>
                                    {module.access.groups.map((group: IGroupUser) => (
                                        <div className="group">
                                            <span className="name">
                                                -
                                                {group.name}
                                            </span>
                                            {group.edit && (
                                                <span className="edit"><EditSquareSvg /></span>
                                            )}
                                            {group.add && (
                                                <span className="add"><UserAddSvg /></span>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className="users">
                                    <div className="title">Пользователи:</div>
                                    {module.access.users.map((user: IGroupUser) => (
                                        <div className="user">
                                            <span className="name">
                                                -
                                                {user.name}
                                            </span>
                                            {user.edit && (
                                                <span className="edit"><EditSquareSvg /></span>
                                            )}
                                            {user.add && (
                                                <span className="add"><UserAddSvg /></span>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="active">{module.status ? 'Актив' : 'Не актив'}</div>
                        </td>
                        <td>
                            <div className="icons">
                                <div className="btn edit" onClick={onEdit}>
                                    <PencilSvg />
                                </div>
                            </div>

                        </td>
                    </tr>
                ))}

            </tbody>
        </AdminTableLayout>
    );
};

export default AdminModuleTable;
