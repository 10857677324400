import s from './Link.module.scss';

interface IProps {
    href: string;
    text: string;
}

const DocTableFileLink = ({
    href, text,
}: IProps) => (
    <a className={s.link} target="_blank" href={href} rel="noreferrer">{text}</a>

);
export default DocTableFileLink;
