import { IUser } from '../../assets/interfaces/interfaces';
import EditSvg from '../../assets/svgs/EditSvg';
import UserAvatarDefault from '../../assets/svgs/UserAvatarDefault';
import UserCardLayout from './UserCardLayout';

interface IProps {
    user: IUser | undefined
}
const UserCard = ({ user }: IProps) => {
    require('../../assets/styles/user/card.scss');
    const changeAvatar = () => {
        const input: HTMLInputElement = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/png, image/jpeg';
        input.onchange = () => {
            if (input.files) {
                const file = input.files[0];
            }
        };
        input.click();
    };
    return (
        <UserCardLayout>
            <div className="cardlayout_user">
                <div className="avatar">
                    {!user?.avatar && (
                        <div className="img svg">
                            <UserAvatarDefault />
                        </div>
                    )}
                    {user?.avatar && (
                        <div
                            className="img"
                            style={{
                                backgroundImage: `url(${user?.avatar})`,
                            }}
                        />
                    )}

                    <span className="edit" onClick={changeAvatar}>
                        <EditSvg />
                    </span>
                </div>
                <div className="fio">
                    <span>{user?.lastname}</span>
                    <span>{user?.name}</span>
                    <span>{user?.middlename}</span>
                </div>
            </div>
            <div className="cardlayout_user__info">
                <span className="title">Должность</span>
                <span className="value">{user?.position_id === 1 ? 'Инжинер-методолог' : ''}</span>
            </div>
            <div className="cardlayout_user__info">
                <span className="title">Почта</span>
                <span className="value email">{user?.email}</span>
            </div>
            <div className="cardlayout_user__info">
                <span className="title">Рабочий телефон</span>
                <span className="value phone">
                    <span className="phone">{user?.job_phones}</span>
                    <span className="edit" style={{ display: !user?.job_phones ? 'none' : '' }}>
                        <EditSvg />
                    </span>
                </span>
            </div>
            <div className="cardlayout_user__info">
                <span className="title">Личный телефон</span>
                <span className="value phone">
                    <span className="phone">{user?.phones}</span>
                    <span className="edit" style={{ display: !user?.job_phones ? 'none' : '' }}>
                        <EditSvg />
                    </span>
                </span>
            </div>
        </UserCardLayout>

    );
};

export default UserCard;
