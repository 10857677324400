import { ReactNode } from 'react';

interface IProps {
    width?: number,
    height?: number,
    children: ReactNode,
}
const UserCardLayout = ({ width, height, children }:IProps) => {
    require('../../assets/styles/user/components/cardlayout.scss');
    return (
        <div className="cardlayout" style={{ width: `${width}px`, height: `${height}px` }}>
            {children}
        </div>

    );
};

export default UserCardLayout;
