import CloseTaskSvg from '../../assets/svgs/CloseTaskSvg';
import s from './EditModal.module.scss';

interface IProps {
    show: boolean;
    onCloseModal: Function;
    onClickSave: Function;
    onClickSpaceZone?: Function;
    title?: string;
    confirmBtnText?: string;
    confirmBtnHide?: boolean;
    children: any;
}

const EditModal = ({
    show, onCloseModal, onClickSpaceZone, onClickSave, title, confirmBtnText, confirmBtnHide, children,
}: IProps) => (
    <div
        className={`${s.wrapper} ${show ? s.show : ''}`}
        onClick={() => { onCloseModal(); }}
    >
        <div className={s.dialog} onClick={(e) => { e.stopPropagation(); }}>
            <div className={s.modal}>
                <div className={s.edit_modal}>
                    {title ? (
                        <div className={s.title}>{title}</div>
                    ) : ''}
                    {children}

                    {onClickSpaceZone
                        ? <div className={s.empty_space} onClick={() => { onClickSpaceZone(); }} /> : null}

                </div>
                <div className={s.footer}>
                    <button type="button" className={`${s.btn} ${s.btn_close}`} onClick={() => onCloseModal()}>
                        <CloseTaskSvg />
                        Закрыть
                    </button>
                    {confirmBtnHide ? null : (
                        <button
                            type="button"
                            onClick={() => onClickSave()}
                            className={`${s.btn} ${s.btn_edit}`}
                        >
                            {confirmBtnText || 'Добавить'}
                        </button>
                    )}

                </div>

            </div>
        </div>

    </div>

);

export default EditModal;
