import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IModuleTab } from '../../../assets/interfaces/interfaces';
import { IDocPage, IDocTable, IDocTableData } from '../../../assets/interfaces/modules/doc/doc_interface';
import { IDocSignTable, IDocTableSignData } from '../../../assets/interfaces/modules/doc/tab/doc_sign_interface';
import '../../../assets/styles/modules/documentoobotor/documentooborot_page.scss';
import { DOC_ROUTE } from '../../../utils/consts';
import {
    ARCHIVE_TAB,
    FIELD_TAB,
    HOME_TAB,
    INCOMING_TAB, OUTCOMING_TAB,
    SIGN_TAB,
    TEMPLATE_TAB,
} from '../../../utils/tabs';
import PageWithTabsLayout from '../layout/PageWithTabsLayout';
import {
    getArchiveDocumentsAPI, getDocumentsAPI,
    getSignsAPI,
    getUsersDocumentAPI,
} from './api/documentAPI';
import DocArchiveTab from './ui/tabs/DocArchiveTab';
import DocFieldTab from './ui/tabs/DocFieldTab';
import DocIncomingTab from './ui/tabs/DocIncomingTab';
import DocOutgoingTab from './ui/tabs/DocOutgoingTab';
import DocSignTab from './ui/tabs/DocSignTab';
import DocStarterTab from './ui/tabs/DocStarterTab';
import DocTemplateTab from './ui/tabs/DocTemplateTab';

const DocumentooborotPage = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [moduleTabs, setModuleTabs] = useState<IModuleTab[]>([
        {
            name: '', text: 'Исходящие', active: true, count: 0, tab: OUTCOMING_TAB,
        },
        {
            name: '', text: 'Входящие', active: false, count: 0, tab: INCOMING_TAB,
        },
        {
            name: '', text: 'Архив', active: false, count: 0, tab: ARCHIVE_TAB,
        },
        {
            name: '', text: 'Справочник факсимиле', active: false, tab: SIGN_TAB,
        },
        {
            name: '', text: 'Справочник шаблонов', active: false, tab: TEMPLATE_TAB,
        },
        {
            name: '', text: 'Справочник полей', active: false, tab: FIELD_TAB,
        },
    ]);
    const [dataOutgoing, setDataOutgoing] = useState<IDocTable>({
        data: [],
        showData: [],
        currentPage: 1,
        todosPerPage: 10,
        pages: [],
    });
    const [dataIncoming, setDataIncoming] = useState<IDocTable>({
        data: [],
        showData: [],
        currentPage: 1,
        todosPerPage: 10,
        pages: [],
    });
    const [dataArchive, setDataArchive] = useState<IDocTable>({
        data: [],
        showData: [],
        currentPage: 1,
        todosPerPage: 10,
        pages: [],
    });
    const [dataSign, setDataSign] = useState<IDocSignTable>({
        data: [],
        currentPage: 1,
        todosPerPage: 10,
    });

    const [starterTab, setStarterTab] = useState<boolean>(false);
    function changeTabCount(tab_txt: string, count: number) {
        moduleTabs.forEach((tab, index) => {
            if ((tab.tab === tab_txt)) {
                if (index === -1) return;
                const newModules = [...moduleTabs];
                newModules[index].count = count;
                setModuleTabs(newModules);
            }
        });
    }
    function onChangePages(tableData: IDocTable) {
        const pages = [];
        for (let i = 1; i <= Math.ceil(tableData.data.length / tableData.todosPerPage); i++) {
            pages.push(i);
        }
        return pages;
    }
    const incomingData = useMemo(() => dataIncoming, [dataIncoming]);
    const getIndexTab = (tab: string) => {
        let index = -1;
        moduleTabs.forEach((val, i) => { if (val.tab === tab) index = i; });
        return index;
    };

    const changeTab = (index: number) => {
        const newTabs = [...moduleTabs];
        newTabs.forEach((val) => val.active = false);
        if (index === -1) {
            setStarterTab(true);
            setModuleTabs(newTabs);
            return;
        }
        setStarterTab(false);
        newTabs[index].active = true;
        setModuleTabs(newTabs);
    };
    const getRouteHandler = (index?: number, tab?: string) => {
        const splitted = DOC_ROUTE.split('/');
        splitted.shift();
        if (tab) {
            const index = getIndexTab(tab);
            changeTab(index);
            splitted[1] = tab;
            splitted[2] = String(params.id);
        } else {
            let tab = HOME_TAB;
            moduleTabs.forEach((val, i) => {
                if (index === i && val.tab) tab = val.tab;
            });
            splitted[1] = tab;
            splitted[2] = String(0);
        }

        const route = `/${splitted.join('/')}`;
        return route;
    };
    function dataTableIsEmpty() {
        if (moduleTabs[0].active && dataOutgoing.data.length === 0) {
            return true;
        } if (moduleTabs[1].active && dataIncoming.data.length === 0) {
            return true;
        } if (moduleTabs[2].active && dataArchive.data.length === 0) {
            return true;
        } if (moduleTabs[3].active && dataSign.data.length === 0) {
            return true;
        }
        return false;
    }
    function checkLengthEmpty(arr: any[]) {
        return Array.isArray(arr) && arr.length === 0;
    }
    async function getResponseData(api: Function): Promise<{'Входящие': IDocTableData[], 'Исходящие': IDocTableData[], 'Факсимиле': IDocTableSignData[], success: boolean}> {
        const resp = await api();
        if (resp.status !== 200) {
            return {
                success: false, Входящие: [], Исходящие: [], Факсимиле: [],
            };
        }
        const res = resp.data.data;
        return {
            success: true, Входящие: res['Входящие'], Исходящие: res['Исходящие'], Факсимиле: res['Факсимиле'],
        };
    }

    function getTableSlicedData(tableData: IDocTable) {
        if (tableData.data.length) {
            const indexOfLastTodo = tableData.currentPage * tableData.todosPerPage;
            const indexOfFirstTodo = indexOfLastTodo - tableData.todosPerPage;

            return tableData.data.slice(indexOfFirstTodo, indexOfLastTodo);
        }
        return [];
    }
    function replaceTableData(setData: Function, tab: string, newTableData: IDocTable) {
        newTableData.showData = getTableSlicedData(newTableData);
        console.log('replaceTableData newTableData.data.length', newTableData.data.length);
        setData(newTableData);
        changeTabCount(tab, newTableData.data.length);
    }
    function changeTableData(getData:IDocTable, setData: Function, tab: string, data: IDocTableData[]) {
        const newData = { ...getData };
        newData.data = data;
        newData.showData = getTableSlicedData(newData);
        newData.pages = onChangePages(newData);
        console.log('changeTableData newData', newData);
        changeTabCount(tab, newData.data.length);
        setData(newData);
    }
    function changeTabData(getData:IDocTable, setData: Function, tab: string, data: IDocTableData[]) {
        if (checkLengthEmpty(getData.data)) {
            changeTableData(getData, setData, tab, data);
        }
    }
    async function getDocuments() {
        const recepData = await getResponseData(getDocumentsAPI);
        if (recepData.success) {
            changeTabData(dataOutgoing, setDataOutgoing, OUTCOMING_TAB, recepData['Исходящие']);
            changeTabData(dataIncoming, setDataIncoming, INCOMING_TAB, recepData['Входящие']);
            console.log('dataIncoming', dataIncoming);
        }
    }

    async function getArchive() {
        const archiveData = await getResponseData(getArchiveDocumentsAPI);
        if (archiveData.success) {
            const concatData = archiveData['Входящие'].concat(archiveData['Исходящие']);
            changeTabData(dataArchive, setDataArchive, ARCHIVE_TAB, concatData);
        }
    }
    async function getSigns() {
        const newData = { ...dataSign };
        const signData = await getResponseData(getSignsAPI);
        if (signData.success) {
            newData.data = signData['Факсимиле'];
            setDataSign(newData);
        }
    }
    async function getData() {
        if (dataTableIsEmpty()) {
            getDocuments();
            getArchive();
            getSigns();
            await getUsersDocumentAPI();
        }
    }
    const onChangeData = useCallback((newData: IDocTable, tab: keyof IDocPage) => {
        switch (tab) {
        case INCOMING_TAB:
            replaceTableData(setDataIncoming, tab, newData);
            break;
        case OUTCOMING_TAB:
            replaceTableData(setDataOutgoing, tab, newData);
            break;
        case ARCHIVE_TAB:
            replaceTableData(setDataArchive, tab, newData);
            break;
        default:
            break;
        }
    }, []);
    const onChangeSignData = (newData: IDocSignTable) => {
        setDataSign(newData);
    };
    const onChangeModuleTab = (index: number) => {
        changeTab(index);
        const route = getRouteHandler(index);
        navigate(route);
    };
    const onCloseTab = () => {
        changeTab(-1);
        const route = getRouteHandler(-1);
        navigate(route);
    };
    useEffect(() => {
        getData();
    }, []);
    useEffect(() => {
        const route = getRouteHandler(undefined, params.tab);
        if (window.location.pathname !== route) {
            navigate(route);
        }
    }, [params]);
    return (
        <PageWithTabsLayout tabs={moduleTabs} onChangeModuleTab={onChangeModuleTab}>
            <div className="documentooborot_page">
                {(() => {
                    switch (true) {
                    case moduleTabs[0].active:
                        return <DocOutgoingTab data={dataOutgoing} onChangeData={onChangeData} onCloseTab={onCloseTab} />;
                    case moduleTabs[1].active:
                        return <DocIncomingTab data={incomingData} onChangeData={onChangeData} onCloseTab={onCloseTab} />;
                    case moduleTabs[2].active:
                        return <DocArchiveTab data={dataArchive} onChangeData={onChangeData} onCloseTab={onCloseTab} />;
                    case moduleTabs[3].active:
                        return <DocSignTab data={dataSign} onChangeData={onChangeSignData} onCloseTab={onCloseTab} />;
                    case moduleTabs[4].active:
                        return <DocTemplateTab onCloseTab={onCloseTab} />;
                    case moduleTabs[5].active:
                        return <DocFieldTab onCloseTab={onCloseTab} />;
                    case starterTab:
                        return <DocStarterTab />;
                    default:
                        return null;
                    }
                })()}
            </div>

        </PageWithTabsLayout>
    );
};

export default DocumentooborotPage;
