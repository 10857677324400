import { useEffect, useRef, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { IUser } from '../../../assets/interfaces/interfaces';
import '../../../assets/styles/admin/adminEdit.scss';
import CloseCrossSvg from '../../../assets/svgs/CloseCrossSvg';
import PlusSvg from '../../../assets/svgs/PlusSvg';
import UserAvatarDefault from '../../../assets/svgs/UserAvatarDefault';
import InputFiled from '../../html/InputFiled';
import RadioComponent from '../../html/RadioComponent';

interface IProps {
    onSave: Function;
    data?: IUser;
}
interface IErrors {
    login: boolean,
    password: boolean,
    lastname: boolean,
    name: boolean,
    email: boolean,
    birthday: boolean,
}
const AdminUserEdit = ({ onSave, data }: IProps) => {
    const imgPreviewRef = useRef<any>(null);
    const [imgSelected, setImgSelected] = useState<boolean>(false);
    const [phones, setPhones] = useState<Array<string>>(['']);
    const [jobPhones, setJobPhones] = useState<Array<string>>([]);
    const [login, setLogin] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [lastname, setLastname] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [middlename, setMiddlename] = useState<string>('');
    const [gender, setGender] = useState<number | string>(0);
    const [email, setEmail] = useState<string>('');
    const [positions, setPositions] = useState<Array<string>>(['Уволенные', 'Разработчик']);
    const [position, setPosition] = useState<number | string>(0);
    const [link, setLink] = useState<string>('');
    const [birthday, setBirthday] = useState<string>('');
    const [errors, setErrors] = useState<IErrors>({
        login: false,
        password: false,
        lastname: false,
        name: false,
        email: false,
        birthday: false,
    });

    const setImg = (file: File) => {
        setImgSelected(true);
        const fr = new FileReader();
        fr.onload = (e) => {
            const result = e.target?.result;
            if (result) {
                imgPreviewRef.current.src = result; // Arrow function also works
            }
        };
        fr.readAsDataURL(file);
    };

    const clearErrors = () => {
        setErrors({
            login: false,
            password: false,
            lastname: false,
            name: false,
            email: false,
            birthday: false,
        });
    };
    const validateEmail = () => email.match(/[^\s@]+@[^\s@]+\.[^\s@]+/gi) === null;
    const onSend = () => {
        clearErrors();
        if (login.length === 0) {
            setErrors((prevstate: IErrors) => ({ ...prevstate, login: true }));
            return;
        }
        if (password.length === 0) {
            setErrors((prevstate: IErrors) => ({ ...prevstate, password: true }));
            return;
        }
        if (lastname.length === 0) {
            setErrors((prevstate: IErrors) => ({ ...prevstate, lastname: true }));
            return;
        }
        if (name.length === 0) {
            setErrors((prevstate: IErrors) => ({ ...prevstate, name: true }));
            return;
        }
        if (validateEmail()) {
            setErrors((prevstate: IErrors) => ({ ...prevstate, email: true }));
            return;
        }
        if (birthday.length === 0) {
            setErrors((prevstate: IErrors) => ({ ...prevstate, birthday: true }));
            return;
        }
        onSave();
    };
    const addPhones = (phone: Array<string>, setPhone: Function) => {
        if (phone.length) {
            if (phone[phone.length - 1].length === 14) {
                setPhone((prev: Array<string>) => [...prev, '']);
            }
        } else {
            setPhone((prev: Array<string>) => [...prev, '']);
        }
    };
    useEffect(() => {
        const user: IUser | undefined = data;
        if (user) {
            if (user.phones) setPhones([user.phones]);
            if (user.job_phones) setJobPhones([user.job_phones]);
            if (user.login) setLogin(user?.login);
            if (user.lastname) setLastname(user.lastname);
            if (user.name) setName(user.name);
            if (user.middlename) setMiddlename(user.middlename);
            if (user.email) setEmail(user.email);
            if (user.position_id) setPosition(user.position_id);
            if (user.vks_link) setLink(user.vks_link);
            if (user.birthday) setBirthday(user.birthday);
            if (user.gender_id) setGender(user.gender_id);
        }
    }, [data]);

    return (
        <div className="adminedit">
            <div className="adminedit__col">
                <div className="adminedit__field">
                    <span className="adminedit__title">
                        <span className="text">Логин:</span>
                        <span className="required">*</span>
                    </span>
                    <InputFiled
                        id="login"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLogin(e.target.value)}
                        value={login}
                        className={`adminedit__input ${errors.login ? 'error' : ''}`}
                    />
                </div>
                <div className="adminedit__field">
                    <span className="adminedit__title">
                        <span className="text auto">Аватар:</span>
                        <span className="avatar">
                            <span style={{ display: imgSelected ? 'none' : 'block' }}>
                                <UserAvatarDefault />
                            </span>
                            <img style={{ display: imgSelected ? 'block' : 'none' }} ref={imgPreviewRef} alt="Not loaded" />
                        </span>
                    </span>
                    <input type="file" accept="image/png, image/gif, image/jpeg" onChange={(e: any) => setImg(e.target.files[0])} className="adminedit__input noborder" />
                </div>
            </div>
            <div className="adminedit__col">
                <div className="adminedit__field">
                    <span className="adminedit__title">
                        <span className="text">Пароль:</span>
                        <span className="required">*</span>
                    </span>
                    <InputFiled
                        id="password"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                        value={password}
                        className={`adminedit__input ${errors.password ? 'error' : ''}`}
                    />
                </div>
            </div>
            <div className="adminedit__col">
                <div className="adminedit__field">
                    <span className="adminedit__title">
                        <span className="text">Фамилия:</span>
                        <span className="required">*</span>
                    </span>
                    <InputFiled
                        id="lastname"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastname(e.target.value)}
                        value={lastname}
                        className={`adminedit__input ${errors.lastname ? 'error' : ''}`}
                    />
                </div>
            </div>
            <div className="adminedit__col">
                <div className="adminedit__field">
                    <span className="adminedit__title">
                        <span className="text">Имя:</span>
                        <span className="required">*</span>
                    </span>
                    <InputFiled
                        id="name"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                        value={name}
                        className={`adminedit__input ${errors.name ? 'error' : ''}`}
                    />
                </div>
            </div>
            <div className="adminedit__col">
                <div className="adminedit__field">
                    <span className="adminedit__title">
                        <span className="text">Отчество:</span>
                        <span className="required" />
                    </span>
                    <InputFiled
                        id="middlename"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMiddlename(e.target.value)}
                        value={middlename}
                        className="adminedit__input"
                    />
                </div>
            </div>
            <div className="adminedit__col">
                <div className="adminedit__field">
                    <span className="adminedit__title">
                        <span className="text">Email:</span>
                        <span className="required">*</span>
                    </span>
                    <InputFiled
                        id="email"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                        value={email}
                        className={`adminedit__input ${errors.email ? 'error' : ''}`}
                    />
                </div>
            </div>
            <div className="adminedit__col">
                <div className="adminedit__field">
                    <span className="adminedit__title">
                        <span className="text">Группа:</span>
                        <span className="required" />
                    </span>
                    <select
                        className="adminedit__input group"
                        value={position}
                        onChange={(e: any) => setPosition(e.target.value)}
                        name="position_id"
                        id="position_id"
                    >
                        {positions.map((pos: string, i: number) => (
                            <option value={i}>{pos}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="adminedit__col">
                <div className="adminedit__field">
                    <span className="adminedit__title">
                        <span className="text">Должность:</span>
                        <span className="required" />
                    </span>
                    <InputFiled
                        id="position"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { }}
                        value=""
                        className="adminedit__input"
                    />
                </div>
            </div>
            <div className="adminedit__col">
                <div className="adminedit__field">
                    <span className="adminedit__title">
                        <span className="text">Телефоны личные:</span>
                        <span className="required" />
                    </span>
                    <div className="phones">
                        {phones.map((phone: string, index: number) => (
                            <div className="phone">
                                <span className="seven">+7</span>
                                <IMaskInput
                                    mask="(000)000-00-00"
                                    className="adminedit__input"
                                    placeholder="(123)456-78-90"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhones([
                                        ...phones.slice(0, index),
                                        e.target.value,
                                        ...phones.slice(index + 1),
                                    ])}
                                    value={phones[index]}
                                />
                                <span className="dashes">-</span>
                                <IMaskInput
                                    mask="+7(000)000-00-00"
                                    className="adminedit__input second_number"
                                    placeholder="(123)456-78-90"
                                />
                                <button
                                    type="button"
                                    className="delete"
                                    onClick={() => setPhones((prev) => prev.filter((value, i) => i !== index))}
                                >
                                    <CloseCrossSvg />
                                </button>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
            <div className="plus">
                {' '}
                <span onClick={() => { addPhones(phones, setPhones); }}><PlusSvg /></span>
                {' '}
            </div>
            <div className="adminedit__col">
                <div className="adminedit__field">
                    <span className="adminedit__title">
                        <span className="text">Телефоны рабочие:</span>
                        <span className="required" />
                    </span>
                    <div className="phones">
                        {jobPhones.map((phone: string, index: number) => (
                            <div className="phone">
                                <span className="seven">+7</span>
                                <IMaskInput
                                    mask="(000)000-00-00"
                                    className="adminedit__input"
                                    placeholder="(123)456-78-90"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setJobPhones([
                                        ...jobPhones.slice(0, index),
                                        e.target.value,
                                        ...jobPhones.slice(index + 1),
                                    ])}
                                    value={jobPhones[index]}
                                />
                                <span className="dashes">-</span>
                                <IMaskInput
                                    mask="+7(000)000-00-00"
                                    className="adminedit__input second_number"
                                    placeholder="(123)456-78-90"
                                />
                                <button
                                    type="button"
                                    className="delete"
                                    onClick={() => setJobPhones((prev) => prev.filter((value, i) => i !== index))}
                                >
                                    <CloseCrossSvg />
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="plus">
                <span onClick={() => { addPhones(jobPhones, setJobPhones); }}>
                    <PlusSvg />
                </span>

            </div>
            <div className="adminedit__col">
                <div className="adminedit__field">
                    <span className="adminedit__title">
                        <span className="text">Дата рождения / Пол:</span>
                        <span className="required">*</span>
                    </span>
                    <input
                        type="date"
                        value={birthday}
                        onChange={(e) => setBirthday(e.target.value)}
                        className={`adminedit__input ${errors.birthday ? 'error' : ''}`}
                    />
                    <span className="slash">/</span>
                    <RadioComponent
                        id="sex"
                        checked={gender === 1}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setGender(Number(e.target.value)); }}
                    />
                    <span className="sex">- мужской</span>
                    <RadioComponent
                        id="sex"
                        checked={gender === 0}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setGender(Number(e.target.value)); }}
                    />
                    <span className="sex">- женский</span>
                </div>
            </div>
            <div className="adminedit__col">
                <div className="adminedit__field">
                    <span className="adminedit__title">
                        <span className="text">Ссылка на комнату ВКС:</span>
                        <span className="required" />
                    </span>
                    <InputFiled
                        id="link"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLink(e.target.value)}
                        value={link}
                        className="adminedit__input"
                    />
                </div>
            </div>
            <div className="adminedit__send_btn">
                <button type="button" onClick={onSend}>Отправить</button>
            </div>
        </div>

    );
};

export default AdminUserEdit;
