import { IOpenFile, ITaskCommentFile, ITaskFile } from '../assets/interfaces/interfaces';
import { HOME_TAB } from './tabs';

export const getFileLink = (task_id: number, file_path: string, comment_id?: number) => `https://develop1.transmashpribor.ru/storage/tasks/${task_id}${comment_id ? `/comments/${comment_id}` : ''}/files/${file_path}`;
export const hrefToFile = (task_id: number, file_path: string, comment_id?: number) => {
    window.open(getFileLink(task_id, file_path, comment_id), '_blank');
};
export const checkImage = (file_path: string) => {
    const format = file_path.split('.')[file_path.split('.').length - 1].toLocaleUpperCase();
    let isImg = false;
    // 'PDF',
    ['JPEG', 'JPG', 'GIF', 'PNG', 'WebP', 'HEIF', 'HEIC', 'TIFF', 'BMP', 'PSD', 'RAW', 'EPS', 'AI', 'INDD', 'DWG'].forEach((val) => {
        if (format === val) {
            isImg = true;
        }
    });
    return isImg;
};
export const onShowFileSlider = (task_id: number, files: ITaskFile[] | ITaskCommentFile[], file: ITaskFile | ITaskCommentFile, file_index: number, task_comment_id?: number): IOpenFile[] => {
    const newFiles: IOpenFile[] = [];
    files.forEach((file) => {
        // if (checkImage(file.path)) {
        const newFile: IOpenFile = {
            task_id, file_id: file.id, path: file.path, task_comment_id,
        };
        newFiles.push(newFile);
        // }
    });
    const firstFile = {
        task_id, file_id: newFiles[0].file_id, path: newFiles[0].path, task_comment_id,
    };
    newFiles[0] = {
        task_id, file_id: file.id, path: file.path, task_comment_id,
    };
    newFiles[file_index] = firstFile;
    console.log('newFiles[file_index]', newFiles[file_index]);
    console.log('file_index', file_index);
    return newFiles;
};
export const getRouteTabId = (get_route:string) => {
    const splittedRoute = get_route.split('/');
    if (splittedRoute) {
        const lastSplittedRoute = splittedRoute[splittedRoute.length - 1];
        if (lastSplittedRoute === ':id') {
            splittedRoute.shift();
            splittedRoute[splittedRoute.length - 1] = String(0);
            if (splittedRoute[1] === ':tab') {
                splittedRoute[1] = HOME_TAB;
            }
            const route = `/${splittedRoute.join('/')}`;
            return route;
        }
    }
    return (get_route);
};
export function formatDateToISO(date: Date) {
    // Get the year, month, day, hours, minutes, seconds, and milliseconds
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    const milliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');

    const fractionalSeconds = `${milliseconds}000`;

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${fractionalSeconds}Z`;
}
export function formatDateYMD(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}
export function formatDateYMDHMS(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
