import { useEffect, useState } from 'react';
import { IMultySelectSearch } from '../../../../../assets/interfaces/interfaces';
import { IDocTableTemplateData, IDocTemplateTable } from '../../../../../assets/interfaces/modules/doc/tab/doc_template_interface';
import { addTemplateAPI, deleteTemplatesAPI, getTemplatesAPI } from '../../api/documentAPI';
import { docCheckTemplateKeys } from '../../config/funcs';
import DocHandbookLayout from '../layouts/DocHandbookLayout';

interface IProps {
    onCloseTab: () => void;
}
const DocTemplateTab = ({ onCloseTab }: IProps) => {
    const [tableData, setTableData] = useState<IDocTemplateTable>({
        data: [],
        currentPage: 1,
        todosPerPage: 10,
    });
    const [selectModalValues, setSelectModalValues] = useState<IMultySelectSearch[]>([
        {
            id: 1, title: 'city_doc', subtitle: 'Город', show: true, selected: false,
        },
        {
            id: 2, title: 'number_doc', subtitle: 'Порядковый номер документа', show: true, selected: false,
        },
        {
            id: 3, title: 'date_doc_start', subtitle: 'Первая дата', show: true, selected: false,
        },
        {
            id: 4, title: 'solution', subtitle: 'Решение', show: true, selected: false,
        },
        {
            id: 5, title: 'info', subtitle: 'Данные', show: true, selected: false,
        },
        {
            id: 6, title: 'situation', subtitle: 'Ситуация', show: true, selected: false,
        },
    ]);
    const [createRow, setCreateRow] = useState<IDocTableTemplateData>({
        array_input: '', file: null, full_name: '', id: -1, name: '', path: null, created_at: '', updated_at: '',
    });

    const onChangeTablePage = (page: number) => {
        const newData = { ...tableData };
        newData.currentPage = page;
        setTableData(newData);
    };
    const clearCreateRow = () => {
        setCreateRow({
            array_input: '', file: null, full_name: '', id: -1, name: '', path: null, created_at: '', updated_at: '',
        });
    };
    async function createTemplate() {
        const form = new FormData();
        form.append('name', createRow.name);
        form.append('full_name', createRow.full_name);
        form.append('array_input', createRow.array_input);
        if (createRow.file) {
            form.append('file', createRow.file);
        }
        const response = await addTemplateAPI(form);
        if (response.status === 200) {
            clearCreateRow();
        }
    }
    const onAddRow = (newData: IDocTemplateTable) => {
        createTemplate();
        console.log('newData', newData);
        setTableData(newData);
    };
    const onDeleteRow = async (newData: IDocTemplateTable, id:number) => {
        deleteTemplatesAPI(id);
        setTableData(newData);
    };
    const onChangeFiles = (index: number, files: FileList | null) => {
        if (files) {
            const file = files[0];
            if (index === -1) {
                const newData = { ...createRow };
                newData.file = file;
                newData.path = file.name;
                setCreateRow(newData);
            } else if (index > -1) {
                const newData = { ...tableData };
                newData.data[index].file = file;
                newData.data[index].path = file.name;
                setTableData(newData);
            }
        }
    };

    const onChangeCreateRow = (key: keyof IDocTableTemplateData, value: string) => {
        const newData = { ...createRow };
        if (docCheckTemplateKeys(key)) {
            newData[key] = value;
            setCreateRow(newData);
        }
    };

    const onChangeDataRow = (index: number, key: keyof IDocTableTemplateData, value: string) => {
        const newData = { ...tableData };
        if (docCheckTemplateKeys(key)) {
            newData.data[index][key] = value;
            setTableData(newData);
        }
    };
    const onSelectModal = (values: IMultySelectSearch[], index: number) => {
        let newFields = '';
        values.forEach((field) => { if (field.selected === true) newFields += `${field.title}, `; });
        newFields = newFields.slice(0, (newFields.length - 1));
        if (index !== -1) {
            const newData = { ...tableData };
            newData.data[index].array_input = newFields;
            setTableData(newData);
        } else {
            const newCreateRow = { ...createRow };
            newCreateRow.array_input = newFields;
            setCreateRow(newCreateRow);
        }
    };
    function setSelectedModalValue(selectedStrign: string) {
        if (selectedStrign.length) {
            selectModalValues.forEach((r) => {
                r.selected = false;
            });
            selectModalValues.forEach((val, i) => {
                if (selectedStrign.includes(val.title)) {
                    const newModalValues = [...selectModalValues];
                    newModalValues[i].selected = true;
                    setSelectModalValues(newModalValues);
                }
            });
        }
    }
    const onSelectModalOpen = (index: number) => {
        if (index === -1) {
            setSelectedModalValue(createRow.array_input);
        } else {
            setSelectedModalValue(tableData.data[index].array_input);
        }
    };
    function setData(data: any) {
        const newData = { ...tableData };
        newData.data = data;
        setTableData(newData);
    }
    async function getTemplates() {
        const { data } = await getTemplatesAPI();
        console.log('data', data);
        setData(data.data);
    }
    useEffect(() => {
        getTemplates();
    }, []);
    return (
        <DocHandbookLayout
            title="Справочник шаблонов"
            tableTemplate="template"
            tableData={tableData}
            onChangeTablePage={onChangeTablePage}
            createRow={createRow}
            onChangeCreateRow={onChangeCreateRow}
            onChangeDataRow={onChangeDataRow}
            onAddRow={onAddRow}
            onDeleteRow={onDeleteRow}
            onChangeFilesRow={onChangeFiles}
            onSelectModalOpen={onSelectModalOpen}
            onSelectModalValue={onSelectModal}
            selectModalProps={{
                title: 'Справочник полей', multiple: true, values: selectModalValues, link: { text: 'Справочник полей', url: 'https://develop.transmashpribor.ru/agreement.html/columns' },
            }}
            getDeleteName={(index) => tableData.data[index].full_name}
            tableHeaders={[
                { text: 'Название типа документа' },
                { text: 'Полное название тип' },
                { text: 'Массив полей' },
                { text: 'Шаблон документа' },
                { text: '' },
            ]}
            onCloseTab={onCloseTab}
        />
    );
};

export default DocTemplateTab;
