import { useState } from 'react';
import {
    IMultySelectSearch, IOpenFile, ITextActive,
} from '../../../../../assets/interfaces/interfaces';
import InputLabelField from '../../../../../components/html/InputLabelFiled';
import SelectLabelField from '../../../../../components/html/SelectLabelField';
import DraggableModal from '../../../../../components/Modals/DraggableModal';
import EditModal from '../../../../../components/Modals/EditModal';
import RowModule from './RowModals';

interface IProps {
    openFiles: IOpenFile[];
    onConfirmNoDenyAgreement: Function;
    onConfirmYesDenyAgreement: Function;
    onChangeOpenFiles: Function;
    selectUserModal: boolean;
    sureDenyAgreementModal: ITextActive;
    onCloseSelectUserModal: Function;
    onSaveSelectUserModal: Function;
    onChangeDenyAgreement: Function;
}
const DocModals = ({
    onChangeDenyAgreement, sureDenyAgreementModal, selectUserModal, onCloseSelectUserModal, onSaveSelectUserModal, onChangeOpenFiles, onConfirmNoDenyAgreement, onConfirmYesDenyAgreement, openFiles,
}: IProps) => {
    const [selectedUsers, setSelectedUsers] = useState<IMultySelectSearch[]>([
        {
            id: 1, title: 'Ермилов Дмитрий Олегович ', subtitle: 'ermilov@gmail.com', show: true, selected: false,
        },
        {
            id: 2, title: 'Балаев Валентин Романович ', subtitle: 'st1ll@odr.su', show: true, selected: false,
        },
    ]);

    const [spaceZoneClicked, setSpaceZoneClicked] = useState<boolean>(false);

    function onSelectUsers(users: IMultySelectSearch[]) {
        setSelectedUsers(users);
    }

    return (
        <>

            {openFiles.length ? (
                <DraggableModal
                    files={openFiles}
                    onClose={() => onChangeOpenFiles([])}
                />
            ) : ''}
            <EditModal
                show={sureDenyAgreementModal.active}
                onCloseModal={() => onConfirmNoDenyAgreement()}
                onClickSave={() => onConfirmYesDenyAgreement()}
                title="Отмена согласования"
            >
                <div className="row">
                    <InputLabelField
                        id="reasonDeny"
                        label="Причина отмены"
                        maxLength={250}
                        onChange={(e) => { onChangeDenyAgreement(e.target.value); }}
                        value={sureDenyAgreementModal.text}
                    />
                </div>
            </EditModal>
            <EditModal
                show={selectUserModal}
                onCloseModal={() => onCloseSelectUserModal()}
                onClickSave={() => onSaveSelectUserModal(selectedUsers)}
                confirmBtnText="Добавить задачу"
            >
                <RowModule
                    title="Список пользователей"
                    link={{ text: 'Список пользователей', url: 'https://develop.transmashpribor.ru/adminka.html?module=users' }}
                >
                    <SelectLabelField
                        id="select_users"
                        placeholder="Поиск..."
                        values={selectedUsers}
                        onChangeValues={(val: IMultySelectSearch[]) => onSelectUsers(val)}
                        spaceZoneClicked={spaceZoneClicked}
                        multiple
                    />
                </RowModule>

            </EditModal>

        </>
    );
};
export default DocModals;
