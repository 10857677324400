import { useState } from 'react';
import { IAddToArchiveResponse, IDocTableData } from '../../../../../../assets/interfaces/modules/doc/doc_interface';
import ArchiveSvg from '../../../../../../assets/svgs/ArchiveSvg';
import ExitSvg from '../../../../../../assets/svgs/ExitSvg';
import FileDeleteSvg from '../../../../../../assets/svgs/FileDeleteSvg';
import PaperAirplaneSvg from '../../../../../../assets/svgs/PaperAirplaneSvg';
import ThreeDotsSvg from '../../../../../../assets/svgs/ThreeDotsSvg';
import TrashSvg from '../../../../../../assets/svgs/TrashSvg';
import DocTableIcon from './DocTableIcon';
import s from './Hover_icons.module.scss';

interface IProps {
    td: IDocTableData;
    index: number;
    onAddToArchive: (response: IAddToArchiveResponse) => void;
    onDeleteDoc: (td: IDocTableData) => void;
    onShowFileHandler: (td: IDocTableData) => void;
    onDenyAgreement: (td: IDocTableData) => void;
    isArchive:boolean;
}

const DocTableHoverIcons = ({
    td, isArchive, index, onAddToArchive, onDenyAgreement, onDeleteDoc, onShowFileHandler,
}: IProps) => {
    const [onHover, setOnHover] = useState<boolean>(false);
    const onClickShowFile = () => {
        onShowFileHandler(td);
    };
    return (
        <div className={`${s.container} ${onHover ? s.show : ''}`}>
            <div
                onMouseLeave={() => setOnHover(false)}
                onMouseEnter={() => setOnHover(true)}
            >
                <DocTableIcon>
                    <ThreeDotsSvg />
                </DocTableIcon>
            </div>
            <div
                className={s.hover_icons}
                onMouseLeave={() => setOnHover(false)}
                onMouseEnter={() => setOnHover(true)}
            >

                {!isArchive ? (
                    <>
                        <DocTableIcon
                            index={index}
                            disabled
                        >
                            <PaperAirplaneSvg />
                        </DocTableIcon>
                        <DocTableIcon
                            index={index}
                            onClickValue={onDenyAgreement}
                            disabled
                            value={td}
                        >
                            <FileDeleteSvg />
                        </DocTableIcon>
                        <DocTableIcon
                            index={index}
                            onClickValue={onDeleteDoc}
                            disabled
                            value={td}
                        >
                            <TrashSvg />
                        </DocTableIcon>
                    </>
                ) : ''}

                <DocTableIcon
                    onClickValue={onClickShowFile}
                    disabled
                    value={td.file}
                >
                    <ExitSvg />
                </DocTableIcon>
                <DocTableIcon
                    onClickValue={onAddToArchive}
                    value={{ td, isArchive }}
                    disabled
                >
                    <ArchiveSvg />
                </DocTableIcon>
                <div className={s.hover_space} />
            </div>
        </div>

    );
};
export default DocTableHoverIcons;
