import {
    useState,
} from 'react';
import {
    IEditTask, IOpenFile, ITaskComment, ITaskCommentFile,
} from '../../../../../assets/interfaces/interfaces';
import '../../../../../assets/styles/user/components/edit/task_comments.scss';
import FileTaskDeleteSvg from '../../../../../assets/svgs/FileTaskDeleteSvg';
import FileTaskIconSvg from '../../../../../assets/svgs/FileTaskIconSvg';
import FileTaskSvg from '../../../../../assets/svgs/FileTaskSvg';
import UserAvatarDefault from '../../../../../assets/svgs/UserAvatarDefault';
import DraggableModal from '../../../../../components/Modals/DraggableModal';
import { checkImage, hrefToFile, onShowFileSlider } from '../../../../../utils/func';

interface ITaskEdit {
    task: IEditTask,
    onEditComment: Function,
    onDeleteComment: Function,
    onAddComment: Function,
    onChangeComment: Function,
}
const TaskComments = ({
    task, onEditComment, onDeleteComment, onAddComment, onChangeComment,
}: ITaskEdit) => {
    const [commentText, setCommentText] = useState<string>('');
    const [commentFiles, setCommentFiles] = useState<any>();
    const [newFiles, setNewFiles] = useState<FileList>();
    const [openFiles, setOpenFiles] = useState<IOpenFile[]>([]);

    const onAddFilesToComment = (files: FileList | null) => {
        if (files) {
            setNewFiles(files);
            setCommentFiles(files);
        }
    };
    const clearInputFile = () => {
        setNewFiles(undefined);
    };
    const addCommentHandler = (task: IEditTask, commentText: string) => {
        if (commentText.length === 0) {
            return;
        }
        const formData = new FormData();
        formData.append('text', commentText);
        if (newFiles) {
            for (let i = 0; i < newFiles.length; i++) {
                const file = newFiles[i];
                formData.append(String(i), file);
            }
        }
        clearInputFile();
        setCommentText('');
        onAddComment(task, formData, commentText);
    };
    const onClickAddFiles = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.multiple = true;
        input.click();

        input.addEventListener('change', (e) => {
            const target = e.target as HTMLInputElement;
            const { files } = target;

            if (files) {
                setNewFiles(files);
            }
        });
    };
    const editCommentHandler = (comment_index: number) => {
        if (task.task.comments[comment_index].edit === true) {
            const formData = new FormData();
            formData.append('text', task.task.comments[comment_index].text);
            formData.append('task_id', String(task.task.id));
            onEditComment(task, comment_index, formData);
        } else {
            onEditComment(task, comment_index);
        }
    };
    const onShowFileHandler = (files: ITaskCommentFile[], file: ITaskCommentFile, index: number, comment_id: number) => {
        setOpenFiles(onShowFileSlider(task.task.id, files, file, index, comment_id));
    };
    const onClickCommentFile = (comment: ITaskComment, file: ITaskCommentFile, index:number) => {
        if (comment.files) {
            onShowFileHandler(comment.files, file, index, comment.id);
        }
    };
    return (
        <>
            {openFiles.length ? (
                <DraggableModal
                    files={openFiles}
                    onClose={() => setOpenFiles([])}
                />
            ) : ''}
            <div className="comment_tab">
                <div className="comment_container">
                    {task.task.archived_at !== true && (
                        <div className="create_comment">
                            <span className="avatar">
                                <UserAvatarDefault />
                            </span>
                            <div className="comment_form">
                                <textarea
                                    placeholder="Ввести комментарий..."
                                    className="comment_textarea"
                                    rows={3}
                                    contentEditable
                                    value={commentText}
                                    onChange={(e) => setCommentText(e.target.value)}
                                />
                                <div className="bottom">
                                    <button
                                        type="button"
                                        onClick={() => { addCommentHandler(task, commentText); }}
                                        className="btn_add"
                                    >
                                        Добавить
                                    </button>
                                    <div className="right">

                                        <div className="files">
                                            {newFiles?.length ? `Выбранно файлов: ${newFiles.length}` : ''}
                                        </div>
                                        <div className="icons">
                                            <span className="icon" onClick={(e) => onClickAddFiles()}>
                                                <FileTaskSvg />
                                            </span>

                                            {newFiles?.length ? (
                                                <span className="icon" onClick={(e) => clearInputFile()}>
                                                    <FileTaskDeleteSvg />
                                                </span>
                                            ) : ''}
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    )}

                    {task.task.comments.length ? (
                        <div className="comments">
                            {task.task.comments.map((comment, index) => (
                                <div className="comment_wrapper">
                                    <span className="avatar">
                                        <UserAvatarDefault />
                                    </span>
                                    <div className="content">
                                        <div className="comment">
                                            {comment.edit ? (
                                                <textarea
                                                    value={comment.text}
                                                    onChange={(e) => onChangeComment(task, index, e.target.value)}
                                                    className="comment_input"
                                                />
                                            ) : (
                                                <>
                                                    <span className="author">{comment.user_id}</span>
                                                    <span className="text">{comment.text}</span>
                                                </>
                                            )}

                                        </div>
                                        {comment.files && (
                                            <div className="files">
                                                {comment.files.map((file, index) => {
                                                    if (checkImage(file.path)) {
                                                        return (
                                                            <div
                                                                className="file"
                                                                onClick={() => {
                                                                    onClickCommentFile(comment, file, index);
                                                                }}
                                                                key={file.id} // Add a key prop for list items
                                                            >
                                                                <div className="icon">
                                                                    <FileTaskIconSvg />
                                                                </div>
                                                                <div className="info">
                                                                    <div className="title">{file.path}</div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                    return (
                                                        <div
                                                            className="file"
                                                            onClick={() => hrefToFile(task.task.id, file.path, comment.id)}
                                                            key={file.id} // Add a key prop for list items
                                                        >
                                                            <div className="icon">
                                                                <FileTaskIconSvg />
                                                            </div>
                                                            <div className="info">
                                                                <div className="title">{file.path}</div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>

                                        )}

                                        <div className="actions">
                                            <span className="action" onClick={() => editCommentHandler(index)}>Редактировать</span>
                                            <span className="dot">•</span>
                                            <span className="action" onClick={() => onDeleteComment(task, index)}>Удалить</span>
                                            <span className="dot">•</span>
                                            <span className="date">{comment.created_at}</span>
                                        </div>
                                    </div>
                                </div>
                            )).reverse()}

                        </div>
                    ) : (
                        <span className="empty">Комметариев нет</span>
                    )}

                </div>

            </div>
        </>

    );
};

export default TaskComments;
