import { IActions, IMultySelectSearch } from '../../../../../assets/interfaces/interfaces';
import { IDocCoordinationData, IDocDoneData, IDocSendData } from '../../../../../assets/interfaces/modules/doc/doc_interface';
import ActionsButtons from '../../../../../components/html/ActionsButtons';
import InputLabelField from '../../../../../components/html/InputLabelFiled';
import SelectLabelField from '../../../../../components/html/SelectLabelField';
import RowModule from './RowModals';

interface IProps {
    onChangeDoneData: Function;
    onChangeCoordinationData: Function;
    setTheme: Function;
    onChangeFiles: Function;
    onActiveDo: Function;
    onChangeSendData: Function;
    onChangeTypeDoc: (id: number) => void;
    onClickSpaceZone: () => void;
    theme: string;
    spaceZoneClicked: boolean;
    sendDocData: IDocSendData;
    sendCoordinationData: IDocCoordinationData;
    doneDocData: IDocDoneData;
    actions: IActions[];
    dropDownFiles: File[];
    selectedDocs: IMultySelectSearch[];
    onSelectDoc: (vals: IMultySelectSearch[], index: number) => void;
}
const typeDocLinks = { text: 'Справочник шаблонов', url: 'https://develop.transmashpribor.ru/agreement.html/types_doc' };
const DocAddModalContent = ({
    dropDownFiles,
    onClickSpaceZone,
    onChangeTypeDoc,
    sendCoordinationData,
    onChangeCoordinationData,
    onChangeDoneData,
    actions,
    setTheme,
    sendDocData,
    doneDocData,
    onActiveDo,
    onChangeSendData,
    onChangeFiles,
    theme,
    spaceZoneClicked,
    selectedDocs,
    onSelectDoc,
}: IProps) => (
    <>
        <RowModule title="Тема" onClick={onClickSpaceZone}>
            <InputLabelField
                id="theme"
                label="Тема"
                maxLength={250}
                onChange={(e) => setTheme(e.target.value)}
                value={theme}
            />
        </RowModule>
        {/* <RowModule
                title="Файл"
            >
                <FileDropdown
                    files={dropDownFiles}
                    onChangeFiles={onChangeFiles}
                    acceptTypes={['application/pdf']}
                />
            </RowModule> */}
        <RowModule
            title="Тип документа"
            link={typeDocLinks}
        >
            <SelectLabelField
                id="type_doc"
                placeholder="Поиск..."
                values={selectedDocs}
                onChangeValues={(val: IMultySelectSearch[], index: number) => onSelectDoc(val, index)}
                spaceZoneClicked={spaceZoneClicked}
                multiple={false}
            />
        </RowModule>
        <RowModule
            title="Действия"
            onClick={onClickSpaceZone}
        >
            <ActionsButtons
                actions={actions}
                onActive={onActiveDo}
            />
        </RowModule>

        {selectedDocs.length > 2 ? (
            <>
                {selectedDocs[0].selected ? (
                    <div className="row">
                        <InputLabelField
                            id="doc_data"
                            title="Опциональные данные"
                            label="Данные"
                            maxLength={250}
                            onChange={(e) => onChangeCoordinationData(e.target.value, 'data')}
                            value={sendCoordinationData.data}
                        />
                    </div>
                ) : ''}
                {selectedDocs[1].selected ? (
                    <>
                        <div className="row">
                            <InputLabelField
                                id="doc_number"
                                title="Опциональные данные"
                                label="Порядковый номер"
                                maxLength={250}
                                onChange={(e) => onChangeSendData(e.target.value, 'number')}
                                value={sendDocData.number}
                            />
                        </div>
                        <div className="row">
                            <InputLabelField
                                id="doc_city"
                                label="Город"
                                maxLength={250}
                                onChange={(e) => onChangeSendData(e.target.value, 'city')}
                                value={sendDocData.city}
                            />
                        </div>
                        <div className="row">
                            <InputLabelField
                                id="doc_first_date"
                                label="Первая дата"
                                isDate
                                onChange={(e) => onChangeSendData(e.target.value, 'firstDate')}
                                value={sendDocData.firstDate}
                            />
                        </div>
                        <div className="row">
                            <InputLabelField
                                id="doc_second_date"
                                label="Вторая дата"
                                isDate
                                onChange={(e) => onChangeSendData(e.target.value, 'secondDate')}
                                value={sendDocData.secondDate}
                            />
                        </div>
                    </>
                ) : ''}
                {selectedDocs[2].selected ? (
                    <>
                        <div className="row">
                            <InputLabelField
                                id="doc_situation"
                                title="Опциональные данные"
                                label="Ситуация"
                                maxLength={250}
                                onChange={(e) => onChangeDoneData(e.target.value, 'situation')}
                                value={doneDocData.situation}
                            />
                        </div>
                        <div className="row">
                            <InputLabelField
                                id="doc_data"
                                label="Данные"
                                maxLength={250}
                                onChange={(e) => onChangeDoneData(e.target.value, 'data')}
                                value={doneDocData.data}
                            />
                        </div>
                        <div className="row">
                            <InputLabelField
                                id="doc_solution"
                                label="Решение"
                                maxLength={250}
                                onChange={(e) => onChangeDoneData(e.target.value, 'solution')}
                                value={doneDocData.solution}
                            />
                        </div>
                    </>
                ) : ''}
            </>
        ) : null}

    </>
);
export default DocAddModalContent;
