import { sendRepeatRequest } from '../../../../http/userAPI';
import { getCookie } from '../../../../utils/cookies';
import { $document, $users } from '../config/backend';

const headers = {
    headers: {
        Authorization: `Bearer ${getCookie('access_token')}`,
    },
};
export const addDocumentAPI = async (data: FormData, repeat?: boolean) => {
    const response = await $document.post('documents', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }).catch((e) => e);
    return sendRepeatRequest(response, data);
};
export const addDocCommentAPI = async (data: FormData, id: number, repeat?: boolean) => {
    const response = await $document.post(`documents/${id}/comments`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }).catch((e) => e);
    return sendRepeatRequest(response, data);
};
export const getDocumentsAPI = async () => {
    const response = await $document.get('documents', headers).catch((e) => e);
    return sendRepeatRequest(response);
};
export const getArchiveDocumentsAPI = async () => {
    const response = await $document.get('documents/archived', headers).catch((e) => e);
    return sendRepeatRequest(response);
};
export const getDocumentAPI = async (id: number) => {
    const response = await $document.get(`documents/${id}`, headers).catch((e) => e);
    return sendRepeatRequest(response);
};
export const editDocumentAPI = async (data: FormData, id: number) => {
    data.append('_method', 'patch');
    const response = await $document.post(`documents/${id}`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }).catch((e) => e);
    return sendRepeatRequest(response);
};
export const deleteDocumentAPI = async (id: number) => {
    const data = new FormData();
    data.append('_method', 'delete');
    const response = await $document.post(`documents/${id}`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }).catch((e) => e);
    return sendRepeatRequest(response);
};
export const getTemplatesAPI = async () => {
    const response = await $document.get('templates', headers).catch((e) => e);
    return sendRepeatRequest(response);
};
export const deleteTemplatesAPI = async (id: number) => {
    const form = new FormData();
    form.append('_method', 'delete');
    const response = await $document.post(`templates/${id}`, form).catch((e) => e);
    return sendRepeatRequest(response);
};
export const addTemplateAPI = async (data: FormData) => {
    const response = await $document.post('templates', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }).catch((e) => e);
    return sendRepeatRequest(response);
};
export const addSignAPI = async (data: FormData) => {
    const response = await $document.post('signs', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }).catch((e) => e);
    return sendRepeatRequest(response);
};
export const getSignsAPI = async () => {
    const response = await $document.get('signs', headers).catch((e) => e);
    return sendRepeatRequest(response);
};
export const deleteSignAPI = async (id:number) => {
    const form = new FormData();
    form.append('_method', 'delete');
    const response = await $document.delete(`signs/${id}`, headers).catch((e) => e);
    return sendRepeatRequest(response);
};
export const getRecepientsAPI = async () => {
    const response = await $document.get('documents/recipients', headers).catch((e) => e);
    return sendRepeatRequest(response);
};
export const generateDocumentAPI = async (data:FormData, id:number) => {
    const response = await $document.post(`documents/${id}/download`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }).catch((e) => e);
    return sendRepeatRequest(response);
};
export const changeStatusDocumentAPI = async (data:FormData, id:number) => {
    const response = await $document.post(`documents/${id}`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }).catch((e) => e);
    return sendRepeatRequest(response);
};
export const acceptStatusDocumentAPI = async (id:number, agreement: keyof {success: 1, fail: 1}) => {
    const form = new FormData();
    form.append('_method', 'patch');
    form.append('agreement', agreement);
    const response = await $document.post(`documents/${id}/recipients`, form, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }).catch((e) => e);
    return sendRepeatRequest(response);
};
export const getUsersDocumentAPI = async () => {
    const response = await $users.get('staff/users').catch((e) => e);
    return sendRepeatRequest(response);
};
