import { createContext, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

export const Context = createContext<any>(null);

const root = createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
    <StrictMode>
        <App />
        ,
    </StrictMode>,
);
