import { useRef, useState } from 'react';
import { ITableTemplate } from '../../../../../../../assets/interfaces/modules/doc/doc_interface';
import { IDocCreateSignData, IDocTableSignData } from '../../../../../../../assets/interfaces/modules/doc/tab/doc_sign_interface';
import PlusLigthSvg from '../../../../../../../assets/svgs/PlusLigthSvg';
import TrashSvg from '../../../../../../../assets/svgs/TrashSvg';
import { docCheckCreateIsEmpty } from '../../../../config/funcs';
import DocTabelSelectField from '../../components/DocTabelSelectField';
import DocTableFileLink from '../../components/DocTableFileLink';
import DocTableIcon from '../../components/DocTableIcon';
import DocTableTd from '../../td/DocTableTd';
import s from '../../td/Td.module.scss';

type IProps = {
    td?: IDocTableSignData;
    index: number;
    create?: boolean;
    createRow?: IDocCreateSignData;
    tableTemplate: keyof ITableTemplate;
    onAddRow?: () => void;
    onDeleteRow?: (index: number) => void;
    onSelectClick?: (index: number) => void
    onChangeFiles?: (index: number, files: FileList | null) => void
}
const DocTabelTrSignTemplate = ({
    td,
    index,
    create,
    createRow,
    tableTemplate,
    onAddRow,
    onDeleteRow,
    onChangeFiles,
    onSelectClick,
}: IProps) => {
    const inputFile = useRef<HTMLInputElement | null>(null);
    const [error, setError] = useState<{user_id:boolean, selected_file: boolean}>({
        user_id: false,
        selected_file: false,
    });
    function checkErrors(data: IDocCreateSignData) {
        const newErrors = {
            user_id: false,
            selected_file: false,
        };
        if (data.selected_user_text.length === 0) {
            newErrors.user_id = true;
        } if (data.selected_file === null) {
            newErrors.selected_file = true;
        }
        setError(newErrors);
    }
    const onAddHandler = () => {
        if (onAddRow && createRow) {
            const isEmpty = docCheckCreateIsEmpty(tableTemplate, createRow);
            checkErrors(createRow);
            if (!isEmpty) {
                if (inputFile.current) {
                    inputFile.current.value = '';
                    onAddRow();
                }
            }
        }
    };
    const onDeleteRowHander = (index:number) => {
        if (onDeleteRow) {
            if (inputFile.current) {
                inputFile.current.value = '';
                onDeleteRow(index);
            }
        }
    };
    const renderIcon = () => {
        if (create) {
            return (
                <DocTableIcon
                    index={index}
                    onVoidClick={onAddHandler}
                >
                    <PlusLigthSvg />
                </DocTableIcon>

            );
        } if (onDeleteRow) {
            return (
                <DocTableIcon
                    index={index}
                    onClickIndex={onDeleteRowHander}
                >
                    <TrashSvg />
                </DocTableIcon>
            );
        }
        return null;
    };
    const renderSignInput = () => {
        if (create && (createRow && createRow.picture.length === 0)) {
            return (
                <input
                    className={s.input_file}
                    ref={inputFile}
                    accept="image/png"
                    onChange={(e) => { if (onChangeFiles) onChangeFiles(-1, e.currentTarget.files); }}
                    type="file"
                />
            );
        }
        if (create && (createRow && createRow.picture.length > 0)) {
            return (
                <>
                    <div className={s.left}>
                        <div className={s.img_wrapper}>
                            <div className={s.img} style={{ backgroundImage: `url(${createRow.picture})` }} />
                        </div>
                    </div>
                    <div className={s.right}>
                        <input
                            onChange={(e) => { if (onChangeFiles) onChangeFiles(-1, e.currentTarget.files); }}
                            className={s.input_file}
                            ref={inputFile}
                            accept="image/png"
                            type="file"
                        />
                        <div className={s.sign}>
                            <DocTableFileLink href={createRow.picture} text={createRow.sign} />
                        </div>
                    </div>
                </>
            );
        }
        if (td && (index === 0 || index)) {
            return (
                <>
                    <div className={s.left}>
                        <div className={s.img_wrapper}>
                            <div className={s.img} style={{ backgroundImage: `url(${td.picture})` }} />
                        </div>
                    </div>
                    <div className={s.right}>
                        <input
                            onChange={(e) => { if (onChangeFiles) onChangeFiles(index, e.currentTarget.files); }}
                            className={s.input_file}
                            ref={inputFile}
                            accept="image/png"
                            type="file"
                        />
                        <div className={s.sign}>
                            <DocTableFileLink href={td.picture} text={td.picture} />
                        </div>
                    </div>
                </>
            );
        }
        return null;
    };
    const renderFioMessage = () => {
        if (create && createRow) {
            if (createRow.selected_user_text.length > 0) {
                return createRow.selected_user_text;
            }
            if ((createRow.selected_user_user_id === '')) {
                return 'Неверное или пустое значение';
            }
        }

        return td ? td.user_id : '';
    };

    return (
        <>
            <DocTableTd
                error={error.user_id}
            >
                <DocTabelSelectField
                    text={renderFioMessage()}
                    index={index}
                    onClick={(index:number) => { if (onSelectClick) onSelectClick(index); }}
                />
            </DocTableTd>
            <DocTableTd
                error={error.selected_file}
                className="sign"
            >
                {renderSignInput()}
            </DocTableTd>
            <DocTableTd className="icons">
                {renderIcon()}
            </DocTableTd>
        </>
    );
};
export default DocTabelTrSignTemplate;
