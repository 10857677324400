import {
    memo, useCallback, useRef, useState,
} from 'react';
import styles from './DropFileInput.module.scss';

import { classNames, Mods } from '../../../utils/classNames/classNames';
import { Button, ThemeButton } from '../Button/Button';
import { HStack, VStack } from '../Stack';
import {
    Text, TextAlign,
} from '../Text/Text';
import { useUpload } from './useUpload';

interface DropFileInputProps {
  className?: string;
  id: string;
  file?: string;
  folder?: string;
  text?: string;
  name: string;
  animationType: 'video' | 'audio' | 'image';
  onChange: (...event: any[]) => void;
}

export const DropFileInput = memo(({
    className, id, name, animationType, folder, file, text, onChange,
}: DropFileInputProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [isDropActive, setIsDropActive] = useState(false);

    const { uploadFile, isLoading } = useUpload(onChange, folder);

    const renderAnimation = useCallback((animationType: 'video' | 'audio' | 'image') => {
        switch (animationType) {
        case 'video':
            return (
                <span className={styles.videoAnimation} />
            );
        case 'audio':
            return (
                <span className={styles.audioAnimation} />
            );
        case 'image':
            return (
                <span className={styles.imageAnimation} />
            );
        default:
            return (
                <span className={styles.imageAnimation} />
            );
        }
    }, []);

    const onDragEnter = () => {
        setIsDropActive(true);
    };

    const chooseFile = () => {
        fileInputRef.current?.click();
    };

    const onDragLeave = () => {
        setIsDropActive(false);
    };

    const onDrop = () => {
        setIsDropActive(false);
    };

    const mods: Mods = {
        [styles.active]: isDropActive,
    };
    const getAcceptValue = (fileType: string) => {
        switch (fileType) {
        case 'file':
            return '.pdf, .doc, .docx, image/png, image/jpeg, image/jpg, image/webp, image/svg+xml';
        case 'video':
            return 'video/*';
        case 'audio':
            return 'audio/*';
        default:
            return '*';
        }
    };
    return (
        <VStack
            className={classNames(styles.DropFileInput, mods, [className])}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            max
        >
            <VStack gap="16" max align="center" className={styles.label}>
                {renderAnimation(animationType)}
                <Text
                    title={`Перетащите ${text || 'изображение'} сюда`}
                    align={TextAlign.CENTER}
                    gap="16"
                />
                <Text
                    text="или нажмите на кнопку"
                    align={TextAlign.CENTER}
                    gap="16"
                />
                {
                    isLoading ? (
                        <Text
                            text="Загрузка..."
                            align={TextAlign.CENTER}
                            gap="16"
                        />
                    )
                        : file && (
                            <Text
                                text={`Файл ${file} загрузился`}
                                isActive
                                align={TextAlign.CENTER}
                                gap="16"
                            />
                        )
                }
                <HStack justify="center" align="center" max gap="32" className={styles.buttonWrapper}>
                    <Button onClick={chooseFile} theme={ThemeButton.DEFAULT}>
                        Выбрать файл
                    </Button>
                    <Button theme={ThemeButton.OUTLINE} helper helperText="Нажмите Ctrl + V">
                        Вставить из буфера
                    </Button>
                </HStack>
            </VStack>
            <input
                ref={fileInputRef}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
                type="file"
                name={name}
                id={id}
                multiple={false}
                className={styles.input}
                onChange={uploadFile}
                accept={getAcceptValue(name)}
            />
        </VStack>

    );
});
