import { useRef, useState } from 'react';
import { ITableTemplate } from '../../../../../../../assets/interfaces/modules/doc/doc_interface';
import { IDocTableTemplateData, IDocTemplateDataError } from '../../../../../../../assets/interfaces/modules/doc/tab/doc_template_interface';
import PlusLigthSvg from '../../../../../../../assets/svgs/PlusLigthSvg';
import TrashSvg from '../../../../../../../assets/svgs/TrashSvg';
import { docCheckCreateIsEmpty, docTemplateCheckErrors } from '../../../../config/funcs';
import DocTabelSelectField from '../../components/DocTabelSelectField';
import DocTableFileLink from '../../components/DocTableFileLink';
import DocTableIcon from '../../components/DocTableIcon';
import DocTableTd from '../../td/DocTableTd';

type IProps = {
    td?: IDocTableTemplateData;
    index: number;
    create?: boolean;
    createRow?: IDocTableTemplateData;
    tableTemplate: keyof ITableTemplate;
    onAddRow?: () => void;
    onDeleteRow?: (index: number) => void;
    onSelectClick?: (index: number) => void
    renderTitle: (key: string) => React.ReactNode
    onChangeFiles?: (index: number, files: FileList | null) => void
}
const DocTabelTrTemplateTemplate = ({
    td,
    index,
    create,
    createRow,
    tableTemplate,
    onAddRow,
    onDeleteRow,
    onChangeFiles,
    onSelectClick,
    renderTitle,
}: IProps) => {
    const inputFile = useRef<HTMLInputElement | null>(null);
    const [error, setError] = useState<IDocTemplateDataError>({
        array_input: false, full_name: false, name: false, path: false,
    });
    function isEmptyCreateRow() {
        if (createRow) {
            const newError = docTemplateCheckErrors(createRow);
            setError(newError);
        }
    }
    const onAddHandler = () => {
        if (onAddRow && createRow) {
            const isEmpty = docCheckCreateIsEmpty(tableTemplate, createRow);
            isEmptyCreateRow();
            if (!isEmpty) {
                if (inputFile.current) {
                    inputFile.current.value = '';
                    onAddRow();
                }
            }
        }
    };
    function clearInputField() {
        if (inputFile.current) {
            inputFile.current.value = '';
        }
    }
    const onDeleteRowHander = (index:number) => {
        if (onDeleteRow) {
            clearInputField();
            onDeleteRow(index);
        }
    };
    const renderTitleTemplate = () => {
        let text = '';
        let href = '';
        if (td) {
            text = td.path || '';
            href = td.path || '';
        } if (createRow) {
            text = createRow.path || '';
            href = createRow.path || '';
        }
        return <DocTableFileLink href={href} text={text} />;
    };
    const onChangeFilesHandler = (files: FileList | null) => {
        if (onChangeFiles && files) {
            onChangeFiles(index, files);
            clearInputField();
        }
    };
    function getArrFields(array: string) {
        if (array.length > 0) {
            return array;
        }
        return 'Неверное или пустое значение';
    }
    const getSelectedFields = () => {
        let text = '';
        if (td) {
            text = getArrFields(td.array_input);
        } if (createRow) {
            text = getArrFields(createRow.array_input);
        }
        return text;
    };
    return (
        <>
            <DocTableTd className="title" error={error.name}>
                {renderTitle('name')}
            </DocTableTd>
            <DocTableTd className="title" error={error.full_name}>
                {renderTitle('full_name')}
            </DocTableTd>
            <DocTableTd
                error={error.array_input}
            >
                <DocTabelSelectField
                    text={getSelectedFields()}
                    index={index}
                    onClick={(index:number) => { if (onSelectClick) onSelectClick(index); }}
                />
            </DocTableTd>
            <DocTableTd
                error={error.path}
                className="template"
            >
                <>
                    <input
                        type="file"
                        ref={inputFile}
                        onChange={(e) => { onChangeFilesHandler(e.currentTarget.files); }}
                        accept="text/html, application/xml"
                    />

                    {renderTitleTemplate()}
                </>
            </DocTableTd>
            <DocTableTd className="icons">
                {create
                    ? (
                        <DocTableIcon
                            index={index}
                            onVoidClick={onAddHandler}
                        >
                            <PlusLigthSvg />
                        </DocTableIcon>
                    ) : (
                        <DocTableIcon
                            index={index}
                            onClickIndex={onDeleteRowHander}
                        >
                            <TrashSvg />
                        </DocTableIcon>
                    )}
            </DocTableTd>
        </>
    );
};
export default DocTabelTrTemplateTemplate;
