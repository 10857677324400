import { useState } from 'react';
import { IOpenFile } from '../../../../../assets/interfaces/interfaces';
import '../../../../../assets/styles/modules/components/doc/doc_info_block.scss';
import FileTaskIconSvg from '../../../../../assets/svgs/FileTaskIconSvg';
import TrashSvg from '../../../../../assets/svgs/TrashSvg';
import FileDropdown from '../../../../../components/html/FileDropdown';
import DraggableModal from '../../../../../components/Modals/DraggableModal';
import InputClose from '../input/InputClose';

interface IProps {
    title: string;
    stats?: boolean;
    files?: Array<any>;
    onDeleteFile?: Function;
}
const DocInfoBlock = ({
    title, stats, files, onDeleteFile,
}: IProps) => {
    const [openFiles, setOpenFiles] = useState<IOpenFile[]>([]);
    const [showAddFile, setShowAddFile] = useState<boolean>(false);
    const [addedFiles, setAddedFiles] = useState<File[]>([]);
    const onClickPlus = () => {
        setShowAddFile(!showAddFile);
    };
    const onChangeFiles = (new_files: File[]) => {
        setAddedFiles(new_files);
    };
    const onSaveFiles = () => {
        setShowAddFile(false);
        setAddedFiles([]);
    };
    const onChangeOpenFiles = (value: IOpenFile[]) => {
        setOpenFiles(value);
    };
    const renderContent = () => {
        if (stats) {
            return (
                <div className="info_block__stats">
                    <div className="info_block__col">
                        <span className="col_title">
                            Редактиров:
                            <span className="success">да</span>
                        </span>
                    </div>
                    <div className="info_block__col">
                        <span className="col_title">
                            Админ:
                            <span className="success"> да </span>
                            <span className="info">Разработчик</span>
                        </span>
                    </div>
                    <div className="info_block__col">
                        <span className="col_title">Всего исходящих: 13</span>
                    </div>
                    <div className="info_block__col">
                        <span className="col_title">Всего входящих: 15</span>
                    </div>
                    <div className="info_block__col">
                        <span className="col_title">Доступно типов документов: 2</span>
                    </div>
                    <div className="info_block__col">
                        <span className="col_title">Доступно полей для заполнения: 7</span>
                    </div>
                </div>
            );
        }

        if (files) {
            return (
                <>
                    <div className="info_block__files">
                        {files.map((file, index) => (
                            <div className="file" key={file.img}>
                                {file.img ? (
                                    <div
                                        className="image"
                                        style={{ backgroundImage: 'url("https://imgs.search.brave.com/5cc7AznkRSQPkhnGXkg4gi582EiRCcMMvish_ONrfU4/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9tZWRp/YS5nZXR0eWltYWdl/cy5jb20vaWQvMTI5/OTQ5MjY4Mi9waG90/by9jYXQtaW4teW91/ci1mYWNlLmpwZz9z/PTYxMng2MTImdz0w/Jms9MjAmYz05WDAt/VlRQRktHakN0QzFa/Tkc4YUUxb2hoaU1z/c3V0RDgwWEtBZk9P/X3VvPQ")' }}
                                        onClick={() => onChangeOpenFiles([{ file_id: 1, path: file.name, task_id: 1 }])}
                                    />
                                ) : (
                                    <div
                                        onClick={() => onChangeOpenFiles([{ file_id: 1, path: file.name, task_id: 1 }])}
                                        className="icon"
                                    >
                                        <FileTaskIconSvg />
                                    </div>
                                )}

                                <div className="name">{file.name}</div>
                                <div
                                    onClick={() => onDeleteFile && onDeleteFile(index)}
                                    className="delete"
                                >
                                    <TrashSvg />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={`doc_file_dropdown ${showAddFile ? '' : 'hide'}`}>
                        <div className="doc_file_body">
                            <div className="doc_dropdown">
                                <FileDropdown
                                    files={addedFiles}
                                    onChangeFiles={onChangeFiles}
                                />
                            </div>
                            {addedFiles.length > 0 && (
                                <div onClick={() => onSaveFiles()} className="add_btn">
                                    Сохранить
                                </div>
                            )}
                        </div>
                    </div>
                </>
            );
        }

        return (
            <div className="info_block__info">
                <div className="info_block__name">Последнее название</div>
                <div className="info_block__type">Тип документа</div>
                <div className="info_block__row">
                    <div className="info_block__col">
                        <span className="col_title">Кому</span>
                        <span className="text">test test test</span>
                    </div>
                    <div className="info_block__col">
                        <span className="text">2024-09-02 11:33:09</span>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <>
            {openFiles.length ? (
                <DraggableModal
                    files={openFiles}
                    onClose={() => onChangeOpenFiles([])}
                />
            ) : ''}
            <div className="doc_info_block">
                <div className="doc_info_block__header">
                    <div className="text">{title}</div>
                    {(files) ? (
                        <div className={`cancel${showAddFile ? ' add' : ''}`}>
                            <span
                                onClick={() => onClickPlus()}
                            >
                                <InputClose />
                            </span>
                        </div>
                    ) : ''}

                </div>
                <div className="doc_info_block__body">
                    {renderContent()}
                </div>
            </div>
        </>

    );
};

export default DocInfoBlock;
