import { useEffect, useState } from 'react';
import { ITask } from '../../../../../assets/interfaces/interfaces';
import '../../../../../assets/styles/user/drag/task_card.scss';
import CheckedSvg from '../../../../../assets/svgs/CheckedSvg';
import CommentSvg from '../../../../../assets/svgs/CommentSvg';
import CommetTaskSvg from '../../../../../assets/svgs/CommetTaskSvg';
import FileTaskSvg from '../../../../../assets/svgs/FileTaskSvg';
import { SelectArrowSvg } from '../../../../../assets/svgs/SelectArrowSvg';
import ToArchiveSvg from '../../../../../assets/svgs/ToArchiveSvg';
import TrashSvg from '../../../../../assets/svgs/TrashSvg';

interface ITaskCard {
    id: number,
    status: string,
    index: number,
    completed: boolean,
    task: ITask,
    onEditTask: Function,
    onAddTaskToArchive: Function,
    onDeleteTask: Function,
    onUpdateCheckboxComplete: Function,
    onTaskToComplete: Function,
}
export default function TaskCard({
    id, status, index, task, completed, onEditTask, onTaskToComplete, onAddTaskToArchive, onDeleteTask, onUpdateCheckboxComplete,
}: ITaskCard) {
    const [input, setInput] = useState<string>('');
    const [isHover, setIsHover] = useState<boolean>(false);
    const [showChecklist, setShowChecklist] = useState<boolean>(false);
    const [onHold, setOnHold] = useState(false);

    const dragStartHandler = (e: any) => {
        e.dataTransfer.setData('cardInfo', JSON.stringify({ id, status }));
        e.target.className += ' ohhold';
        setTimeout(() => {
            setOnHold(true);
        }, 0);
    };
    const dragEndHandler = () => {
        setOnHold(false);
    };
    const onDragOverHandler = (e: any) => {
        e.preventDefault();
        if (e.target.className === 'card') {
            setTimeout(() => {
                e.target.className = 'card anotherCardOnTop';
            }, 0);
        }
    };
    const resetClassName = (e: any) => {
        e.preventDefault();
        const isCard = e.target.className === 'card'
            || e.target.className === 'card anotherCardOnTop';
        if (isCard) {
            setTimeout(() => {
                e.target.className = 'card';
            }, 0);
        }
    };
    const onDragLeaveHandler = (e: any) => {
        resetClassName(e);
    };
    const onDropHandler = (e: any) => {
        resetClassName(e);
        /**
         TODO: Remove all anotherCardOnTop classnames
         from DOM after drop complete.
        */
    };

    const changeInput = (element: any) => {
        setInput(element.value);
        element.style.height = '5px';
        element.style.height = `${element.scrollHeight}px`;
    };
    const getProgress = (checklists: Array<any>) => {
        const checkboxes: Array<any> = [];
        const checkboxesCompleted: Array<any> = [];
        checklists.forEach((checklsit) => {
            checklsit.checkboxes.forEach((checkbox: any) => {
                checkboxes.push(checkbox);
                if (checkbox.complete) {
                    checkboxesCompleted.push(checkbox);
                }
            });
        });
        const width = (checkboxesCompleted.length / checkboxes.length) * 100;
        return width;
    };
    const onShowChecklist = () => {
        setShowChecklist(!showChecklist);
    };
    const onClickCheckbox = (archived_at:boolean | null, checklist_index:number, checkbox_index:number) => {
        if (archived_at) {
            onUpdateCheckboxComplete(task, status, checklist_index, checkbox_index);
        }
    };
    useEffect(() => {
        setInput(task.name);
    }, [task.name]);
    return (
        <div
            id="id"
            className={`task_card ${onHold ? 'hidden' : ''}`}
            draggable={!task.archived_at}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onDragStart={dragStartHandler}
            onDragEnd={dragEndHandler}
            onDragOver={onDragOverHandler}
            onDragLeave={onDragLeaveHandler}
            onDrop={onDropHandler}
        >
            <div className="card_left">

                <div className="top">
                    <span onClick={() => onTaskToComplete(task, status)} className="checked"><CheckedSvg /></span>
                    <span className="title" style={{ color: task.color_text ? task.color_text : '#000' }}>{task.name}</span>

                </div>
                {(task.checklists.length > 0) && (
                    <div className="middle">
                        <span className={`arrow ${showChecklist && 'show'}`} onClick={(e) => { e.preventDefault(); onShowChecklist(); }}>
                            <SelectArrowSvg />
                        </span>
                        .
                        <div className="checklist_container">
                            <div className="wrapper_progress">
                                <div className="progress" style={{ width: `${getProgress(task.checklists)}%` }} />
                            </div>
                            {showChecklist && (
                                <div className="checklists">
                                    {task.checklists.map((checklist, checklist_index) => (
                                        <div className="checklist">
                                            <div className="title">{checklist.name}</div>
                                            <div className="checkboxes">
                                                {checklist.checkboxes.map((checkbox, checkbox_index) => (
                                                    <div
                                                        className="checkbox-wrapper"
                                                        onClick={() => { onClickCheckbox(task.archived_at, checklist_index, checkbox_index); }}
                                                    >
                                                        <input
                                                            className="inp-cbx"
                                                            id="cbx-15"
                                                            checked={checkbox.complete}
                                                            type="checkbox"
                                                            style={{ display: 'none' }}
                                                        />

                                                        <label className="cbx" htmlFor="cbx-15">
                                                            <span>
                                                                <svg width="12px" height="9px" viewBox="0 0 12 9">
                                                                    <polyline points="1 5 4 8 11 1" />
                                                                </svg>
                                                            </span>
                                                            <span>{checkbox.name}</span>
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}

                        </div>

                    </div>
                )}

                <span className="bottom">
                    <div className="left">
                        <div className="comment">
                            <CommetTaskSvg />
                            <span className="count">{task.comments.length}</span>
                        </div>
                        {task.files.length > 0 && (
                            <div className="file">
                                <FileTaskSvg />
                            </div>
                        )}
                        <div className="desc">
                            {task.descriptions && (
                                <CommentSvg />
                            )}
                        </div>
                    </div>
                    <div className="right">
                        <div className="performers">
                            {task.performers.map((performer) => (
                                <div className="avatar" style={{ backgroundImage: `url(${performer.avatar})` }} />
                            ))}
                        </div>
                    </div>
                </span>

            </div>
            <div className="bg" onClick={() => { onEditTask(index, status); }} />

            {(isHover) && (
                <div className="right">

                    {/* <span className="dots" onClick={() => onEditTask(index, status)}>
                        <ThreeDotsSvg />
                    </span> */}
                    {/* {!task.archived_at && (
                        <>
                            <span className="edit" onClick={() => onEditTitleTask(index, status)}>
                                <EditSquareSvg />
                            </span>
                        </>
                    )} */}

                    <span className="edit" onClick={() => onAddTaskToArchive({ index, status })}>
                        <ToArchiveSvg />
                    </span>
                    <span className="edit" onClick={() => onDeleteTask({ index, status })}>
                        <TrashSvg />
                    </span>
                </div>
            )}

        </div>

    );
}
