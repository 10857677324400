interface IProps {
    onAdd: React.MouseEventHandler<HTMLButtonElement>;
    data?: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const AdminAddPosition = ({ onAdd, onChange, data }: IProps) => {
    require('../../../assets/styles/admin/admin_add_position.scss');

    // Define htmlFor as a constan since it won't change
    const htmlFor = 'nameposition';

    return (
        <div className="admin_add_position">
            <div className="admin_add_position__row">
                <div className="field">
                    <label htmlFor={htmlFor}>
                        Название должности
                        <input
                            id={htmlFor}
                            type="text"
                            value={data}
                            onChange={onChange}
                            name={htmlFor}
                            placeholder=""
                        />
                    </label>
                </div>
                <div className="admin_add_position__send_btn">
                    <button type="button" onClick={onAdd}>Добавить</button>
                </div>
            </div>
        </div>
    );
};

export default AdminAddPosition;
