import { ITask, ITaskData } from '../../../../../assets/interfaces/interfaces';
import '../../../../../assets/styles/user/components/task_column.scss';
import PlusSolidSvg from '../../../../../assets/svgs/PlusSolidSvg';
import TaskCard from './TaskCard';

interface ITaskColumn {
    data: ITaskData[keyof ITaskData],
    status: keyof ITaskData | any,

    task_state_id: number,
    currentTab: string,
    onAddTask: Function,
    onDrop: Function,
    onEditTask: Function,
    onFilterTasks: Function,
    onDeleteTask: Function,
    onUpdateCheckboxComplete: Function,
    onAddTaskToArchive: Function,
    onTaskToComplete: Function,

}
export default function TaskBoard({
    data, status, currentTab, onTaskToComplete, onAddTask, onDrop, onAddTaskToArchive, onFilterTasks, task_state_id, onDeleteTask, onEditTask, onUpdateCheckboxComplete,
}: ITaskColumn) {
    // Sort data (Might need useMemo)
    const sorted: ITask[] = data.sort((a, b) => a.order - b.order);

    const onDragEnterHandler = (e: any) => {
        e.preventDefault();
    };
    const onDragOverHandler = (e: any) => {
        e.preventDefault();
        if (e.target.className === 'boardContentArea') {
            setTimeout(() => {
                e.target.className = 'boardContentArea hovered';
            }, 0);
        }
    };
    const onDragLeaveHandler = (e: any) => {
        e.preventDefault();
        if (e.target.className === 'boardContentArea hovered') {
            setTimeout(() => {
                e.target.className = 'boardContentArea';
            }, 0);
        }
    };
    const onDropHandler = (e: any) => {
        const cardInfo = JSON.parse(e.dataTransfer.getData('cardInfo'));

        const targetCardId = e.target.id;
        onDrop(cardInfo, status, targetCardId);
        if (e.target.className === 'boardContentArea hovered') {
            setTimeout(() => {
                e.target.className = 'boardContentArea';
            }, 0);
        }
    };

    // returns JSX - Render cards
    const renderCards = () => sorted.map((item, index) => {
        // Check if the task is archived
        const isArchived = item.archived_at === true;

        // Handling rendering based on currentTab state
        if (currentTab === 'archive') {
            if (isArchived) {
                return (
                    <TaskCard
                        key={`status-${item.id}`}
                        id={item.id}
                        status={status}
                        completed={item.ending_at ? new Date(item.ending_at) > new Date() : false}
                        index={index}
                        onDeleteTask={onDeleteTask}
                        onEditTask={onEditTask}
                        onAddTaskToArchive={onAddTaskToArchive}
                        onTaskToComplete={onTaskToComplete}
                        onUpdateCheckboxComplete={onUpdateCheckboxComplete}
                        task={item}
                    />
                );
            }
            return null; // Current task is 'archive' but not archived
        }
        // For any other task (not 'archive')
        if (!isArchived) {
            return (
                <TaskCard
                    key={`status-${item.id}`}
                    id={item.id}
                    status={status}
                    completed={item.ending_at ? new Date(item.ending_at) > new Date() : false}
                    index={index}
                    onDeleteTask={onDeleteTask}
                    onEditTask={onEditTask}
                    onAddTaskToArchive={onAddTaskToArchive}
                    onUpdateCheckboxComplete={onUpdateCheckboxComplete}
                    onTaskToComplete={onTaskToComplete}
                    task={item}
                />
            );
        }

        return null; // Default case (not archived)
    });

    const getCount = (isArchived: boolean) => {
        let count = 0;
        if (isArchived) {
            count = data.filter((val) => (val.task_state_id === task_state_id && (val.archived_at === true))).length;
        } else {
            count = data.filter((val) => (val.task_state_id === task_state_id && (val.archived_at !== true))).length;
        }
        return count;
    };
    return (
        <div className="container_task">

            <div className="container_task_content">
                <div className="content_header">
                    <div className="header_top">
                        <div className="left">
                            <span className="title">{status}</span>
                            <span id={`status_${status}`} className="count">
                                {currentTab === 'archive' ? (
                                    getCount(true)
                                ) : (
                                    getCount(false)
                                )}
                            </span>

                        </div>
                        <div className="right">
                            <div className="add" onClick={() => onAddTask(task_state_id, status)}>
                                {(status === 'Новые' && currentTab !== 'archive') && (
                                    <span className="plus"><PlusSolidSvg /></span>
                                )}

                            </div>
                            {/* <ThreeDotsSvg /> */}
                        </div>
                    </div>
                    <div className="header_bottom">
                        {/* <div className="add" onClick={() => onAddTask(task_state_id, status)}>
                            {(status === 'Новые' && currentTabs !== 'archive') && (
                                <>
                                    <span className="plus"><PlusSolidSvg /></span>
                                    <span className="text">Добавить задачу</span>
                                </>
                            )}

                        </div> */}
                    </div>

                    <input className="input_search" onChange={(e) => onFilterTasks(e.target.value, status)} placeholder="Поиск по вкладке" />

                </div>
                <div
                    className={`task_content ${status}`}
                    onDragEnter={onDragEnterHandler}
                    onDragOver={onDragOverHandler}
                    onDragLeave={onDragLeaveHandler}
                    onDrop={onDropHandler}
                >
                    {renderCards()}
                </div>
            </div>
        </div>
    );
}
