import { docGetIndex } from '../../../config/funcs';
import s from './Icon.module.scss';

interface IProps {
    index?: number;
    children: any;
    value?:any;
    disabled?: boolean;
    onClickIndex?: (index: number) => void;
    onClickValue?: (value: any, second_value?: any) => void;
    onVoidClick?: () => void;
}

const DocTableIcon = ({
    index, onClickIndex, children, onVoidClick, disabled, onClickValue, value,
}: IProps) => (
    <div
        className={`${s.icon} ${disabled ? s.disabled : ''}`}
        onClick={() => { if (onClickIndex) onClickIndex(docGetIndex(index)); else if (onVoidClick) onVoidClick(); else if (onClickValue) onClickValue(value); }}
    >
        {children}
    </div>
);
export default DocTableIcon;
